import { LoadingButton } from "@mui/lab";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

import React from "react";
import { DataGridContext } from "components/DataGrid/context";

const RefreshButton: React.FC = () => {
  const { refetch, isLoading } = React.useContext(DataGridContext);

  return (
    <LoadingButton loading={isLoading} onClick={refetch}>
      <RefreshOutlinedIcon fontSize="small" /> Atualizar
    </LoadingButton>
  );
};

export default RefreshButton;
