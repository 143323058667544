import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Box, CircularProgress, Stack, Typography } from "@pankod/refine-mui";

import { forwardRef, useEffect, useImperativeHandle } from "react";
import { ChartRefProps } from "../../pages/dashboard";
import { axiosInstance } from "../../authProvider";

export const OpenSales = forwardRef<ChartRefProps, React.RefAttributes<any>>(
  (_, ref) => {
    const API_URL = useApiUrl();
    const url = `${API_URL}/dashboard/openSales`;

    const { data, isFetching, refetch } = useCustom<{
      activeSales: Record<any, any>[];
    }>({
      queryOptions: {
        retry: false,
        cacheTime: 0,
        enabled: false,
      },
      errorNotification: false,
      url,
      method: "get",
    });

    useImperativeHandle(ref, () => ({
      load: refetch,
    }));

    useEffect(() => {
      refetch();

      const handleRefresh = (event: any) => {
        if (
          event.detail.url.indexOf("openSales") > -1 &&
          url.indexOf("openSales") > -1
        ) {
          axiosInstance.defaults.headers.common["Authorization"] =
            event.detail.authorization;
          refetch();
        }
      };
      window.addEventListener("token-refreshed", handleRefresh);
      return () => {
        window.removeEventListener("token-refreshed", handleRefresh);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const endDateString = (date: any) => {
      let _endDate = new Date(date);
      let endDate = _endDate.toLocaleDateString();
      let endTime = _endDate.toLocaleTimeString();
      endTime = endTime.slice(0, -3);

      return endDate + " " + endTime;
    };

    return (
      <Stack
        justifyContent="space-between"
        sx={{
          height: "230px",
          p: 1,
          backgroundColor: "#2a384b9e",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontWeight: 700, mb: 0 }}
          >
            Promoções ativas
          </Typography>
        </Stack>
        <Box sx={{ minHeight: "130px", overflow: "auto" }}>
          {isFetching ? (
            <Box display={"flex"} justifyContent="center">
              <CircularProgress sx={{ color: "#fff" }} />
            </Box>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mx: 2, py: 0.2, color: "#fff" }}
                borderBottom="1px solid"
              >
                <Typography variant="caption" sx={{ mb: 0 }}>
                  Nome
                </Typography>

                <Typography variant="caption" sx={{ mb: 0 }}>
                  Encerra em
                </Typography>
              </Stack>

              {data?.data?.activeSales?.map((sale) => (
                <Stack
                  key={sale.id}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mx: 2, py: 0.2, color: "#fff" }}
                  borderBottom="1px solid"
                >
                  <Typography variant="caption" sx={{ mb: 0 }}>
                    {sale.name}
                  </Typography>

                  <Typography variant="caption" sx={{ mb: 0 }}>
                    {endDateString(sale.endDate)}
                  </Typography>
                </Stack>
              ))}
            </>
          )}
        </Box>
      </Stack>
    );
  }
);
