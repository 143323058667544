import { localDateTimeStr } from "./localDateTime";

function splitBill(billAmount, numPeople) {
  const totalAmountPerPerson = billAmount / numPeople;
  const roundedAmountPerPerson = Math.round(totalAmountPerPerson * 100) / 100;
  return roundedAmountPerPerson;
}

export const formatPrice = (price, { language, currency }) => {
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency,
  }).format(price / 100);
};

export const printTemplate = (
  ticket,
  currentOrder,
  { currency, language }
) => `<!DOCTYPE html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8" />
    <style>
      @media print {
        @page {
          margin: 0;
          size: auto;          
        }
      }
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }

      body {
        font-size: 0.8rem;
      }

      .container {
        width: 6.5cm;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
      }

      .container + .container {
        margin-top: 2cm;
      }

      .order {
        display: flex;
        justify-content: center;
        align-items: end;
        font-weight: bold;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 1.5rem;
      }

      .order span {
        font-size: 1.3rem;
        margin-left: 5px;
      }

      .date {
        font-size: 0.9rem;
        margin-bottom: 30px;
      }

      .row,
      .item {
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 100%;
        margin-bottom: 15px;
      }

      .item {
        font-weight:bold;
        font-size: 0.85rem;
      }

      .split {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top:5px;
      }

      .split div {
        display: flex;
        justify-content: start;
        align-items: center;
      }

      .item label:nth-child(1) {
        display: flex;
        gap:2px;
        justify-content: space-between;
      }

      .dashed-divider {
        border-bottom: 1px dashed #000;
        width: 100%;
        margin: 5px 0 15px 0;
      }

      .footer-message {
        margin-top: 20px;
        font-size: 0.65rem;
      }
    </style>
  </head>
  <body>
  ${new Array(ticket.copies)
    .fill(0)
    .map(() => template(ticket, currentOrder, currency, language))
    .join("")}
  </body>
</html>`;

function template(ticket, currentOrder, currency, language) {
  return `<div class="container">
  <h1>${ticket.header}</h1>
  <div class="order">Pedido: <span>${currentOrder.code}</span></div>
  <div class="date">${localDateTimeStr(currentOrder.createdAt, true)}</div>

  <div class="row">
    <label>Pagamento:</label>
    <label style="font-weight: bold">${currentOrder.paymentMethod.name}</label>
  </div>

  ${
    currentOrder.moneyChangeFormatted
      ? `
  <div class="row">
  <label>Troco para:</label>
  <label style="font-weight: bold">${currentOrder.moneyChangeFormatted.replace(
    /\s/g,
    ""
  )}</label>
</div>
`
      : ""
  }

  <div class="dashed-divider"></div>

  <div class="row">
    <label>Bairro:</label>
    <label style="font-weight: bold">${
      currentOrder?.neighbourhood?.name || "-"
    }</label>
  </div>

  <div class="row" style="display:block">
    <label>Endereço:</label>
    <label>
    ${currentOrder.direction}, ${currentOrder.number}
      ${
        currentOrder.addressComplement
          ? ", " + currentOrder.addressComplement + " "
          : " "
      }
      ${
        currentOrder.addressNotes ? ", " + currentOrder.addressNotes + " " : " "
      }

    </label>
  </div>

  <div class="row">
    <label>Cliente:</label>
    <label>${currentOrder.customer}</label>
  </div>

  ${
    currentOrder.notes
      ? `
      <div class="row">
        <label>Observação:</label>
      </div>
      <div class="row">
        <label style="font-weight:bold">${currentOrder.notes}</label>
      </div>`
      : ""
  }

  <div class="dashed-divider"></div>


  ${
    currentOrder?.items?.length
      ? currentOrder.items
          .map(
            (item) =>
              `<div class="item">
      <label><label>${item.quantity}</label> ${item.product.name}</label>
      <label>${item.totalPriceFormatted.replace(/\s/g, "")}</label>
    </div>`
          )
          .join("")
      : ""
  }
  
  ${currentOrder?.items?.length ? `<div class="dashed-divider"></div>` : ""}

  <div class="row">
    <label>Soma dos produtos:</label>
    <label>${currentOrder.subtotalFormatted.replace(/\s/g, "")}</label>
  </div>

  <div class="row">
    <label>Desconto</label>
    <label>-${currentOrder.discountAmountFormatted.replace(/\s/g, "")}</label>
  </div>

  <div class="row">
    <label>Taxa de entrega:</label>
    <label>${currentOrder.shippingCostFormatted.replace(/\s/g, "")}</label>
  </div>

  <div class="row">
    <label>Total a pagar:</label>
    <label style="font-weight: bold">${currentOrder.totalFormatted.replace(
      /\s/g,
      ""
    )}</label>
  </div>
   
  ${
    currentOrder.moneyChange
      ? `
    <div class="row">
  <label>Troco:</label>
  <label>${formatPrice(currentOrder.moneyChange - currentOrder.total, {
    currency,
    language,
  })}</label>
</div>
    `
      : ""
  }
  
  <div class="row" style="margin-top:10px">
    <label>Cupom:</label>
    <label>${currentOrder.discount.code}</label>
  </div>

  <div class="split" style="margin-top:10px">
    <div>
      <label>2pess .... &nbsp;</label>
      <label>${formatPrice(splitBill(currentOrder.total, 2), {
        currency,
        language,
      }).replace(/\s/g, "")}</label>
    </div>
    <div>
      <label>3pess. .... &nbsp;</label>
      <label>${formatPrice(splitBill(currentOrder.total, 3), {
        currency,
        language,
      }).replace(/\s/g, "")}</label>
    </div>
  </div>

  <div class="split">
  <div>
    <label>4pess .... &nbsp;</label>
    <label>${formatPrice(splitBill(currentOrder.total, 4), {
      currency,
      language,
    }).replace(/\s/g, "")}</label>
  </div>
  <div>
    <label>5pess .... &nbsp;</label>
    <label>${formatPrice(splitBill(currentOrder.total, 5), {
      currency,
      language,
    }).replace(/\s/g, "")}</label>
  </div>
</div>

<div class="footer-message">${ticket.footer}</div>
</div>`;
}
