import { Box } from "@pankod/refine-mui";

interface FormProps {
  children: React.ReactNode;
}

export const Form: React.FC<FormProps> = ({ children }) => {
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      autoComplete="off"
    >
      {children}
    </Box>
  );
};
