import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Box, CircularProgress, Stack, Typography } from "@pankod/refine-mui";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { ChartProps, ChartRefProps } from "../../pages/dashboard";
import { axiosInstance } from "../../authProvider";

export const Revenue = forwardRef<ChartRefProps, ChartProps>(
  ({ dates }, ref) => {
    const API_URL = useApiUrl();
    const url = `${API_URL}/dashboard/revenueByPeriod/${dates[0]}/${dates[1]}`;

    const { data, isFetching, refetch } = useCustom<{
      shippingCost: string;
      subtotal: string;
      total: string;
    }>({
      queryOptions: {
        retry: false,
        cacheTime: 0,
        enabled: false,
        networkMode: "always",
      },
      errorNotification: false,
      url,
      method: "get",
    });

    useImperativeHandle(ref, () => ({
      load: refetch,
    }));

    useEffect(() => {
      refetch();

      const handleRefresh = (event: any) => {
        if (
          event.detail.url.indexOf("revenueByPeriod") > -1 &&
          url.indexOf("revenueByPeriod") > -1
        ) {
          axiosInstance.defaults.headers.common["Authorization"] =
            event.detail.authorization;
          refetch();
        }
      };
      window.addEventListener("token-refreshed", handleRefresh);
      return () => {
        window.removeEventListener("token-refreshed", handleRefresh);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Stack
        justifyContent="space-between"
        sx={{
          height: "230px",
          p: 2,
          backgroundColor: "#5f4f7b",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1 }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontWeight: 700, p: 0 }}
          >
            Receita
          </Typography>
        </Stack>
        <Box sx={{ minHeight: "130px", px: 1 }}>
          {isFetching ? (
            <Box display={"flex"} justifyContent="center">
              <CircularProgress sx={{ color: "#fff" }} />
            </Box>
          ) : (
            <>
              <Typography
                variant="h5"
                style={{ fontSize: 36 }}
                sx={{ fontWeight: 700, color: "#fff", mt: -2, mb: 2 }}
              >
                {data?.data?.total || "R$ 0,00"}
              </Typography>

              <Typography
                variant="caption"
                sx={{
                  color: "#fff",
                }}
              >
                Subtotal + taxa de Entrega
              </Typography>
              <Box display={"flex"}>
                <Typography
                  variant="caption"
                  sx={{
                    color: "#fff",
                  }}
                >
                  {data?.data?.subtotal || "R$ 0,00"}
                  {" + "}
                  {data?.data?.shippingCost || "R$ 0,00"}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Stack>
    );
  }
);
