import { SxProps, TextField } from "@pankod/refine-mui";
import { FocusEvent } from "react";

export interface InputTextFieldProps {
  register: any;
  errors: any;
  exactError?: any;
  name: string;
  label: string;
  registerProps?: any;
  sx?: SxProps;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  onBlur?: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
}

export const InputTextField: React.FC<InputTextFieldProps> = ({
  register,
  registerProps,
  errors,
  exactError,
  name,
  label,
  sx,
  required,
  type,
  disabled,
  onBlur,
}) => {
  const _sx = {
    maxWidth: 300,
    marginTop: 0,
    ...sx,
  };

  const _registerProps = {
    ...(required ? { required: `Campo obrigatório` } : {}),
    ...registerProps,
  };

  return (
    <TextField
      {...register(name, _registerProps)}
      error={!!errors?.[name] || exactError}
      helperText={errors?.[name]?.message || exactError?.message}
      margin="normal"
      required={required}
      fullWidth
      type={type || "text"}
      id={name}
      label={label}
      name={name}
      onBlur={onBlur}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      sx={_sx}
      {...(type === "textarea" ? { multiline: true } : {})}
    />
  );
};
