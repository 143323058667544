import { Box, Create, SaveButton, Typography } from "@pankod/refine-mui";

import { useForm } from "@pankod/refine-react-hook-form";

import React from "react";

import {
  Form,
  EnableInputField,
  InputTextField,
  InputNumberField,
  SelectInputField,
  PriceInputField,
} from "../../components/form";
import { errorNotificationHandler } from "../../utils";
import { DateTimeInput } from "../../components/form/DateTimeInput";

export const DiscountCreate: React.FC = () => {
  const {
    refineCore: { onFinish, formLoading },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      //@ts-ignore
      errorNotification: (error) =>
        errorNotificationHandler(error, "Erro ao criar cupom de desconto"),
    },
  });

  const typeWatch = watch("type");

  const codeWatch = watch("code");

  React.useEffect(() => {
    if (codeWatch) {
      const transformCode = codeWatch.toUpperCase().replace(/\s/g, "");
      setValue("code", transformCode);
    }
  }, [codeWatch, setValue]);

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Criar cupom de desconto
        </Typography>
      }
      footerButtons={
        <SaveButton
          onClick={() => {
            trigger().then((isValid) => {
              if (isValid) {
                const referenceDate_ = new Date(getValues("referenceDate"));
                setValue(
                  "referenceDate",
                  referenceDate_.toISOString().slice(0, -8)
                );
              }
              handleSubmit(onFinish)();
            });
          }}
        />
      }
    >
      <Form>
        <InputTextField
          name="code"
          label="Código"
          required
          register={register}
          errors={errors}
        />

        <InputNumberField
          name="availableQuantity"
          label="Quantidade disponível"
          required
          register={register}
          errors={errors}
          sx={{ maxWidth: 300 }}
        />

        <SelectInputField
          control={control}
          errors={errors}
          name="type"
          label="Tipo"
          options={[
            { label: "Selecione", value: "" },
            { label: "Valor", value: "value" },
            { label: "Percentual", value: "percent" },
          ]}
          required
        />

        {typeWatch === "percent" ? (
          <PriceInputField
            name="amount"
            label="Porcentagem %"
            required
            sufix="%"
            prefix=""
            control={control}
            errors={errors}
            sx={{ maxWidth: 175 }}
          />
        ) : (
          <PriceInputField
            name="amount"
            label="Valor R$"
            required
            control={control}
            errors={errors}
            sx={{ maxWidth: 175 }}
          />
        )}

        <Box sx={{ display: "flex", gap: 2 }}>
          <PriceInputField
            disabled={typeWatch === "value" || typeWatch === ""}
            name="maxDiscountAllowed"
            label="Desconto Máximo R$"
            required={typeWatch === "percent"}
            control={control}
            errors={errors}
            sx={{ maxWidth: 300 }}
          />

          <PriceInputField
            disabled={typeWatch === "percent" || typeWatch === ""}
            name="minOrderAmount"
            label="Valor mínimo da compra R$"
            required={typeWatch === "value"}
            control={control}
            errors={errors}
            sx={{ maxWidth: 300 }}
          />
        </Box>

        <DateTimeInput
          name="referenceDate"
          label="Data de referência"
          register={register}
          errors={errors}
          required
        />

        <EnableInputField name="enabled" control={control} label="Habilitado" />
      </Form>
    </Create>
  );
};
