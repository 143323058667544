import {
  Box,
  Button,
  IconButton,
  Stack,
  Switch,
  Tooltip,
} from "@pankod/refine-mui";

import CloseIcon from "@mui/icons-material/Close";
import Forbbiden from "@mui/icons-material/Block";

import {
  FormControlLabel,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@pankod/refine-mui";
import { NumericFormat } from "react-number-format";
import { TextField } from "@pankod/refine-mui";

import { TableBody } from "@pankod/refine-mui";
import DialogSelectProduct from "../../components/product/dialog-select-product/DialogSelectProduct";
import { useSelectProductDialog } from "../../components/product/dialog-select-product/useSelectProductDialog";
import { InputNumberField } from "components/form";

function TextFieldSmall(props: any) {
  return <TextField {...props} size="small" variant="outlined" />;
}

export default function Products({ setItems, items, formLoading }: any) {
  const selectProductProps = useSelectProductDialog();

  const updateItem = (productId: number, value: any) => {
    const index = items.findIndex((i: any) => i.product.id === productId);
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      ...value,
    };
    setItems(newItems);
  };

  const removeItem = (productId: number) => {
    const newItems = items.filter((i: any) => i.product.id !== productId);
    setItems(newItems);
  };

  const onSelected = (selectedItems: any[]) => {
    const parseCarouselItems = selectedItems.map((item) => ({
      enabled: true,
      order: 0,
      product: {
        formattedPrice: item.formattedPrice,
        id: item.id,
        name: item.name,
      },
    }));

    const newItems = [
      ...items,
      ...parseCarouselItems.filter(
        (item) => !items.find((i: any) => i.product.id === item.product.id)
      ),
    ];

    setItems(newItems);
  };

  return (
    <>
      <DialogSelectProduct
        multiple
        onSelected={onSelected}
        {...selectProductProps}
        disabledItems={items.map((item: any) => item.product.id)}
      />

      <Stack
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        padding={1}
        direction="row"
        gap={2}
        sx={{ marginTop: 8, marginBottom: 4 }}
      >
        <Typography variant="h5" sx={{ fontSize: 16 }}>
          Produtos
        </Typography>

        <Box>
          <Button
            id="basic-button"
            onClick={selectProductProps.openSelectProduct}
            variant="outlined"
            sx={{
              marginRight: "5px",
            }}
          >
            Selecionar produtos
          </Button>
        </Box>
      </Stack>

      <TableContainer sx={{ marginBottom: 8 }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Produto</TableCell>
              <TableCell>Preço</TableCell>
              <TableCell>Ordem</TableCell>
              <TableCell>Habilitado</TableCell>

              <TableCell>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(items || []).map((row: any) => (
              <TableRow
                key={row.id || row.product.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.product.name}
                </TableCell>
                <TableCell>
                  <Typography color={"GrayText"}>
                    {row.product.formattedPrice}
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    fullWidth
                    type={"number"}
                    id={"ordem"}
                    label={"Ordem"}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ maxWidth: 100 }}
                    defaultValue={row.order}
                    onChange={(e) => {
                      updateItem(row.product.id, {
                        order: e.target.value,
                      });
                    }}
                  />
                </TableCell>

                <TableCell>
                  <FormControlLabel
                    sx={{ marginTop: 2, width: "120px" }}
                    control={
                      <Switch
                        checked={row.enabled}
                        onChange={(_, value) => {
                          updateItem(row.product.id, { enabled: value });
                        }}
                      />
                    }
                    label="Habilitado"
                  />
                </TableCell>

                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => {
                      removeItem(row.product.id);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
