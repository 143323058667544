import { useShow } from "@pankod/refine-core";
import { Show, Stack, Typography } from "@pankod/refine-mui";
import { boolToString } from "../../boolToString";
import { useViewImage } from "../../components/useUploadImage/useUploadImage";

export const CategoryGroupShow: React.FC = () => {
  const { queryResult } = useShow({
    metaData: {
      populate: ["image", "category"],
    },
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { Viewer: ImageViewer } = useViewImage({
    image: record?.image,
  });

  return (
    <Show
      isLoading={isLoading}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Grupo
        </Typography>
      }
    >
      <Stack gap={1} sx={{ marginLeft: 2 }}>
        <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 2 }}>
          Nome
        </Typography>
        <Typography variant="body2">{record?.name}</Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 2 }}>
          Categoria
        </Typography>
        <Typography variant="body2">{record?.category.name}</Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 2 }}>
          Ordem
        </Typography>
        <Typography variant="body2">{record?.order}</Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 2 }}>
          Habilitada
        </Typography>
        <Typography variant="body2">{boolToString(record?.enabled)}</Typography>

        <ImageViewer />
      </Stack>
    </Show>
  );
};
