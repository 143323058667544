import { FormControlLabel, Switch } from "@pankod/refine-mui";
import { TableSearchProps } from "./TableSearch";

export const EnabledFilter: React.FC<TableSearchProps> = ({
  getDefaultFilter,
  setFilters,
  crudFilter,
  filters,
}: TableSearchProps) => {
  const onClickSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    crudFilter.value = value;
    const newFilters = filters.filter(
      // @ts-ignore
      (filter: CrudFilter) => filter.field !== crudFilter.field
    );
    if (value) {
      newFilters.push(crudFilter);
    }
    setFilters(newFilters);
  };

  const checked = getDefaultFilter(
    crudFilter.field,
    filters,
    crudFilter.operator
  )?.toString();

  return (
    <FormControlLabel
      sx={{
        minWidth: "250px",
      }}
      control={
        <Switch
          onChange={onClickSearch}
          defaultValue={checked}
          defaultChecked={checked === "true"}
        />
      }
      label={"Esconder desabilitados"}
    />
  );
};
