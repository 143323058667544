import React, { useEffect, useMemo } from "react";
import {
  Box,
  Card,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@pankod/refine-mui";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import { useApiUrl, useCustom, useModalReturnType } from "@pankod/refine-core";

export const AvailabilityProducts: React.FC<useModalReturnType> = ({
  visible,
  close,
}) => {
  const theme = useTheme();

  const API_URL = useApiUrl();
  const url = `${API_URL}/products/availability`;
  const { data, refetch, isRefetching, isLoading } = useCustom<any>({
    url,
    queryOptions: {
      retry: false,
      cacheTime: 0,
      enabled: false,
      networkMode: "offlineFirst",
      keepPreviousData: true,
    },
    errorNotification: false,
    method: "get",
  });

  useEffect(() => {
    if (!visible) return;

    refetch();
  }, [visible]);

  return (
    <Drawer
      color={theme.palette.background.default}
      sx={{ zIndex: "1300" }}
      PaperProps={{
        sx: {
          width: { sm: "100%", md: 600 },
          padding: "0",
        },
      }}
      open={visible}
      onClose={() => {}}
      anchor="right"
    >
      <Box
        component={Card}
        sx={{
          display: "flex",
          flex: 1,
          borderRadius: 0,
          flexDirection: "column",
        }}
      >
        <Stack
          flex={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          height="50px"
          maxHeight="50px"
          gap={2}
          px={2}
          borderBottom={`1px solid ${theme.palette.action.active}`}
        >
          <IconButton
            onClick={() => close()}
            sx={{
              width: "30px",
              height: "30px",
              mt: "3px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="body1" color="inherit" component="div">
            Disponibilidade
          </Typography>
        </Stack>

        {isLoading || isRefetching ? (
          <Stack
            flex={1}
            sx={{ p: 2, placeContent: "center", placeItems: "center" }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Stack flex={1} sx={{ px: 3, py: 4 }} gap={5} overflow={"auto"}>
            {Object.keys(data?.data).map((item) => (
              <Progress
                label={item}
                pct={data?.data[item].pct}
                value={data?.data[item].enabled}
                total={data?.data[item].amount}
              />
            ))}
          </Stack>
        )}
        {/* */}
      </Box>
    </Drawer>
  );
};

function Progress({ label, pct, total, value }: any) {
  const theme = useTheme();

  let color = theme.palette.error.main;

  if (pct > 59) {
    color = "#4caf50";
  } else if (pct > 39) {
    color = theme.palette.warning.main;
  }

  return (
    <Stack direction="column">
      <Typography variant="body2" color="inherit" component="div">
        {label} {pct}% ({value} de {total})
      </Typography>
      <LinearProgress
        value={pct}
        variant="determinate"
        sx={{
          width: "100%",
          backgroundColor: "#CCC",
          "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: color,
          },
        }}
      />
    </Stack>
  );
}
