import { MosaicEdit } from "pages/mosaic/edit";
import { MosaicList } from "pages/mosaic/list";

import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";

const MosaicsConfig = {
  name: "mosaics",
  list: MosaicList,
  edit: MosaicEdit,
  options: {
    label: "Mosaico",
  },
  icon: (
    <AutoAwesomeMosaicIcon
      style={{
        transform: "rotate(90deg)",
      }}
    />
  ),
};

export { MosaicsConfig };
