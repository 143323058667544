import { useList, useNavigation } from "@pankod/refine-core";
import { Box, Typography } from "@pankod/refine-mui";
import { DataGridTable } from "components/DataGrid/DataGridTable";
import { dataGridColumnFactory } from "components/DataGrid/components/Column";
import RefreshButton from "components/DataGrid/components/RefreshButton";
import DataGridFilterSelect from "components/DataGrid/components/filter/DataGridFilterSelect";
import DataGridFilterSort from "components/DataGrid/components/filter/DataGridFilterSort";
import DataGridFilterText from "components/DataGrid/components/filter/DataGridFilterText";
import { localDateTimeStr } from "localDateTime";
import React from "react";

const columns = [
  dataGridColumnFactory({
    accessorKey: "attributes.code",
    header: "Código",
    size: 30,
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.status.data.attributes.name",
    header: "Status",
    size: 30,
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.customer",
    header: "Cliente",
    Cell: ({ attributes }) => (
      <Box display={"flex"} gap={1} alignItems={"center"}>
        <Typography variant="body2">{attributes.customer}</Typography>
        <Typography variant="body2">{attributes.phone}</Typography>
      </Box>
    ),
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.totalFormatted",
    header: "Total",
    size: 30,
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.createdAt",
    header: "Criado em",
    size: 30,
    Cell: ({ attributes }) => (
      <>{localDateTimeStr(attributes.createdAt, true)}</>
    ),
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.courier.data.attributes.name",
    header: "Entregador",
    size: 30,
  }),
];

export const OrderList: React.FC = () => {
  const { show: showOrder } = useNavigation();

  const { data: statusList } = useList<any>({
    resource: "order-statuses",
    config: {
      hasPagination: false,
    },
  });

  const { data: couriersList } = useList<any>({
    resource: "couriers",
    config: {
      hasPagination: false,
    },
  });

  return (
    <DataGridTable<{
      code: "";
      customer: "";
      status: "";
      courier: "";
      phone: "";
    }>
      noActions
      id="order-list"
      initialSort={{
        field: "createdAt",
        order: "desc",
      }}
      endpoint="orders"
      header={"Pedidos"}
      columns={columns}
      initialState={{
        code: "",
        customer: "",
        status: "",
        courier: "",
        phone: "",
      }}
      headerActions={
        <>
          <RefreshButton />
        </>
      }
      filterComponent={
        <>
          <DataGridFilterText field="code" placeholder="Buscar por Código" />
          <DataGridFilterText
            field="customer"
            placeholder="Buscar por Cliente"
          />
          <DataGridFilterText field="phone" placeholder="Buscar por Telefone" />
          <DataGridFilterSelect
            emptyLabel="Todos os status"
            field="status"
            options={
              statusList?.data.map((status: any) => ({
                value: status.id,
                label: status.name,
              })) || []
            }
          />

          <DataGridFilterSelect
            emptyLabel="Todos os entregadores"
            field="courier"
            options={
              couriersList?.data.map((status: any) => ({
                value: status.id,
                label: status.name,
              })) || []
            }
          />

          <DataGridFilterSort
            fields={[
              {
                name: "customer",
                label: "Cliente",
              },
              {
                name: "createdAt",
                label: "Criado em",
              },
            ]}
          />
        </>
      }
      queryPipeline={(f) => {
        const query: Record<string, string> = {};
        if (f.code) {
          query["filters[code][$containsi]"] = f.code;
        }

        if (f.customer) {
          query["filters[customer][$containsi]"] = f.customer;
        }

        if (f.phone) {
          query["filters[phone][$containsi]"] = f.phone;
        }

        if (f.status) {
          query["filters[status][id][$eq]"] = f.status;
        }

        if (f.courier) {
          query["filters[courier][id][$eq]"] = f.courier;
        }

        query["populate[status][fields][0]"] = "name";
        query["populate[courier][fields][0]"] = "name";
        return query;
      }}
      onClickRow={(id) => {
        showOrder("orders", id);
      }}
    />
  );
};
