import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const StyleSection: React.FC<SectionProps> = ({ register, errors }) => {
  return (
    <InputTextField
      name="style"
      label="Estilos"
      required
      register={register}
      errors={errors}
      type="textarea"
      sx={{
        maxWidth: "100%",
      }}
    />
  );
};
