import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Box, CircularProgress, Stack, Typography } from "@pankod/refine-mui";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { ChartProps, ChartRefProps } from "../../pages/dashboard";
import { axiosInstance } from "../../authProvider";

export const DailyOrders = forwardRef<ChartRefProps, ChartProps>(
  ({ dates }, ref) => {
    const API_URL = useApiUrl();
    const url = `${API_URL}/dashboard/countByPeriod/${dates[0]}/${dates[1]}`;

    const { data, isFetching, refetch } = useCustom<{
      ordersByStatus: Record<any, any>[];
    }>({
      queryOptions: {
        retry: false,
        cacheTime: 0,
        enabled: false,
        networkMode: "always",
      },
      errorNotification: false,
      url,
      method: "get",
    });

    useImperativeHandle(ref, () => ({
      load: () => {
        refetch();
      },
    }));

    useEffect(() => {
      refetch();

      const handleRefresh = (event: any) => {
        if (
          event.detail.url.indexOf("countByPeriod") > -1 &&
          url.indexOf("countByPeriod") > -1
        ) {
          axiosInstance.defaults.headers.common["Authorization"] =
            event.detail.authorization;
          refetch();
        }
      };
      window.addEventListener("token-refreshed", handleRefresh);
      return () => {
        window.removeEventListener("token-refreshed", handleRefresh);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const finishedOrders = data?.data?.ordersByStatus?.find(
      (order) => order.statusOrder === 1000
    );

    const othersOrders = data?.data?.ordersByStatus?.filter(
      (order) => order.statusOrder !== 1000
    );

    return (
      <Stack
        justifyContent="space-between"
        sx={{
          height: "230px",
          p: 1,
          backgroundColor: "#2a384b9e",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontWeight: 700, mb: 0 }}
          >
            Pedidos
          </Typography>

          <Stack direction="row" alignItems="center">
            <Typography sx={{ fontWeight: 700, fontSize: 24, color: "#fff" }}>
              {finishedOrders?.name || ""} {finishedOrders?.count ?? 0}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            minHeight: "130px",
          }}
        >
          {isFetching ? (
            <Box display={"flex"} justifyContent="center">
              <CircularProgress sx={{ color: "#fff" }} />
            </Box>
          ) : (
            othersOrders?.map((order) => (
              <Stack
                key={order.name}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mx: 2, py: 0.2, color: "#fff" }}
                borderBottom="1px solid"
              >
                <Typography variant="caption" sx={{ mb: 0 }}>
                  {order.name}
                </Typography>

                <Typography variant="caption" sx={{ mb: 0 }}>
                  {order.count}
                </Typography>
              </Stack>
            ))
          )}
        </Box>
      </Stack>
    );
  }
);
