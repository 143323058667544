import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";

import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocalPizzaOutlinedIcon from "@mui/icons-material/LocalPizzaOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import TuneIcon from "@mui/icons-material/Tune";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import BikeWhiteIcon from "@mui/icons-material/PedalBike";
import PanoramaWideAngleIcon from "@mui/icons-material/PanoramaWideAngle";
import SellIcon from "@mui/icons-material/Sell";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import GraphicEq from "@mui/icons-material/GraphicEq";

import { DataProvider } from "@pankod/refine-strapi-v4";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { Title, Sider, Layout, Header } from "components/layout";
import { ColorModeContextProvider } from "contexts";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
import { UsersList } from "./pages/users/list";
import { ProductsList } from "./pages/products/list";
import { ProductCreate } from "./pages/products/create";
import { ProductEdit } from "./pages/products/edit";
import { UserShow } from "./pages/users/show";
import { UserEdit } from "./pages/users/edit";
import { UserCreate } from "./pages/users/create";

import { BannersList } from "./pages/banners/list";
import { BannerCreate } from "./pages/banners/create";
import { BannerEdit } from "./pages/banners/edit";

import { CategoryGroupsList } from "./pages/category_groups/list";
import { CategoryGroupCreate } from "./pages/category_groups/create";
import { CategoryGroupShow } from "./pages/category_groups/show";
import { CategoryGroupEdit } from "./pages/category_groups/edit";

import { OrderStatusList } from "./pages/order_status/list";
import { OrderStatusCreate } from "./pages/order_status/create";
import { OrderStatusEdit } from "./pages/order_status/edit";
import { PaymentMethodsList } from "./pages/payment_methods/list";
import { PaymentMethodCreate } from "./pages/payment_methods/create";
import { PaymentMethodEdit } from "./pages/payment_methods/edit";
import { SettingsEdit } from "./pages/settings/edit";
import { SettingsList } from "./pages/settings/list";
import { AuthPage } from "./components/login/LoginPage";

import { DeliverySettingsEdit } from "./pages/delivery_settings/edit";
import { DeliverySettingsList } from "./pages/delivery_settings/list";

import { CouriersList } from "./pages/courier/list";
import { CourierCreate } from "./pages/courier/create";
import { CourierEdit } from "./pages/courier/edit";
import { SalesList } from "./pages/sales/list";
import { SaleCreate } from "./pages/sales/create";
import { SaleEdit } from "./pages/sales/edit";
import { OrderList } from "./pages/orders/list";
import { ShowOrder } from "./pages/orders/show";
import { DashboardPage } from "./pages/dashboard";

import {
  DiscountList,
  DiscountCreate,
  DiscountEdit,
  DiscountShow,
} from "./pages/discounts";
import { CarouselsConfig } from "pages/carousel";
import { MosaicsConfig } from "pages/mosaic";
import { Analytics } from "pages/analytics";
import { CategoriesConfig } from "pages/categories";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider
        sx={{
          maxWidth: "350px",
        }}
      >
        <Refine
          authProvider={authProvider}
          dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
          LoginPage={AuthPage}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          routerProvider={routerProvider}
          i18nProvider={i18nProvider}
          DashboardPage={DashboardPage}
          resources={[
            {
              name: "analytics",
              list: Analytics,
              options: {
                label: "Analytics",
              },
              icon: <GraphicEq />,
            },
            {
              name: "orders",
              list: OrderList,
              show: ShowOrder,
              options: {
                label: "Pedidos",
              },
              icon: <ShoppingBasketIcon />,
            },
            {
              name: "banners",
              list: BannersList,
              create: BannerCreate,
              edit: BannerEdit,
              options: {
                label: "Banners",
              },
              icon: <PanoramaWideAngleIcon />,
            },
            CarouselsConfig,
            MosaicsConfig,
            {
              name: "discounts",
              list: DiscountList,
              create: DiscountCreate,
              edit: DiscountEdit,
              show: DiscountShow,
              options: {
                label: "Descontos",
              },
              icon: <AttachMoneyIcon />,
            },
            {
              name: "sales",
              list: SalesList,
              create: SaleCreate,
              edit: SaleEdit,
              options: {
                label: "Promoções",
              },
              icon: <SellIcon />,
            },
            {
              name: "products",
              list: ProductsList,
              create: ProductCreate,
              edit: ProductEdit,
              options: {
                label: "Produtos",
              },
              icon: <LocalPizzaOutlinedIcon />,
            },
            CategoriesConfig,
            {
              name: "category-groups",
              list: CategoryGroupsList,
              create: CategoryGroupCreate,
              show: CategoryGroupShow,
              edit: CategoryGroupEdit,
              options: {
                label: "Grupos",
              },
              icon: <CategoryOutlinedIcon />,
            },
            {
              name: "payment-methods",
              list: PaymentMethodsList,
              create: PaymentMethodCreate,
              edit: PaymentMethodEdit,
              options: {
                label: "Formas de pagamento",
              },
              icon: <LocalAtmIcon />,
            },

            {
              name: "order-statuses",
              list: OrderStatusList,
              create: OrderStatusCreate,
              edit: OrderStatusEdit,
              options: {
                label: "Fluxo de entrega",
              },
              icon: <CalendarViewWeekIcon />,
            },

            {
              name: "couriers",
              list: CouriersList,
              edit: CourierEdit,
              create: CourierCreate,
              options: {
                label: "Entregadores",
              },
              icon: <BikeWhiteIcon />,
            },

            {
              name: "delivery-settings",
              list: DeliverySettingsList,
              edit: DeliverySettingsEdit,
              options: {
                label: "Config. Entrega",
              },
              icon: <TuneIcon />,
            },

            {
              name: "users",
              list: UsersList,
              show: UserShow,
              edit: UserEdit,
              create: UserCreate,
              options: {
                label: "Usuários",
              },
              icon: <PeopleOutlineOutlinedIcon />,
            },

            {
              name: "company-settings",
              list: SettingsList,
              edit: SettingsEdit,
              options: {
                label: "Configurações",
              },
              icon: <TuneIcon />,
            },
          ]}
        />
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
