import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Box, CircularProgress, Stack, Typography } from "@pankod/refine-mui";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { forwardRef, useEffect, useImperativeHandle } from "react";
import { ChartProps, ChartRefProps } from "../../pages/dashboard";
import { axiosInstance } from "../../authProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const OrderByHours = forwardRef<ChartRefProps, ChartProps>(
  ({ dates }, ref) => {
    const API_URL = useApiUrl();
    const url = `${API_URL}/dashboard/orderPerHourByPeriod/${dates[0]}/${dates[1]}`;

    const { data, isFetching, refetch } = useCustom<{
      totalHours: number;
      totalAmount: number;
      averageAmount: number;
      ordersByHour: Record<any, any>[];
    }>({
      queryOptions: {
        retry: false,
        cacheTime: 0,
        enabled: false,
        networkMode: "always",
      },
      errorNotification: false,
      url,
      method: "get",
    });

    useImperativeHandle(ref, () => ({
      load: refetch,
    }));

    useEffect(() => {
      refetch();

      const handleRefresh = (event: any) => {
        if (
          event.detail.url.indexOf("orderPerHourByPeriod") > -1 &&
          url.indexOf("orderPerHourByPeriod") > -1
        ) {
          axiosInstance.defaults.headers.common["Authorization"] =
            event.detail.authorization;
          refetch();
        }
      };
      window.addEventListener("token-refreshed", handleRefresh);
      return () => {
        window.removeEventListener("token-refreshed", handleRefresh);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const labels = data?.data?.ordersByHour?.map((order) => {
      const date = new Date(order.key);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const key = `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")} ${hour.toString().padStart(2, "0")}`;

      return key;
    });
    const values = data?.data?.ordersByHour?.map((order) => order.count);

    return (
      <Stack
        justifyContent="space-between"
        sx={{
          minHeight: "400px",
          p: 1,
          backgroundColor: "#5f4f7b",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 2, pt: 2 }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontWeight: 700, mb: 0 }}
          >
            Pedidos/Hora
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontWeight: 700, mb: 0 }}
          >
            Ticket Médio: {data?.data?.averageAmount}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="start"
          sx={{ px: 2 }}
        >
          <Typography
            variant="body1"
            sx={{ color: "#fff", fontWeight: 600, mb: 0 }}
          >
            Horas: {data?.data?.totalHours}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#fff", fontWeight: 600, mb: 0 }}
          >
            Total: {data?.data?.totalAmount}
          </Typography>
        </Stack>
        <Box sx={{ minHeight: "300px" }}>
          {isFetching ? (
            <Box display={"flex"} justifyContent="center">
              <CircularProgress sx={{ color: "#fff" }} />
            </Box>
          ) : (
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context: any) {
                        const value = values?.[context.dataIndex];
                        return ` ${value} pedidos`;
                      },
                    },
                  },
                },
                layout: {
                  padding: 20,
                },
                scales: {
                  x: {
                    ticks: {
                      color: "#fff",
                      maxRotation: 65,
                      minRotation: 65,
                    },
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    suggestedMax: 10,
                    suggestedMin: 0,
                    ticks: {
                      color: "#fff",
                      callback: function (value, index, ticks) {
                        return Math.ceil(Number(value));
                      },
                    },
                    grid: {
                      color: "rgba(255,255,255,0.3)",
                    },
                  },
                },
              }}
              data={{
                labels,
                datasets: [
                  {
                    data: values,
                    borderColor: "rgb(99, 255, 177)",
                    backgroundColor: "rgba(99, 255, 177, 0.5)",
                  },
                ],
              }}
            />
          )}
        </Box>
      </Stack>
    );
  }
);
