import { FC, useState } from "react";

import ReactECharts from "echarts-for-react";
import {
  Box,
  ButtonGroup,
  IconButton,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { colorByName } from "utils";
import { Button } from "@pankod/refine-mui";
import { goWeekForward, goMonthForward, dateToInput } from "localDateTime";

import NextPlan from "@mui/icons-material/NextPlan";

type ProductsOrderPeriodProps = {
  products: {
    daily: {
      [date: string]: {
        [id: string]: {
          quantity: number;
        };
      };
    };
    monthly: {
      [date: string]: {
        [id: string]: {
          quantity: number;
        };
      };
    };
    overall: {
      [id: string]: {
        name: string;
        quantity: number;
      };
    };
  };
  stateHandler: any;
};

export const ProductsOrderPeriod: FC<ProductsOrderPeriodProps> = ({
  products,
  stateHandler,
}) => {
  const overall = products.overall;

  //initial dates for daily should be the current week
  const [startDate, setStartDate] = useState(goWeekForward(new Date(), 0)[0]);
  const [endDate, setEndDate] = useState(goWeekForward(new Date(), 0)[1]);

  const [granularity, setGranularity] = useState<"daily" | "monthly">("daily");
  const labels = Object.keys(products[granularity] || {});

  const [selected] = stateHandler;

  if (!selected || selected.length === 0) {
    //centered message "Selecione 1 ou mais produtos para visualizar"
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="caption">
          Selecione 1 ou mais produtos para visualizar
        </Typography>
      </div>
    );
  }

  const filtered = Object.keys(overall).filter((productId) =>
    selected.includes(productId)
  );

  const productNames = filtered.map((productId) => overall[productId].name);

  const summary = ["Produtos", ...productNames];

  const dataset = labels.reduce((acc, date) => {
    if (
      granularity === "daily" &&
      (new Date(date) < startDate || new Date(date) > endDate)
    ) {
      return acc;
    }

    const data = filtered.map((productId) => {
      return products[granularity][date][productId]?.quantity || 0;
    });

    acc.push([date, ...data]);

    return acc;
  }, [] as any[]);

  const option = {
    grid: {
      left: "3%", // Adjust these values as needed
      right: "3%",
      top: "8%",
      bottom: "5%",
      containLabel: true,
    },
    confine: true,
    // dataZoom: [
    //   {
    //     type: "slider",
    //     start: 0,
    //     end: 100,
    //   },
    //   {
    //     type: "inside",
    //   },
    // ],
    tooltip: {
      trigger: "item",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        rotate: 60,
      },
    },
    yAxis: {},
    dataset: {
      source: [summary, ...dataset],
    },
    series: filtered.map((productId) => ({
      type: "bar",
      itemStyle: {
        color: colorByName(overall[productId].name),
      },
    })),
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <ButtonGroup
          size="small"
          variant="outlined"
          sx={{
            margin: "1rem 1rem 0",
          }}
        >
          <Button
            onClick={() => setGranularity("daily")}
            variant={granularity === "daily" ? "contained" : "outlined"}
          >
            Diário
          </Button>
          <Button
            onClick={() => setGranularity("monthly")}
            variant={granularity === "monthly" ? "contained" : "outlined"}
          >
            Mensal
          </Button>
        </ButtonGroup>

        {granularity === "daily" ? (
          <DateFiltersForWeek
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        ) : null}
      </Box>

      <ReactECharts
        style={{ height: "90%" }}
        option={option}
        key={selected.length}
      />
    </>
  );
};

export function DateFiltersForWeek({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  sx,
}: {
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
  sx?: any;
}) {
  return (
    <Box
      sx={
        sx || {
          display: "flex",
          margin: "1rem 1rem 0",
          alignItems: "center",
          gap: "1rem",
        }
      }
    >
      <TextField
        required
        margin="none"
        type="date"
        size="small"
        sx={{
          transform: "scale(0.77)",
        }}
        onChange={(e) => {
          let [, , day] = e.target.value.split("-").map(Number);
          //VERY BAD APPROACH
          day++;
          const date = new Date(e.target.value);
          date.setDate(day - 1);
          setStartDate(date);
        }}
        value={dateToInput(startDate, false)}
      />
      <TextField
        required
        margin="none"
        type="date"
        size="small"
        sx={{
          transform: "scale(0.77)",
          marginLeft: "-2.5rem",
          marginRight: "-1.5rem",
        }}
        onChange={(e) => {
          let [, , day] = e.target.value.split("-").map(Number);
          //VERY BAD APPROACH
          day++;
          const date = new Date(e.target.value);
          date.setDate(day - 1);
          setEndDate(date);
        }}
        value={dateToInput(endDate, false)}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton size="small">
          <NextPlan
            fontSize="small"
            onClick={() => {
              const [start, end] = goWeekForward(startDate, -1);
              setStartDate(start);
              setEndDate(end);
            }}
            sx={{
              transform: "scaleX(-1)",
            }}
          />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => {
            const [start, end] = goWeekForward(startDate, 1);
            setStartDate(start);
            setEndDate(end);
          }}
        >
          <NextPlan fontSize="small" />
        </IconButton>

        <Button
          size="small"
          sx={{
            marginLeft: "0.5rem",
          }}
          onClick={() => {
            const [start, end] = goWeekForward(new Date(), 0);
            setStartDate(start);
            setEndDate(end);
          }}
          variant={"outlined"}
        >
          Essa Semana
        </Button>

        <IconButton
          size="small"
          sx={{
            marginLeft: "1rem",
          }}
        >
          <NextPlan
            fontSize="small"
            onClick={() => {
              const [start, end] = goMonthForward(startDate, -1);
              setStartDate(start);
              setEndDate(end);
            }}
            sx={{
              transform: "scaleX(-1)",
            }}
          />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => {
            const [start, end] = goMonthForward(startDate, 1);
            setStartDate(start);
            setEndDate(end);
          }}
        >
          <NextPlan fontSize="small" />
        </IconButton>

        <Button
          size="small"
          sx={{
            marginLeft: "0.5rem",
          }}
          onClick={() => {
            const [start, end] = goMonthForward(new Date(), 0);

            setStartDate(start);
            setEndDate(end);
          }}
          variant={"outlined"}
        >
          Esse Mês
        </Button>
      </Box>
    </Box>
  );
}
