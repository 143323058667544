import { Box } from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const SEOSection: React.FC<SectionProps> = ({
  register,
  errors,
  upload,
}) => {
  const { Component: UploadImageFavicon, Viewer: ImageViewerFavicon } =
    upload?.favicon!;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <ImageViewerFavicon />
        <UploadImageFavicon />
      </Box>

      <InputTextField
        name="initialPageTitle"
        required
        label="Título da página inicial"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="metaDescription"
        required
        label="SEO Description"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="googleTag"
        label="Tag google analytics"
        register={register}
        errors={errors}
        registerProps={{
          min: 1,
        }}
        sx={{
          maxWidth: 200,
        }}
      />
    </Box>
  );
};
