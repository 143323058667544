import { Box } from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const TicketSection: React.FC<SectionProps> = ({ register, errors }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <InputTextField
        name="ticketHeader"
        label="Cabeçalho da comanda"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="ticketFooter"
        label="Rodapé da comanda"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="ticketCopies"
        type="number"
        label="Numero de cópias da comanda"
        register={register}
        errors={errors}
        registerProps={{
          min: 1,
        }}
        sx={{
          maxWidth: 200,
        }}
      />
    </Box>
  );
};
