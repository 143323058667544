import React from "react";
import { Create, Typography, SaveButton } from "@pankod/refine-mui";

import { useForm } from "@pankod/refine-react-hook-form";
import OrderStatusForm from "./OrderStatusForm";
import { errorNotificationHandler } from "../../utils";

export const OrderStatusCreate: React.FC = () => {
  const {
    register,
    control,
    formState: { errors },
    refineCore: { onFinish, formLoading },
    saveButtonProps,
    handleSubmit,
  } = useForm({
    refineCoreProps: {
      errorNotification: (error) =>
        errorNotificationHandler(error, "Erro ao criar fluxo de entrega"),
    },
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Criar Fluxo de entrega
        </Typography>
      }
      footerButtons={<SaveButton onClick={handleSubmit(onFinish)} />}
    >
      <OrderStatusForm register={register} control={control} errors={errors} />
    </Create>
  );
};
