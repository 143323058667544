import { useForm } from "@pankod/refine-react-hook-form";
import { SaveButton } from "@pankod/refine-mui";
import { errorNotificationHandler } from "../../utils";
import { useEffect, useState } from "react";

export function useCarouselForm() {
  const [items, setItems] = useState<any[]>([]);

  const {
    refineCore: {
      onFinish,
      formLoading,
      // @ts-ignore
      queryResult: { data },
    },
    saveButtonProps,
    register,
    setValue,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      errorNotification(error) {
        return errorNotificationHandler(error, "Erro ao salvar carrosel");
      },
    },
  });

  useEffect(() => {
    if (data?.data?.carousel_items) {
      setItems(data.data.carousel_items);
    }
  }, [data]);

  const SaveButtonCarousel = () => (
    <SaveButton
      onClick={() => {
        trigger().then((isValid) => {
          if (isValid) {
            setValue("items", items);
          }
          handleSubmit(onFinish)();
        });
      }}
    />
  );

  return {
    formLoading,
    saveButtonProps,
    register,
    errors,
    SaveButtonCarousel,
    control,
    setItems,
    items,
  };
}
