import React from "react";
import { Edit, Typography } from "@pankod/refine-mui";
import Products from "./Products";
import {
  Form,
  EnableInputField,
  InputTextField,
  InputNumberField,
  SelectInputField,
} from "../../components/form";
import { useCarouselForm } from "./useCarouselForm";

export const CarouselEdit: React.FC = () => {
  const {
    formLoading,
    saveButtonProps,
    errors,
    control,
    register,
    setItems,
    items,
    SaveButtonCarousel,
  } = useCarouselForm();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Editar Carrossel
        </Typography>
      }
      footerButtons={<SaveButtonCarousel />}
    >
      <Form>
        <InputTextField
          name="name"
          label="Nome"
          required
          register={register}
          errors={errors}
        />

        <SelectInputField
          control={control}
          errors={errors}
          name="location"
          label="Local"
          options={[
            { label: "Selecione", value: "" },
            { label: "Pagina inicial", value: "home" },
          ]}
          required
          sx={{ maxWidth: 300 }}
        />

        <InputNumberField
          name="order"
          label="Ordem"
          required
          register={register}
          errors={errors}
          sx={{ maxWidth: 150 }}
        />

        <EnableInputField
          name="enabled"
          control={control}
          label="Habilitada"
          defaultValue={false}
        />
      </Form>
      <Products setItems={setItems} items={items} formLoading={formLoading} />
    </Edit>
  );
};
