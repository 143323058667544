import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Stack,
  EditButton,
  DeleteButton,
  Typography,
  Box,
} from "@pankod/refine-mui";
import { boolToString } from "../../boolToString";
import { errorNotificationHandler } from "../../utils";

export const OrderStatusList: React.FC = () => {
  const { dataGridProps } = useDataGrid({
    //@ts-ignore
    initialCurrent: 1,
    initialPageSize: 10,
    initialSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
    permanentSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
    initialFilter: [],
    syncWithLocation: true,
  });

  const columns = React.useMemo<GridColumns>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 50,
        sortable: false,
        filterable: false,
        hideable: false,
      },
      {
        field: "name",
        headerName: "Status",
        minWidth: 400,
        flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,

        renderCell: function render(params) {
          return (
            <Box display={"flex"} flexDirection="column">
              <Typography variant="body2">{params.row.name}</Typography>
              <Typography variant="caption" color={"GrayText"}>
                {params.row.displayName}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "order",
        headerName: "Ordem",
        flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,
      },
      {
        field: "enabled",
        headerName: "Habilitado",
        flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,
        renderCell: function render(params) {
          return <span>{boolToString(params.row.enabled)}</span>;
        },
      },

      {
        headerName: "",
        headerAlign: "center",
        field: "actions",
        width: 140,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                disabled={!params.row.canDelete}
                hideText
                recordItemId={params.row.id}
                errorNotification={(error: any) => {
                  return errorNotificationHandler(
                    error,
                    "Erro ao deletar fluxo de entrega"
                  );
                }}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
      />
    </List>
  );
};
