import React from "react";
import {
  Box,
  TextField,
  Create,
  FormControlLabel,
  Switch,
  Typography,
} from "@pankod/refine-mui";

import { Controller, useForm } from "@pankod/refine-react-hook-form";

export const CourierCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      // @ts-ignore
      errorNotification: (error, values, resource) => {
        // @ts-ignore
        const details = error?.response?.data?.error?.details;
        if (details) {
          return {
            message: details.message || "Erro ao salvar",
            description: "Error",
            type: "error",
          };
        }

        return {
          message: "Erro ao salvar",
          description: "Error",
          type: "error",
        };
      },
    },
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Criar Entregador
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          error={!!errors?.name}
          //@ts-ignore
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nome"
          name="name"
          autoFocus
          sx={{ maxWidth: "300px" }}
        />

        <TextField
          {...register("address")}
          error={!!errors?.address}
          //@ts-ignore
          helperText={errors?.address?.message}
          margin="normal"
          fullWidth
          id="address"
          label="Endereço"
          name="address"
          defaultValue={" "}
        />

        <TextField
          {...register("phone")}
          error={!!errors?.phone}
          //@ts-ignore
          helperText={errors?.phone?.message}
          margin="normal"
          fullWidth
          id="phone"
          label="Telefone"
          name="phone"
          defaultValue={" "}
          sx={{ maxWidth: "200px" }}
        />

        <TextField
          {...register("accessCode")}
          error={!!errors?.accessCode}
          //@ts-ignore
          helperText={errors?.accessCode?.message}
          margin="normal"
          fullWidth
          id="accessCode"
          label="Código de acesso"
          name="accessCode"
          defaultValue={" "}
          sx={{ maxWidth: "200px" }}
        />

        <Controller
          control={control}
          name="enabled"
          defaultValue={true as any}
          render={({ field }) => (
            <FormControlLabel
              sx={{ marginTop: 2, width: "120px" }}
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                />
              }
              label="Habilitado"
            />
          )}
        />
      </Box>
    </Create>
  );
};
