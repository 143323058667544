import { useForm } from "@pankod/refine-react-hook-form";
import { SaveButton } from "@pankod/refine-mui";
import { dateToInput } from "../../localDateTime";
import { errorNotificationHandler } from "../../utils";
import { useEffect, useRef, useState } from "react";

export function useSalesForm() {
  const [error, setError] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const refDates = useRef<any>(null);

  const {
    refineCore: {
      onFinish,
      formLoading,
      // @ts-ignore
      queryResult: { data },
    },
    saveButtonProps,
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      // @ts-ignore
      errorNotification(error) {
        //roolback dates to original format
        const startDate_ = dateToInput(refDates.current.startDate);
        const endDate_ = dateToInput(refDates.current.endDate);
        setValue("startDate", startDate_);
        setValue("endDate", endDate_);

        // @ts-ignore
        const details = error?.response?.data?.error?.details;

        if (details?.details) {
          setError(details);
          setIsOpen(true);
        }
        return errorNotificationHandler(error, "Erro ao salvar promoção");
      },
    },
  });

  useEffect(() => {
    if (data?.data?.sale_items) {
      setItems(data.data.sale_items);
    }

    if (data?.data?.startDate) {
      setValue("startDate", dateToInput(new Date(data?.data?.startDate)));
    }

    if (data?.data?.endDate) {
      setValue("endDate", dateToInput(new Date(data?.data?.endDate)));
    }
  }, [data]);

  const SaveButtonSale = () => (
    <SaveButton
      onClick={() => {
        trigger().then((isValid) => {
          if (isValid) {
            const startDate_ = new Date(getValues("startDate"));
            const endDate_ = new Date(getValues("endDate"));

            refDates.current = {
              startDate: startDate_,
              endDate: endDate_,
            };

            setValue("startDate", startDate_.toISOString().slice(0, -8));
            setValue("endDate", endDate_.toISOString().slice(0, -8));
            setValue("sale_items", items);
          }
          handleSubmit(onFinish)();
        });
      }}
    />
  );

  return {
    error,
    isOpen,
    setIsOpen,
    formLoading,
    saveButtonProps,
    setValue,
    register,
    errors,
    SaveButtonSale,
    control,
    setItems,
    items,
  };
}
