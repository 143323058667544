import {
  useApiUrl,
  useCustom,
  useNavigation,
  useOne,
  useShow,
} from "@pankod/refine-core";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@pankod/refine-mui";

import { useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { localDateTimeStr } from "../../localDateTime";
import Products from "./Products";
import { printTemplate } from "../../print";
import OrderHistoryTimeline from "../../components/history/HistoryTimeline";

export const ShowOrder: React.FC = () => {
  const { queryResult } = useShow();

  const { goBack } = useNavigation();

  const { id } = useParams();

  const API_URL = useApiUrl();

  const { data } = queryResult;
  const record = data?.data;

  const {
    //@ts-ignore
    data: dataCourier,
  } = useOne({
    resource: "couriers",
    id: record?.courier?.id ?? "",
  });

  const { data: settings } = useCustom({
    url: `${API_URL}/company-settings`,
    method: "get",
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={
              <IconButton onClick={goBack}>
                <ArrowBackIcon />
              </IconButton>
            }
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
            }}
            title={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h5">{record?.code ?? ""}</Typography>

                {record?.accepted === true && record?.statusName && (
                  <Chip color="info" label={record?.statusName} />
                )}

                {record?.accepted === true && record?.deliveryTime && (
                  <Chip
                    color="warning"
                    variant="outlined"
                    label={`de ${record?.deliveryTime[0]}min à ${record?.deliveryTime[1]}min`}
                  />
                )}

                <Button
                  onClick={() => {
                    let newWindow = window.open();

                    const _settings =
                      settings?.data?.data?.[0]?.attributes || {};

                    newWindow?.document.write(
                      printTemplate(
                        {
                          header: _settings?.ticketHeader || "",
                          footer: _settings?.ticketFooter || "",
                          copies: _settings?.ticketCopies,
                        },
                        record,
                        {
                          currency: _settings?.currency || "BRL",
                          language: _settings?.language || "pt-BR",
                        }
                      )
                    );
                    newWindow?.document.close();
                    newWindow?.focus();
                    newWindow?.print();
                    newWindow?.close();
                  }}
                  style={{ marginTop: 5 }}
                >
                  Imprimir
                </Button>
              </Stack>
            }
          />
          <CardContent sx={{ p: 2 }}>
            <Box sx={{ px: 2 }} display="flex" flexDirection="column" gap={4}>
              <Box display="flex" gap={4}>
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Criado em
                  </Typography>
                  <Typography variant="body2">
                    {record?.createdAt
                      ? localDateTimeStr(record?.createdAt)
                      : ""}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Forma de pagamento
                  </Typography>
                  <Typography variant="body2">
                    {record?.paymentMethod.name || ""}
                    {record?.moneyChange ? (
                      <> {`(Troco para ${record?.moneyChangeFormatted})`}</>
                    ) : null}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" gap={4}>
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Subtotal
                  </Typography>
                  <Typography variant="body2">
                    {record?.subtotalFormatted || ""}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Taxa de entrega
                  </Typography>
                  <Typography variant="body2">
                    {record?.shippingCostFormatted || ""}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Total
                  </Typography>
                  <Typography variant="body2">
                    {record?.totalFormatted || ""}
                  </Typography>
                </Box>
              </Box>

              {record?.discount && (
                <Box display="flex" gap={4}>
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ marginTop: 2 }}
                    >
                      Cupom
                    </Typography>
                    <Typography variant="body2">
                      {record?.discount.code || ""}
                    </Typography>
                    <Typography variant="caption">
                      {record?.discount.type === "percent" ? (
                        <>
                          {record?.discount.formattedAmount}% (max:{" "}
                          {record?.discount.formattedMaxDiscountAllowed})
                        </>
                      ) : (
                        <>
                          {record?.discount.formattedAmount} (min:
                          {record?.discount.formattedMinOrderAmount})
                        </>
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ marginTop: 2 }}
                    >
                      Desconto
                    </Typography>
                    <Typography variant="body2">
                      {record?.discountAmountFormatted || ""}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            {record?.notes && (
              <Box sx={{ px: 2, marginTop: 3 }} display="flex" gap={4}>
                <Box>
                  <Typography variant="body1" fontWeight="bold">
                    Observações do pedido
                  </Typography>
                  <Typography variant="body2">{record?.notes || ""}</Typography>
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Stack
                direction="row"
                sx={{ px: 2 }}
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h5">Dados do cliente e entrega</Typography>
              </Stack>
            }
          />
          <CardContent>
            <Box sx={{ px: 2 }} display="flex" gap={4}>
              <Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ marginTop: 2 }}
                >
                  Nome
                </Typography>
                <Typography variant="body2">
                  {record?.customer || ""}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ marginTop: 2 }}
                >
                  Telefone
                </Typography>
                <Typography variant="body2">{record?.phone || ""}</Typography>
              </Box>
            </Box>

            <Box
              sx={{ px: 2, mt: 2 }}
              display="flex"
              flexDirection={"column"}
              gap={1}
            >
              <Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ marginTop: 2 }}
                >
                  Endereço
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  {record?.direction ? record?.direction + ", " : ""}
                  {record?.number ? record?.number + ", " : ""}
                  {record?.addressComplement
                    ? record?.addressComplement + ", "
                    : ""}
                  {record?.neighbourhood?.name || ""}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  {record?.addressNotes || ""}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {record?.courier && (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Stack
                  direction="row"
                  sx={{ px: 2 }}
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="h5">Entregador</Typography>
                </Stack>
              }
            />
            <CardContent>
              <Box sx={{ px: 2 }} display="flex" gap={4}>
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Nome
                  </Typography>
                  <Typography variant="body2">
                    {dataCourier?.data?.name || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Telefone
                  </Typography>
                  <Typography variant="body2">
                    {dataCourier?.data?.phone || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{ px: 2, mt: 2 }}
                display="flex"
                flexDirection={"column"}
                gap={1}
              >
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginTop: 2 }}
                  >
                    Endereço
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    {dataCourier?.data?.adrress || "-"}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Stack
                direction="row"
                sx={{ px: 2 }}
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h5">Produtos</Typography>
              </Stack>
            }
          />
          <CardContent sx={{ p: 2 }}>
            <Box sx={{ px: 2 }} display="flex" gap={4}>
              <Products items={record?.items} />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Stack
                direction="row"
                sx={{ px: 2 }}
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h5">Histórico</Typography>
              </Stack>
            }
          />
          <CardContent sx={{ p: 2 }}>
            <OrderHistoryTimeline id={id} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
