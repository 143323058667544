export function localDateTimeStr(date: any, withoutYear: boolean = false) {
  let _startDate = new Date(date);
  let startDate = _startDate.toLocaleDateString();

  if (withoutYear) {
    startDate = startDate.split("/").reverse().slice(1).reverse().join("/");
  } else {
    startDate = startDate.split("/").reverse().join("/");
  }

  let startTime = _startDate.toLocaleTimeString();
  startTime = startTime.slice(0, startTime.length - 3);
  return `${startDate} ${startTime}`;
}

export function localDateLabel(date: any) {
  let _date = new Date(date);
  let dateStr = _date.toLocaleDateString();
  return `${dateStr}`;
}

export function parseUTCDateToInputString(utcDateStr: any) {
  const date = new Date(utcDateStr);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function dateToInput(date: any, hasTime: boolean = true) {
  let startDate = date.toLocaleDateString();
  let startTime = date.toLocaleTimeString();
  startDate = startDate.replace(/\//g, "-").split("-").reverse().join("-");

  if (!hasTime) {
    return startDate.split("T")[0];
  }

  return startDate + "T" + startTime.slice(0, -3);
}

function getStartOfWeek(date: any) {
  const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when Sunday
  return new Date(date.getFullYear(), date.getMonth(), diff);
}

function getEndOfWeek(date: any) {
  const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  const diff = date.getDate() + (7 - dayOfWeek); // next Sunday, and subtract 1 to make it Saturday
  return new Date(date.getFullYear(), date.getMonth(), diff);
}

export function goWeekForward(date: any, weeks: any) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + weeks * 7); // Move forward by `weeks` number of weeks

  const startOfWeek = getStartOfWeek(newDate);
  const endOfWeek = getEndOfWeek(newDate);

  return [startOfWeek, endOfWeek];
}

export function goMonthForward(date: any, months: any) {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);

  const startOfMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  const endOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);

  return [startOfMonth, endOfMonth];
}
