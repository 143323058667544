import React from "react";
import { Box, Button, Edit, Typography } from "@pankod/refine-mui";
import { Form, EnableInputField } from "../../components/form";
import { useMosaicForm } from "./useMosaicForm";
import { MosaicItem } from "pages/mosaic/MosaicItem";

import { List } from "@mui/material";
import { MosaicPreview } from "pages/mosaic/MosaicPreview";

export const stylesMosaic = {
  layout: (active: boolean) => ({
    border: `solid 0.15rem ${active ? "#CCC" : "transparent"}`,
    borderRadius: "0.25rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
    width: 120,
  }),
  cell: {
    display: "flex",
    flexDirection: "row",
    gap: "0.25rem",
    justifyContent: "start",
    width: "100%",
  },
  item: {
    background: "#CCC",
    height: "30px",
    flex: "1",
  },
};

export const MosaicEdit: React.FC = () => {
  const {
    formLoading,
    saveButtonProps,
    errors,
    control,
    register,
    SaveButtonMosaic,
    schemas,
    getValues,
    changeSchema,
    fields,
  } = useMosaicForm();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Mosaico
        </Typography>
      }
      footerButtons={<SaveButtonMosaic />}
    >
      <Form>
        <Typography variant="h6" color="inherit" component="div">
          Formato
        </Typography>
        <Box
          mb={3}
          gap={2}
          display={"flex"}
          flexDirection={"row"}
          flexWrap="wrap"
        >
          {schemas.map((schema, index) => (
            <Button
              sx={stylesMosaic.layout(getValues("schema") === index)}
              key={index}
              onClick={() => changeSchema(index)}
            >
              {schema.map((cell, index) => (
                <Box sx={stylesMosaic.cell} key={index}>
                  {cell.map((item, index) => (
                    <Box sx={stylesMosaic.item} key={index}></Box>
                  ))}
                </Box>
              ))}
            </Button>
          ))}
        </Box>

        <MosaicPreview getValues={getValues} />

        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {fields.map((_: any, index: number) => (
            <MosaicItem
              register={register}
              key={index}
              errors={errors}
              index={index}
            />
          ))}
        </List>

        <EnableInputField
          name="enabled"
          control={control}
          label="Habilitada"
          defaultValue={false}
        />
      </Form>
    </Edit>
  );
};
