import { Edit, Typography } from "@pankod/refine-mui";

import CategoryForm from "pages/categories/CategoryForm";
import { useCategoriesForm } from "pages/categories/useCategoriesForm";

export const CategoryEdit: React.FC = () => {
  const {
    formLoading,
    isSubmitting,
    saveButtonProps,
    register,
    errors,
    SaveButtonSale,
    control,
    ImageViewer,
    UploadImage,
    clearDates,
    nextDay,
    hasDates,
  } = useCategoriesForm();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Editar Categoria
        </Typography>
      }
      footerButtons={<SaveButtonSale />}
    >
      <CategoryForm
        ImageViewer={ImageViewer}
        UploadImage={UploadImage}
        errors={errors}
        control={control}
        register={register}
        isSubmitting={isSubmitting}
        formLoading={formLoading}
        clearDates={clearDates}
        nextDay={nextDay}
        hasDates={hasDates}
      />
    </Edit>
  );
};
