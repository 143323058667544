import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
} from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { Fragment, useState } from "react";

type MosaicItemProps = {
  index: number;
  register: any;
  errors: any;
};

export function MosaicItem({ register, errors, index }: MosaicItemProps) {
  const [open, setOpen] = useState(false);
  const handleClick = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Fragment key={index}>
      <ListItemButton onClick={() => handleClick(!open)}>
        <ListItemText primary={`Slot ${index + 1}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <Box
          sx={{
            padding: 2,
            mt: 2,
          }}
        >
          <InputTextField
            name={`slots.${index}.label`}
            label="Nome"
            required
            register={register}
            exactError={errors?.slots?.[index]?.label}
            errors={`${errors}`}
          />
        </Box>
      </Collapse>
    </Fragment>
  );
}
