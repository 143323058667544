import {
  Button,
  DialogContent,
  DialogContentText,
  Link,
  ListItem,
  ListItemText,
  MuiList,
  Typography,
} from "@pankod/refine-mui";
import { DialogActions } from "@pankod/refine-mui";
import { Dialog, DialogTitle } from "@pankod/refine-mui";

export default function ErrorDetails({
  error,
  openSettings,
}: {
  error: any;
  openSettings: [boolean, (isOpen: boolean) => void];
}) {
  const [isOpen, setIsOpen] = openSettings;

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{error?.message}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <MuiList>
            {Object.values(error?.details || []).map((detail: any) => (
              <>
                <ListItem
                  dense
                  sx={{
                    py: 0,
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="button">{detail.name}</Typography>
                    }
                  />
                </ListItem>
                {detail.sales.map((sale: any) => (
                  <ListItem
                    dense
                    sx={{
                      pt: 0,
                      pb: 2,
                      pl: 4,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="caption">{sale.name}</Typography>
                      }
                      secondary={
                        <>
                          <Link href={`/sales/edit/${sale.id}`} target="_blank">
                            Abrir em nova aba
                          </Link>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </>
            ))}
          </MuiList>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
