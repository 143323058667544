import { Box } from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const CourierSection: React.FC<SectionProps> = ({
  register,
  errors,
  upload,
}) => {
  const { Component: UploadImagePwaDelivery, Viewer: ImageViewerPwaDelivery } =
    upload?.pwa!;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <ImageViewerPwaDelivery />
        <UploadImagePwaDelivery />
      </Box>

      <InputTextField
        name="pwaBackgroundColorDelivery"
        label="Cor de fundo do App de Entrega"
        register={register}
        errors={errors}
      />

      <InputTextField
        name="deliveryTimeOptions"
        label="Opções de tempo de entrega"
        register={register}
        errors={errors}
      />
    </Box>
  );
};
