import { CloseRounded } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Select } from "@pankod/refine-mui";
import { DataGridContext } from "components/DataGrid/context";
import React from "react";

type DataGridFilterSelectProps = {
  field: string;
  emptyLabel?: string;
  options: { value: string; label: string }[];
};

const DataGridFilterSelect = ({
  field,
  options,
  emptyLabel = "Todos",
}: DataGridFilterSelectProps) => {
  const { state } = React.useContext(DataGridContext);

  const [value, setValue] = React.useState(state.filters[field]);

  return (
    <Select
      name={field}
      size="small"
      sx={{ flex: 1 }}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      displayEmpty={true}
      endAdornment={
        value ? (
          <IconButton
            size="small"
            sx={{ mr: 1 }}
            onClick={() => {
              setValue("");
            }}
          >
            <CloseRounded />
          </IconButton>
        ) : null
      }
    >
      <MenuItem value="">{emptyLabel}</MenuItem>
      {options.map((option, key) => (
        <MenuItem key={`${option}_${key}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DataGridFilterSelect;
