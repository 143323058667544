import { FormControlLabel, Switch, SxProps } from "@pankod/refine-mui";
import {
  Control,
  Controller,
  FieldValues,
} from "@pankod/refine-react-hook-form";

interface EnableInputFieldProps {
  control: Control<FieldValues>;
  sx?: SxProps;
  label: string;
  disabled?: boolean;
  name: string;
  defaultValue?: boolean;
}

export const EnableInputField: React.FC<EnableInputFieldProps> = ({
  control,
  sx,
  label,
  disabled,
  name,
  defaultValue = true,
}) => {
  const _sx = { marginTop: 2, width: 120, ...sx };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControlLabel
          sx={_sx}
          control={
            <Switch
              {...field}
              disabled={disabled}
              checked={field.value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
            />
          }
          label={label}
        />
      )}
    />
  );
};
