import { Box, Button, Stack, Typography, useTheme } from "@pankod/refine-mui";
import { goWeekForward, localDateLabel } from "localDateTime";
import { DateFiltersForWeek } from "pages/analytics/products/ProductsOrderPeriod";
import { FC, useState } from "react";

type SoldPerPeriodProps = {
  products: {
    daily: {
      [date: string]: {
        [id: string]: {
          quantity: number;
        };
      };
    };
    monthly: {
      [date: string]: {
        [id: string]: {
          quantity: number;
        };
      };
    };
    overall: {
      [id: string]: {
        name: string;
        quantity: number;
      };
    };
  };
};

export const SoldPerPeriod: FC<SoldPerPeriodProps> = ({ products }) => {
  const theme = useTheme();

  const [startDate, setStartDate] = useState(goWeekForward(new Date(), 0)[0]);
  const [endDate, setEndDate] = useState(goWeekForward(new Date(), 0)[1]);

  const granularity = "daily";
  const labels = Object.keys(products[granularity] || {});

  const productsByPeriod = labels
    .reduce((acc, date) => {
      if (
        granularity === "daily" &&
        (new Date(date) < startDate || new Date(date) > endDate)
      ) {
        return acc;
      }

      const data = Object.keys(products[granularity][date])
        .map((productId) => {
          const exists = acc.find((item) => item.id === productId);
          if (exists) {
            exists.quantity += products[granularity][date][productId]?.quantity;
            return false;
          }

          if (products[granularity][date][productId]?.quantity > 0) {
            return {
              id: productId,
              name: products.overall[productId].name,
              quantity: products[granularity][date][productId]?.quantity,
            };
          }
        })
        .filter((item) => item) as any[];

      acc.push(...data);

      return acc;
    }, [] as any[])
    .sort((a, b) => {
      return b.quantity - a.quantity;
    });

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        py: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, px: 2 }}>
        Produtos vendidos por período
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <DateFiltersForWeek
          sx={{
            display: "flex",
            margin: "0 0 1rem -3px",
            alignItems: "center",
            gap: "1rem",
          }}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        {productsByPeriod.length > 0 && (
          <Button
            size="small"
            sx={{
              marginRight: "1rem",
              alignSelf: "center",
            }}
            variant={"contained"}
            onClick={() => downloadCsv(startDate, endDate, productsByPeriod)}
          >
            Exportar
          </Button>
        )}
      </Box>

      <Box
        sx={{
          overflowY: "auto",
          display: "flex",
          flexWrap: "wrap",
          gap: "4%",
          border: "1px solid #e0e0e0",
          px: 2,
          py: 2,
          mx: 2,
        }}
      >
        {!productsByPeriod ||
          (productsByPeriod.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "1rem",
              }}
            >
              <Typography variant="caption">
                Nenhum produto vendido no período selecionado
              </Typography>
            </div>
          ))}

        {productsByPeriod.map((product, key, arr) => (
          <Box
            display="flex"
            justifyContent="space-between"
            key={product.id}
            sx={{
              py: 1,
              width: "29%",
              px: 1,
              borderBottom: arr.length - key < 4 ? "none" : "1px solid #e0e0e0",
              ":hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <Typography variant="caption">{product.name}</Typography>
            <Typography variant="caption">{product.quantity}</Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

const downloadCsv = (startDate: Date, endDate: Date, data: any) => {
  const rows = [
    ["Produtos vendidos por período"],
    [
      "\nPeríodo: " +
        localDateLabel(startDate) +
        " - " +
        localDateLabel(endDate),
    ],
    ["Id", "Nome", "Quantidade"],
  ];

  data.forEach((row: any) => {
    rows.push([
      '"' + row.id.toString() + '"',
      '"' + row.name.toString() + '"',
      '"' + row.quantity + '"',
    ]);
  });

  let csvContent = rows.map((e) => e.join(";")).join("\n");

  var link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
  );
  link.setAttribute(
    "download",
    `produtos_vendidos_${localDateLabel(startDate).replace(
      /\s/g,
      "_"
    )}-${localDateLabel(startDate).replace(/\s/g, "_")}.csv`
  );
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
