import { useList, useModal } from "@pankod/refine-core";
import { Box, CreateButton, Typography } from "@pankod/refine-mui";
import { useModalForm } from "@pankod/refine-react-hook-form";
import { DataGridTable } from "components/DataGrid/DataGridTable";
import { dataGridColumnFactory } from "components/DataGrid/components/Column";
import RefreshButton from "components/DataGrid/components/RefreshButton";
import DataGridFilterEnabled from "components/DataGrid/components/filter/DataGridFilterEnabled";
import DataGridFilterSelect from "components/DataGrid/components/filter/DataGridFilterSelect";
import DataGridFilterSort from "components/DataGrid/components/filter/DataGridFilterSort";
import DataGridFilterText from "components/DataGrid/components/filter/DataGridFilterText";
import { AvailabilityProducts } from "components/product/AvailabilityProducts";
import { QuickEditProduct } from "components/product/quickEdit";
import { ProductImage } from "pages/products/ProductImage";
import BatchOperation from "pages/products/batchOperation";
import React from "react";

const columns = [
  dataGridColumnFactory({
    accessorKey: "name",
    header: "Nome",
    size: 500,
    Cell: ({ attributes }) => (
      <Box display={"flex"} alignItems={"center"}>
        <ProductImage image={attributes.image} />
        <Typography variant="body2">{attributes.name}</Typography>
      </Box>
    ),
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.formattedPrice",
    header: "Preço",
  }),
  dataGridColumnFactory({
    accessorKey: "enabled",
    header: "Habilitado",
    isBoolean: true,
  }),
];

export const ProductsList: React.FC = () => {
  const { data: categoriesList } = useList<any>({
    resource: "categories",
    config: {
      hasPagination: false,
    },
  });

  const availabilityModal = useModal();

  const editDrawerFormProps = useModalForm({
    refineCoreProps: {
      action: "edit",
      metaData: {
        populate: ["image"],
      },
      onMutationSuccess() {
        DataGridTable.refresh("products-list");
      },
      redirect: false,
    },
  });

  const {
    modal: { show: showEditDrawer },
  } = editDrawerFormProps;

  return (
    <>
      <QuickEditProduct {...editDrawerFormProps} />
      <AvailabilityProducts {...availabilityModal} />
      <DataGridTable<{
        name: string;
        category: string;
        enabled: string;
      }>
        id="products-list"
        initialSort={{
          field: "name",
          order: "asc",
        }}
        endpoint="products"
        header={
          <Typography variant="h5">
            Produtos
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={availabilityModal.show}
            >
              Ver Disponibilidade
            </Typography>
          </Typography>
        }
        columns={columns}
        initialState={{
          name: "",
          enabled: "",
          category: "",
        }}
        headerActions={
          <>
            <RefreshButton />
            <BatchOperation />
            <CreateButton />
          </>
        }
        filterComponent={
          <>
            <DataGridFilterText field="name" />
            <DataGridFilterEnabled />
            <DataGridFilterSelect
              emptyLabel="Todas as categorias"
              field="category"
              options={
                categoriesList?.data.map((category: any) => ({
                  value: category.id,
                  label: category.name,
                })) || []
              }
            />
            <DataGridFilterSort
              fields={[
                {
                  name: "name",
                  label: "Nome",
                },
                {
                  name: "price",
                  label: "Preço",
                },
              ]}
            />
          </>
        }
        queryPipeline={(f) => {
          const query: Record<string, string> = {};
          if (f.name) {
            query["filters[name][$containsi]"] = f.name;
          }

          if (f.enabled) {
            query["filters[enabled][$eq]"] = f.enabled;
          }

          if (f.category) {
            query["filters[categories][$in][0]"] = f.category;
          }

          query["populate[image][fields][0]"] = "url";
          return query;
        }}
        onClickRow={showEditDrawer}
      />
    </>
  );
};
