import React from "react";

export type OpenSelectProductProps = {
  multiple?: boolean;
  open?: boolean;
  onClose?: () => void;
  onSelected?: (selectedItems: any[]) => void;
  closeSelectProduct?: () => void;
  disabledItems?: number[];
};

export const useSelectProductDialog = () => {
  const [open, setOpen] = React.useState(false);

  const openSelectProduct = () => {
    setOpen(true);
  };
  const closeSelectProduct = () => setOpen(false);

  return {
    open,
    openSelectProduct,
    closeSelectProduct,
    disabledItems: [],
  };
};
