import React from "react";
import { Create, Typography } from "@pankod/refine-mui";

import ErrorDetails from "./ErrorDetails";
import Products from "./Products";

import { Form, EnableInputField, InputTextField } from "../../components/form";
import { slugfy } from "../../utils";
import { DateTimeInput } from "../../components/form/DateTimeInput";
import { useSalesForm } from "./useSalesForm";

export const SaleCreate: React.FC = () => {
  const {
    error,
    isOpen,
    setIsOpen,
    formLoading,
    saveButtonProps,
    setValue,
    register,
    errors,
    SaveButtonSale,
    control,
    setItems,
    items,
  } = useSalesForm();

  return (
    <>
      <ErrorDetails error={error} openSettings={[isOpen, setIsOpen]} />

      <Create
        isLoading={formLoading}
        saveButtonProps={saveButtonProps}
        title={
          <Typography variant="h5" color="inherit" component="div">
            Criar Promoção
          </Typography>
        }
        footerButtons={<SaveButtonSale />}
      >
        <Form>
          <InputTextField
            name="name"
            label="Nome"
            required
            register={register}
            errors={errors}
          />

          <InputTextField
            name="slug"
            label="Slug"
            required
            register={register}
            onBlur={(e) => {
              setValue("slug", slugfy(e.target.value));
            }}
            errors={errors}
          />

          <InputTextField
            name="description"
            label="Descrição"
            register={register}
            errors={errors}
            sx={{ maxWidth: "100%" }}
          />

          <DateTimeInput
            name="startDate"
            label="Data de início"
            register={register}
            errors={errors}
            required
          />

          <DateTimeInput
            name="endDate"
            label="Data de encerramento"
            register={register}
            errors={errors}
            required
          />

          <EnableInputField
            name="enabled"
            control={control}
            label="Habilitada"
            defaultValue={false}
          />
        </Form>

        <Products setItems={setItems} items={items} formLoading={formLoading} />
      </Create>
    </>
  );
};
