import React from "react";
import { NumericFormat } from "react-number-format";

import "./previewProduct.css";

export default function PreviewProduct({ watchFn, previewImagebase64 }: any) {
  const [previewImagebase64_, setPreviewImagebase64] =
    React.useState<any>(previewImagebase64);

  const name = watchFn("name");
  const description = watchFn("description");
  const price = watchFn("price");
  const image = watchFn("image");

  function priceLabel() {
    return (
      <NumericFormat
        prefix="R$ "
        value={price}
        thousandSeparator={"."}
        decimalSeparator={","}
        fixedDecimalScale
        decimalScale={2}
        displayType="text"
      />
    );
  }

  //listen for custom event onProductImageChange that contains the base64 image
  //and set the state
  React.useEffect(() => {
    window.addEventListener("onProductImageChange", (e: any) => {
      setPreviewImagebase64(e.detail);
    });
  }, []);

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <div className="product__item">
        <div className="product__item-img">
          <div
            className="product__img"
            style={{
              backgroundImage: `url(${previewImagebase64_ || image?.url})`,
            }}
          ></div>
        </div>
        <div className="product__content ">
          <span className="title">
            {name}
            <span className="description">{description}</span>
          </span>
          <div className="d-flex align-items-center justify-content-between">
            <span className="product__price has-sale"> {priceLabel()} </span>
            <span className="product__sale_price">
              {priceLabel()}{" "}
              <span className="inner__regular_price"> {priceLabel()} </span>
            </span>
            <button className="addTOCart__btn "></button>
          </div>
        </div>
      </div>

      <div className="product__item mobile">
        <div className="product__item-img mobile">
          <div
            className="product__img mobile"
            style={{
              backgroundImage: `url(${previewImagebase64_ || image?.url})`,
            }}
          ></div>
        </div>
        <div className="product__content mobile">
          <span className="title">
            {name}
            <span className="description">{description}</span>
          </span>
          <div className="d-flex align-items-center justify-content-between">
            <span className="product__price mobile has-sale">
              {priceLabel()}
            </span>
            <span className="product__sale_price mobile">
              {priceLabel()}{" "}
              <span className="inner__regular_price mobile">
                {priceLabel()}
              </span>
            </span>
            <button className="addTOCart__btn mobile "></button>
          </div>
        </div>
      </div>
    </div>
  );
}
