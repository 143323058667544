import { CarouselCreate } from "pages/carousel/create";
import { CarouselEdit } from "pages/carousel/edit";
import { CarouselList } from "pages/carousel/list";

import PanoramaWideAngleIcon from "@mui/icons-material/PanoramaWideAngle";

const CarouselsConfig = {
  name: "carousels",
  list: CarouselList,
  create: CarouselCreate,
  edit: CarouselEdit,
  options: {
    label: "Carrossels",
  },
  icon: <PanoramaWideAngleIcon />,
};

export { CarouselsConfig };
