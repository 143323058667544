import { Box } from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const PWASiteSection: React.FC<SectionProps> = ({
  register,
  errors,
  upload,
}) => {
  const { Component: UploadImagePwa, Viewer: ImageViewerPwa } = upload?.pwa!;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <ImageViewerPwa />
        <UploadImagePwa />
      </Box>

      <InputTextField
        name="pwaBackgroundColor"
        label="Cor de fundo do app"
        register={register}
        errors={errors}
      />
    </Box>
  );
};
