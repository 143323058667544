import { OpenNotificationParams } from "@pankod/refine-core";

export function getRandomColor() {
  var letters = "0123456789ABCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function errorNotificationHandler(
  error: unknown,
  defaultMessage?: string
) {
  //@ts-ignore
  const error_ = error?.response?.data?.error as {
    message: string;
    details: { message: string };
  };

  let message = error_?.details?.message || error_?.message;

  if (message === "Bad Request") {
    message = defaultMessage || "Ocorreu um erro";
  }

  return {
    message,
    type: "error" as OpenNotificationParams["type"],
  };
}

export function slugfy(text: string) {
  return (text || "")
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/&/g, "-e-")
    .replace(/[\s\W-]+/g, "-");
}

const colors = ["#808080", "#f0d99c", "#de987d", "#bf444c"];
const thresholds = [0, 0.5, 0.75, 1];

export function interpolateColor(factor: number) {
  let color1 = colors[3];
  let color2 = colors[3];
  for (let i = 0; i < thresholds.length - 1; i++) {
    if (factor <= thresholds[i + 1]) {
      color1 = colors[i];
      color2 = colors[i + 1];
      break;
    }
  }

  const r1 = parseInt(color1.slice(1, 3), 16),
    g1 = parseInt(color1.slice(3, 5), 16),
    b1 = parseInt(color1.slice(5, 7), 16),
    r2 = parseInt(color2.slice(1, 3), 16),
    g2 = parseInt(color2.slice(3, 5), 16),
    b2 = parseInt(color2.slice(5, 7), 16);

  const r = Math.round(r1 + (r2 - r1) * factor)
      .toString(16)
      .padStart(2, "0"),
    g = Math.round(g1 + (g2 - g1) * factor)
      .toString(16)
      .padStart(2, "0"),
    b = Math.round(b1 + (b2 - b1) * factor)
      .toString(16)
      .padStart(2, "0");

  return "#" + r + g + b;
}

export function colorByName(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate color components from hash
  const hue = Math.abs(hash % 360); // Use modulo to keep it within the range [0, 360)
  const saturation = 70 + Math.abs(hash % 30); // Adjust saturation to make colors more diverse
  const lightness = 50 + Math.abs(hash % 20); // Adjust lightness to make colors more diverse

  // Convert HSL to RGB
  const h = hue / 360;
  const s = saturation / 100;
  const l = lightness / 100;
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h * 6) % 2) - 1));
  const m = l - c / 2;
  let r, g, b;

  if (h < 1 / 6) {
    r = c;
    g = x;
    b = 0;
  } else if (h < 2 / 6) {
    r = x;
    g = c;
    b = 0;
  } else if (h < 3 / 6) {
    r = 0;
    g = c;
    b = x;
  } else if (h < 4 / 6) {
    r = 0;
    g = x;
    b = c;
  } else if (h < 5 / 6) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Convert RGB to hexadecimal
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
