import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@pankod/refine-mui";

import { Revenue } from "../../components/dashboard/Revenue";
import { OpenSales } from "../../components/dashboard/OpenSales";
import { CourierTax } from "../../components/dashboard/CourierTax";
import { dateToInput, parseUTCDateToInputString } from "../../localDateTime";
import { PaymentMethods } from "../../components/dashboard/PaymentMethods";
import { OrderByHours } from "../../components/dashboard/OrderByHours";
import { DailyOrders } from "../../components/dashboard/OrderCount";

export interface ChartProps {
  dates: [string, string];
}

export interface ChartRefProps {
  load: () => void;
}

let timeout: any = null;

export const DashboardPage: React.FC = () => {
  const RevenueRef = React.useRef<ChartRefProps>(null);
  const DailyOrdersRef = React.useRef<ChartRefProps>(null);
  const OpenSalesRef = React.useRef<ChartRefProps>(null);
  const PaymentMethodsRef = React.useRef<ChartRefProps>(null);
  const TaxCourierRef = React.useRef<ChartRefProps>(null);
  const OrderByHoursRef = React.useRef<ChartRefProps>(null);

  const [startDate, setStartDate] = React.useState<string>(() => {
    let dateToReturn = dateToInput(new Date());
    if (sessionStorage.getItem("dashboardStartDate")) {
      try {
        dateToReturn = parseUTCDateToInputString(
          JSON.parse(sessionStorage.getItem("dashboardStartDate") as string)
        );
      } catch (e) {
        sessionStorage.removeItem("dashboardStartDate");
      }
    }

    return dateToReturn;
  });

  const [endDate, setEndDate] = React.useState<string>(() => {
    let dateToReturn = dateToInput(new Date());
    if (sessionStorage.getItem("dashboardEndDate")) {
      try {
        dateToReturn = parseUTCDateToInputString(
          JSON.parse(sessionStorage.getItem("dashboardEndDate") as string)
        );
      } catch (e) {
        sessionStorage.removeItem("dashboardEndDate");
      }
    }

    return dateToReturn;
  });

  const [debouncedDate, setDebouncedDate] = React.useState<[string, string]>(
    () => {
      let startDate_, endDate_;

      if (sessionStorage.getItem("dashboardStartDate")) {
        startDate_ = JSON.parse(
          sessionStorage.getItem("dashboardStartDate") as string
        );
      } else {
        startDate_ = new Date().toISOString().slice(0, -8);
      }

      if (sessionStorage.getItem("dashboardEndDate")) {
        endDate_ = JSON.parse(
          sessionStorage.getItem("dashboardEndDate") as string
        );
      } else {
        endDate_ = new Date().toISOString().slice(0, -8);
      }

      return [startDate_, endDate_];
    }
  );

  const changeDate = (fn: any, newDate: string, key: string) => {
    fn(newDate);
    sessionStorage.setItem(key, JSON.stringify(new Date(newDate)));
  };

  useEffect(() => {
    if (timeout) clearTimeout(timeout);

    timeout = () => {
      let st = new Date(startDate).toISOString().slice(0, -8);
      let ed = new Date(endDate).toISOString().slice(0, -8);
      setDebouncedDate([st, ed]);
    };

    timeout = setTimeout(timeout, 1000);
  }, [startDate, endDate]);

  const [isFetching, setIsFetching] = React.useState(false);

  const refreshBoard = () => {
    setIsFetching(true);

    Promise.all([
      RevenueRef?.current?.load(),
      DailyOrdersRef?.current?.load(),
      OpenSalesRef?.current?.load(),
      PaymentMethodsRef?.current?.load(),
      TaxCourierRef?.current?.load(),
      OrderByHoursRef?.current?.load(),
    ]).finally(() => {
      setIsFetching(false);
    });
  };

  useEffect(() => {
    refreshBoard();
  }, []);

  return (
    <Grid container columns={24} spacing={2}>
      <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Box
            display="flex"
            flexDirection={"column"}
            sx={{
              paddingX: { xs: 2 },
              paddingY: { xs: 2 },
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 400, mb: 0 }}>
              Data de referência
            </Typography>

            <Box display="flex" gap={2} alignItems="center">
              <TextField
                required
                margin="normal"
                type="datetime-local"
                size="small"
                value={startDate}
                onChange={(e) =>
                  changeDate(setStartDate, e.target.value, "dashboardStartDate")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              -
              <TextField
                required
                margin="normal"
                type="datetime-local"
                size="small"
                value={endDate}
                onChange={(e) =>
                  changeDate(setEndDate, e.target.value, "dashboardEndDate")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="outlined"
                style={{
                  marginTop: 6,
                  height: 40,
                }}
                disabled={isFetching}
                onClick={refreshBoard}
              >
                Atualizar
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={24} sm={24} md={24} lg={8} xl={8}>
        <Card>
          <Revenue dates={debouncedDate} ref={RevenueRef} />
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={8} xl={8}>
        <Card>
          <DailyOrders dates={debouncedDate} ref={DailyOrdersRef} />
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={8} xl={8}>
        <Card>
          <OpenSales ref={OpenSalesRef} />
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <PaymentMethods dates={debouncedDate} ref={PaymentMethodsRef} />
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <CourierTax dates={debouncedDate} ref={TaxCourierRef} />
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <OrderByHours dates={debouncedDate} ref={OrderByHoursRef} />
        </Card>
      </Grid>
    </Grid>
  );
};
