import {
  Box,
  TextField,
  Create,
  useAutocomplete,
  Autocomplete,
  Typography,
  FormControlLabel,
  Switch,
} from "@pankod/refine-mui";

import { Controller, useForm } from "@pankod/refine-react-hook-form";

import { NumericFormat } from "react-number-format";
import { useUploadImage } from "../../components/useUploadImage/useUploadImage";
import { useState, useEffect } from "react";

import PreviewProduct from "./PreviewProduct";

export const ProductCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    setError,
    getValues,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const { autocompleteProps } = useAutocomplete({
    resource: "categories",
    onSearch: (value) => [
      {
        field: "name",
        operator: "containss",
        value,
      },
    ],
  });

  const { autocompleteProps: autocompleteGroupsProps } = useAutocomplete({
    resource: "category-groups",
    metaData: {
      populate: ["category"],
    },
    onSearch: (value) => [
      {
        field: "name",
        operator: "containss",
        value,
      },
    ],
  });

  const watchCategories = watch("categories");

  useEffect(() => {
    //remove from groups if not in categories
    const groups = getValues("categoryGroups");
    const newGroups = groups.filter((group: any) => {
      return watchCategories.some((category: any) => {
        return category.id === group.category.id;
      });
    });
    setValue("categoryGroups", newGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCategories]);

  const {
    Component: UploadImage,
    Viewer: ImageViewer,
    handleUpload,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "image",
    context: "products",
    errors,
    size: [209, 247],
    onChangeEventName: "onProductImageChange",
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        disabled: saveButtonProps.disabled || isLoading,
        onClick: (e: any) => {
          if (!isValid) {
            saveButtonProps.onClick(e);
            return;
          }

          setIsLoading(true);
          handleUpload(getValues("name")).then(() => {
            saveButtonProps.onClick(e);
            setIsLoading(false);
          });
        },
      }}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Cadastrar Produto
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          error={!!errors?.name}
          //@ts-ignore
          helperText={errors.name?.message}
          margin="normal"
          required
          id="name"
          label="Nome"
          name="name"
          autoFocus
          sx={{ maxWidth: "300px" }}
          disabled={isLoading || formLoading}
        />

        <TextField
          {...register("referenceCode")}
          error={!!errors?.referenceCode}
          //@ts-ignore
          helperText={errors.referenceCode?.message}
          margin="normal"
          fullWidth
          id="referenceCode"
          label="Código de referência"
          name="referenceCode"
          disabled={isLoading || formLoading}
          sx={{ maxWidth: "200px" }}
        />

        <Controller
          control={control}
          name="price"
          rules={{ required: "Preço é obrigatório" }}
          defaultValue={null as any}
          render={({ field }) => (
            <NumericFormat
              {...field}
              customInput={TextField}
              disabled={isLoading || formLoading}
              label="Preço"
              prefix="R$ "
              sx={{ marginTop: 2, maxWidth: "150px" }}
              thousandSeparator={"."}
              decimalSeparator={","}
              decimalScale={2}
              type="text"
              required
              fixedDecimalScale
              error={!!errors.price}
              //@ts-ignore
              helperText={errors.price?.message}
              onValueChange={({ value }) => field.onChange(value)}
            />
          )}
        />

        <TextField
          {...register("description")}
          sx={{ marginTop: 3 }}
          error={!!errors?.description}
          //@ts-ignore
          helperText={errors.description?.message}
          margin="normal"
          fullWidth
          id="description"
          label="Descrição"
          disabled={isLoading || formLoading}
          name="description"
        />

        <Controller
          control={control}
          name="categories"
          rules={{ required: "Categoria é obrigatório" }}
          defaultValue={[] as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              disabled={isLoading || formLoading}
              multiple
              getOptionLabel={(item) => {
                return item.name ? item.name : "";
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined || option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categorias"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.categories}
                  //@ts-ignore
                  helperText={errors.categories?.message}
                  required
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="categoryGroups"
          defaultValue={[] as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteGroupsProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              disabled={isLoading || formLoading}
              multiple
              options={
                watchCategories?.length > 0 &&
                autocompleteGroupsProps?.options.length
                  ? autocompleteGroupsProps?.options.filter((group: any) => {
                      return watchCategories.some((category: any) => {
                        return category.id === group.category.id;
                      });
                    })
                  : []
              }
              getOptionLabel={(item) => {
                return item.name ? `${item.category.name}:${item.name}` : "";
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined || option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grupos"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />

        <TextField
          {...register("order", { required: "Ordem é obrigatória" })}
          error={!!errors?.order}
          //@ts-ignore
          helperText={errors.order?.message}
          margin="normal"
          type="number"
          required
          fullWidth
          id="order"
          label="Ordem"
          name="order"
          sx={{ maxWidth: "100px" }}
          defaultValue={"0"}
        />

        <Controller
          control={control}
          name="enabled"
          defaultValue={true as any}
          render={({ field }) => (
            <FormControlLabel
              sx={{ marginTop: 2, marginBottom: 4, width: "120px" }}
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                />
              }
              label="Habilitado"
              disabled={isLoading || formLoading}
            />
          )}
        />

        <UploadImage disabled={isLoading || formLoading} />
        <ImageViewer disabled={isLoading || formLoading} />

        <PreviewProduct watchFn={watch} />
      </Box>
    </Create>
  );
};
