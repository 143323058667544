import { Box, MenuItem, Select, Typography } from "@pankod/refine-mui";
import { DataGridContext } from "components/DataGrid/context";
import React from "react";

type DataGridFilterSortProps = {
  fields: { name: string; label: string }[];
};

const DataGridFilterSort = ({ fields }: DataGridFilterSortProps) => {
  const { state } = React.useContext(DataGridContext);

  const [sort, setSort] = React.useState(state.sort);

  return (
    <>
      <Typography color="text.secondary" gutterBottom>
        Ordenação
      </Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Select
          name="sortField"
          size="small"
          sx={{ flex: 1 }}
          value={sort.field}
          onChange={(e) => setSort({ ...sort, field: e.target.value })}
        >
          {fields.map(({ name, label }) => (
            <MenuItem value={name}>{label}</MenuItem>
          ))}
        </Select>
        <Select
          name="sortOrder"
          size="small"
          value={sort.order}
          sx={{ flex: 1 }}
          onChange={(e) => setSort({ ...sort, order: e.target.value })}
        >
          <MenuItem value="asc">Ascendente</MenuItem>
          <MenuItem value="desc">Descendente</MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default DataGridFilterSort;
