import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Box, CircularProgress, Stack, Typography } from "@pankod/refine-mui";

import { forwardRef, useEffect, useImperativeHandle } from "react";
import { ChartProps, ChartRefProps } from "../../pages/dashboard";
import { axiosInstance } from "../../authProvider";

export const CourierTax = forwardRef<ChartRefProps, ChartProps>(
  ({ dates }, ref) => {
    const API_URL = useApiUrl();
    const url = `${API_URL}/dashboard/couriersTax/${dates[0]}/${dates[1]}`;

    const { data, isFetching, refetch } = useCustom({
      queryOptions: {
        retry: false,
        cacheTime: 0,
        enabled: false,
        networkMode: "always",
      },
      errorNotification: false,
      url,
      method: "get",
    });

    useImperativeHandle(ref, () => ({
      load: refetch,
    }));

    useEffect(() => {
      refetch();

      const handleRefresh = (event: any) => {
        if (
          event.detail.url.indexOf("couriersTax") > -1 &&
          url.indexOf("couriersTax") > -1
        ) {
          axiosInstance.defaults.headers.common["Authorization"] =
            event.detail.authorization;
          refetch();
        }
      };
      window.addEventListener("token-refreshed", handleRefresh);
      return () => {
        window.removeEventListener("token-refreshed", handleRefresh);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Stack
        justifyContent="space-between"
        sx={{
          p: 1,
          backgroundColor: "#2a384b9e",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontWeight: 700, mb: 0 }}
          >
            Taxa de entrega por entregador
          </Typography>
        </Stack>

        {isFetching ? (
          <Box display={"flex"} justifyContent="center">
            <CircularProgress sx={{ color: "#fff" }} />
          </Box>
        ) : (
          data?.data?.map((courier: any) => (
            <Stack
              key={courier.name}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mx: 2, py: 0.5, color: "#fff" }}
              borderBottom="1px solid"
            >
              <Typography variant="caption" sx={{ mb: 0 }}>
                {courier.name}
              </Typography>

              <Typography variant="caption" sx={{ mb: 0 }}>
                {courier.count} - {courier.total}
              </Typography>
            </Stack>
          ))
        )}
      </Stack>
    );
  }
);
