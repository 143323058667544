import { Box } from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const MessagesSection: React.FC<SectionProps> = ({
  register,
  errors,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <InputTextField
        name="closedMessageStore"
        label="Mensagem de loja fechada ao acessar o site"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="checkoutPageMessage"
        label="Mensagem na página de checkout"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="closeMessageOnCheckout"
        label="Mensagem de loja fechada no momento do checkout"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="closeMessageButtonLabel"
        label="Label no botao de checkout de Loja fechada"
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />
    </Box>
  );
};
