import React from "react";
import {
  Menu,
  MenuItem,
  Divider,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@pankod/refine-mui";

import { useState } from "react";
import { boolToString } from "../../boolToString";
import { useBatchCsv } from "../../components/useBatchCsv/useBatchCsv";
import { useApiUrl, useList, useNotification } from "@pankod/refine-core";
import { DataGridContext } from "components/DataGrid/context";

export default function BatchOperation() {
  const { refetch, state } = React.useContext(DataGridContext);

  const apiUrl = useApiUrl();

  const { open: openNotification } = useNotification();

  const {
    Component: UploadFile,
    isUploading,
    dataToConfirm,
    cancelUpload,
    confirmUpload,
  } = useBatchCsv({
    uploadUrl: "/product/batch",
  });

  const { data: categories } = useList({
    resource: "categories",
    config: {
      hasPagination: false,
    },
  });

  const downloadCsvCreate = () => {
    const rows = [
      ["create"],
      [
        '\nInstruções\n"Não alterar, remover e adicionar as linhas anteriores (inclusive) ao cabeçalho"',
      ],
      [
        '\nTransformação de texto no excell\n"" Se seus produtos estão todos em maiúsculo e quer transformar o texto você pode utilizar a fómrula abaixo',
        "\nEm Portugues: =MAIÚSCULA(ESQUERDA(A1;1))&MINÚSCULA(DIREITA(A1;NÚM.CARACT(A1)-1))",
        "\nEm Inglês: =UPPER(LEFT(A1,1))&LOWER(RIGHT(A1,LEN(A1)-1))",
      ],
      ["\nCategorias"],
      ...(categories?.data || []).map((category) => [category.name]),
      [
        '\n\nAs categorias devem ser escritas exatamente como estão acima\n"Mais de uma categoria deve ser separada por vírgula, Ex: Categoria 1, Categoria 2"',
      ],
      ['\n"Preço deve conter 2 casas decimais separados por ,"\n\n\n'],
      ["Nome", "ref. Code", "Preço", "Habilitado", "Categorias"],
      ["Produto Exemplo 1", "PRE01", '"10,99"', "sim", "Categoria 1"],
    ];

    let csvContent = rows.map((e) => e.join(";")).join("\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
    );
    link.setAttribute("download", "criar_produtos.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleClose();
  };

  const downloadCsvUpdate = async () => {
    const rows = [
      ["update"],
      [
        '\nInstruções\n"Não alterar, remover e adicionar as linhas anteriores (inclusive) ao cabeçalho"',
      ],
      ["\nCategorias"],
      ...(categories?.data || []).map((category) => [category.name]),
      [
        '\n\nAs categorias devem ser escritas exatamente como estão acima\n"Mais de uma categoria deve ser separada por vírgula, Ex: Categoria 1, Categoria 2"',
      ],
      ['\n"Preço deve conter 2 casas decimais separados por ,"\n\n\n'],
      ["Id", "Nome", "ref. Code", "Preço", "Habilitado", "Categorias"],
    ];

    const params = new URLSearchParams();
    params.append("populate[0]", "categories");

    const { field, order } = state.sort || {};
    params.append("sort", `${field}:${order}`);

    if (state.filters?.name) {
      params.append("filters[name][$containsi]", state.filters?.name);
    }

    if (state.filters?.category) {
      params.append("filters[categories][$in][0]", state.filters.category);
    }

    if (state.filters?.enabled) {
      params.append("filters[enabled][$eq]", state.filters.enabled);
    }

    params.append("export", "true");

    setIsExporting(true);
    const res = await fetch(`${apiUrl}/products?${params.toString()}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("strapi-jwt-token")}`,
      },
    });

    const data = await res.json();

    setIsExporting(false);

    data.forEach((row: any) => {
      const p = row.formattedPrice.split(" ");
      const refCode = row.referenceCode === null ? "" : row.referenceCode;
      rows.push([
        '"' + row.id.toString() + '"',
        '"' + row.name.toString() + '"',
        '"' + refCode.toString() + '"',
        '"' + p[1].toString() + '"',
        '"' + boolToString(row.enabled) + '"',
        '"' + row.categories + '"',
      ]);
    });

    let csvContent = rows.map((e) => e.join(";")).join("\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
    );
    link.setAttribute("download", "editar_produtos.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleClose();
  };

  const [isExporting, setIsExporting] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button id="basic-button" onClick={handleClick} variant="outlined">
        <CircularProgress
          size={16}
          sx={{
            marginRight: "5px",
            display: isUploading ? "block" : "none",
          }}
        />
        Operação em lote
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={isExporting ? () => {} : handleClose}
      >
        <Typography
          sx={{
            padding: 1,
            fontSize: 11,
            fontWeight: "bold",
            color: "GrayText",
          }}
        >
          Arquivo csv (separador ";")
        </Typography>
        <MenuItem onClick={downloadCsvCreate} disabled={isExporting}>
          Template para cadastro
        </MenuItem>
        <MenuItem onClick={downloadCsvUpdate} disabled={isExporting}>
          <CircularProgress
            size={16}
            sx={{
              marginRight: "5px",
              display: isExporting ? "block" : "none",
            }}
          />
          Template para edição
        </MenuItem>

        <Divider />

        {!isExporting ? (
          <UploadFile
            onError={(response: any) => {
              openNotification?.({
                message: response.error.details.message,
                type: "error",
              });
            }}
            onSelectedFile={() => {
              handleClose();
            }}
          >
            <MenuItem>Carregar arquivo</MenuItem>
          </UploadFile>
        ) : (
          <MenuItem disabled={isExporting}>Carregar arquivo</MenuItem>
        )}
      </Menu>

      <DialogConfirmBatch
        onClose={cancelUpload}
        data={dataToConfirm}
        onConfirm={confirmUpload}
        refetch={refetch}
      />
    </>
  );
}

function DialogConfirmBatch({ data, onClose, onConfirm, refetch }: any) {
  const [isImporting, setIsImporting] = React.useState(false);

  const products = data?.data;
  const hasInconsistences = data?.hasInconsistences;
  const inconsistences = data?.inconsistences;

  return (
    <Dialog open={data !== null} fullWidth maxWidth="md">
      <DialogTitle>Verifique os produtos que serão importados</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Nome & categorias</TableCell>
                <TableCell>ref. Code</TableCell>
                <TableCell>Preço</TableCell>
                <TableCell>Habilitado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(products || []).map((row: any, index: any) => (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& td,& th": {
                        borderWidth: inconsistences[index].length > 0 ? 0 : 1,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Box display={"flex"} flexDirection="column">
                        <Typography variant="body2">{row.name}</Typography>
                        <Typography variant="caption" color={"GrayText"}>
                          {row.categories && row.categories.length > 0
                            ? row.categories
                                .map((category: any) => category.name)
                                .join(", ")
                            : "-"}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{row.referenceCode}</TableCell>
                    <TableCell>{row.formattedPrice}</TableCell>
                    <TableCell>{boolToString(row.enabled)}</TableCell>
                  </TableRow>
                  {inconsistences[index].length > 0 && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "1px",
                            color: "error.main",
                          }}
                        >
                          <ul>
                            {inconsistences[index].map(
                              (inconsistency: any, index_: any) => (
                                <li key={index_}>{inconsistency}</li>
                              )
                            )}
                          </ul>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isImporting}>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            setIsImporting(true);
            onConfirm()
              .then(() => {
                setIsImporting(false);
                onClose();
                refetch();
              })
              .finally(() => {
                setIsImporting(false);
              });
          }}
          autoFocus
          color="error"
          variant="contained"
          disabled={isImporting || hasInconsistences}
        >
          <CircularProgress
            size={16}
            sx={{
              color: "white",
              marginRight: "5px",
              display: isImporting ? "block" : "none",
            }}
          />
          Importar produtos
        </Button>
      </DialogActions>
    </Dialog>
  );
}
