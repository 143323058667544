import React from "react";
import { Link } from "@pankod/refine-mui";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@pankod/refine-mui";

import { TableBody } from "@pankod/refine-mui";
import { useNavigation } from "@pankod/refine-core";

export default function Products(props: any) {
  const navigation = useNavigation();

  return (
    <TableContainer sx={{ marginBottom: 8 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Produto</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell>Preço</TableCell>
            <TableCell>Subtotal</TableCell>
            <TableCell>Promoção</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(props.items || []).map((row: any) => (
            <TableRow
              key={row.id || row.product.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.product.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.quantity}
              </TableCell>
              <TableCell>
                <Typography
                  color={"GrayText"}
                  style={{ textDecoration: "line-through" }}
                >
                  {row.originalPriceFormatted}
                </Typography>
                <Typography>{row.priceFormatted}</Typography>
              </TableCell>

              <TableCell scope="row">
                <Typography
                  color={"GrayText"}
                  style={{ textDecoration: "line-through" }}
                >
                  {row.originalTotalPriceFormatted}
                </Typography>
                <Typography>{row.totalPriceFormatted}</Typography>
              </TableCell>

              <TableCell>
                {(row.sale && (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigation.push(`/sales/edit/${row?.sale?.id}`);
                    }}
                    href="#"
                  >
                    {row?.sale?.name}
                  </Link>
                )) ||
                  "Nenhuma promoção"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
