import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import { CategoriesList } from "./list";
import { CategoryCreate } from "./create";
import { CategoryEdit } from "./edit";

const CategoriesConfig = {
  name: "categories",
  list: CategoriesList,
  create: CategoryCreate,
  edit: CategoryEdit,
  options: {
    label: "Categorias",
  },
  icon: <CategoryOutlinedIcon />,
};

export { CategoriesConfig };
