import React from "react";
import { Typography, Box } from "@pankod/refine-mui";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { dataGridColumnFactory } from "../../components/DataGrid/components/Column";

const columns = [
  dataGridColumnFactory({
    accessorKey: "mosaic",
    header: "Mosaic",
    Cell: () => (
      <Box display={"flex"} flexDirection="column">
        <Typography variant="body2">Mosaico</Typography>
      </Box>
    ),
  }),
  dataGridColumnFactory({
    accessorKey: "enabled",
    header: "Habilitado",
    isBoolean: true,
  }),
];

export const MosaicList: React.FC = () => {
  return (
    <DataGridTable<{
      enabled: string;
    }>
      id="carousel-list"
      initialSort={{
        field: "id",
        order: "asc",
      }}
      endpoint="mosaics"
      header="Mosaico"
      columns={columns}
      initialState={{
        enabled: "",
      }}
      headerActions={<></>}
    />
  );
};
