export const filterReducer = (state: any, action: any) => {
  let newState: typeof state;

  switch (action.type) {
    case "reset":
      newState = {
        ...state,
        pagination: { pageSize: 10, pageIndex: 0 },
        filters: {
          name: "",
        },
        sort: {
          field: "name",
          order: "asc",
        },
      };

      return newState;

    case "update_pagination":
      newState = {
        ...state,
        pagination: { ...state.pagination, ...action.payload },
      };

      return newState;
    case "update_filters":
      newState = {
        ...state,
        filters: action.payload,
      };

      return newState;
    default:
      return state;
  }
};
