import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Stack,
  EditButton,
  ShowButton,
  DeleteButton,
  Typography,
  Box,
} from "@pankod/refine-mui";
import { boolToString } from "../../boolToString";

export const CategoryGroupsList: React.FC = () => {
  const { dataGridProps } = useDataGrid({
    //@ts-ignore
    initialCurrent: 1,
    initialPageSize: 10,
    initialSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
    initialFilter: [],
    syncWithLocation: true,
    metaData: {
      populate: ["category"],
    },
  });

  const columns = React.useMemo<GridColumns>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 50,
        sortable: false,
        filterable: false,
        hideable: false,
      },
      {
        field: "name",
        headerName: "Nome",
        minWidth: 400,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Box display={"flex"} flexDirection="column">
              <Typography variant="body2">{params.row.name}</Typography>
              <Typography variant="caption" color={"GrayText"}>
                {params.row.category.name}
              </Typography>
            </Box>
          );
        },
      },

      {
        field: "enabled",
        headerName: "Habilitada",
        flex: 1,
        sortable: false,
        renderCell: function render(params) {
          return <span>{boolToString(params.row.enabled)}</span>;
        },
      },
      {
        field: "order",
        headerName: "Ordem",
        flex: 1,
        sortable: false,
      },
      {
        headerName: "",
        headerAlign: "center",
        field: "actions",
        width: 140,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              <ShowButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
                errorNotification={(error: any) => {
                  return {
                    message:
                      error.response.data.error.details.message ||
                      "Erro ao deletar",
                    type: "error",
                  };
                }}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
      />
    </List>
  );
};
