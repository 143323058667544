import React, { useState, useRef, useEffect } from "react";

import { Stack, Typography, Box, Button, Slider } from "@pankod/refine-mui";

import { useApiUrl } from "@pankod/refine-core";

import { LoadingButton } from "@mui/lab";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReactCrop, {
  Crop,
  centerCrop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

interface IUseUploadImageProps {
  watchFn: any;
  setValueFn: any;
  setErrorFn: any;
  registerFn: any;
  field: any;
  errors: any;
  onChangeEventName?: string;
  context: string;
  size?: [number, number];
  accept?: string;
  suggestedDimensions?: boolean;
}

const Component = (
  props: IUseUploadImageProps & { onPreview: any; disabled?: boolean }
) => {
  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //@ts-ignore
    const file = event.target.files[0];

    //return base64 image to show in preview
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      props.onPreview(reader.result, file);
    };
  };

  const renderProportions = (size?: [number, number]) => {
    if (size) {
      const nextSizes = [];
      //calculate proportional sizes 2 ahead
      for (let i = 1; i <= 2; i++) {
        nextSizes.push([
          Math.round(size[0] * (1 + i * 0.2)),
          Math.round(size[1] * (1 + i * 0.2)),
        ]);
      }

      return (
        <Typography variant="caption">
          {props.suggestedDimensions !== false ? (
            <>
              Dimensões sugeridas: {size[0]}x{size[1]}
              {nextSizes.map((size) => (
                <span key={size[0]}>
                  , {size[0]}x{size[1]}
                </span>
              ))}{" "}
              ...
            </>
          ) : (
            <>
              Dimensão: {size[0]}x{size[1]}
            </>
          )}
        </Typography>
      );
    }
  };

  return (
    <Stack direction="row" gap={4} flexWrap="wrap">
      <label htmlFor={props.field}>
        <input
          id={props.field}
          type="file"
          style={{ display: "none" }}
          accept={props.accept || "image/png, image/jpeg, image/webp"}
          onChange={onChangeHandler}
          disabled={props.disabled}
        />
        <input id="file" {...props.registerFn(props.field)} type="hidden" />
        <LoadingButton
          disabled={props.disabled}
          loadingPosition="end"
          endIcon={<FileUploadIcon />}
          variant="contained"
          component="span"
        >
          Carregar imagem
        </LoadingButton>
        <br />
        {renderProportions(props.size)}
        <br />
        {props.errors.logo && (
          <Typography variant="caption" color="#fa541c">
            {/* @ts-ignore */}
            {props.errors.logo?.message}
          </Typography>
        )}
      </label>
    </Stack>
  );
};

const Viewer = ({ image, removable, onRemove, disabled }: any) => {
  if (!image) return null;

  const canRemove = removable && image?.id;

  return (
    <Box display="flex" flexDirection={"column"} alignItems="center">
      <Box
        component="img"
        sx={{
          marginTop: 4,
          maxWidth: "200px",
        }}
        src={image.url}
        width={image.width}
        alt="Post logo"
      />
      {canRemove && (
        <Button
          sx={{ marginTop: 2, width: 200 }}
          disabled={disabled}
          onClick={onRemove}
        >
          Remover imagem
        </Button>
      )}
    </Box>
  );
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  height: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        height,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps?: any
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

const Preview = ({ base64, onImageChange, size }: any) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect] = useState<number | undefined>(size[0] / size[1]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        if (previewCanvasRef.current) {
          previewCanvasRef.current.toBlob((blob) => {
            onImageChange(previewCanvasRef.current?.toDataURL(), blob);
          });
        }
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  if (!base64) return null;

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect, size[1]));
    }
  }

  return (
    <>
      <Box display={"flex"} gap={4}>
        <Box display={"flex"} flexDirection="column">
          <Typography
            variant="caption"
            style={{
              transform: "translateY(10px)",
            }}
          >
            Tamanho
          </Typography>
          <Slider
            value={scale}
            step={0.01}
            min={0.1}
            max={1}
            sx={{ width: 200 }}
            valueLabelDisplay="auto"
            onChange={(e, value) => setScale(Number(value))}
          />
        </Box>
        <Box display={"flex"} flexDirection="column">
          <Typography
            variant="caption"
            style={{
              transform: "translateY(10px)",
            }}
          >
            Rotação
          </Typography>
          <Slider
            value={rotate}
            step={1}
            min={-180}
            max={180}
            sx={{ width: 200 }}
            valueLabelDisplay="auto"
            onChange={(e, value) => {
              setRotate(Math.min(180, Math.max(-180, Number(value))));
            }}
          />
        </Box>
      </Box>
      <ReactCrop
        crop={crop}
        locked
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        aspect={aspect}
        style={{ marginTop: 15 }}
        onComplete={(c) => {
          setCompletedCrop(c);
        }}
      >
        <img
          ref={imgRef}
          alt=""
          src={base64}
          style={{
            transform: `scale(${scale}) rotate(${rotate}deg)`,
          }}
          onLoad={onImageLoad}
        />
      </ReactCrop>

      <div style={{ display: "none" }}>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
    </>

    // <>
    //   <Box
    //     component="img"
    //     sx={{
    //       marginTop: 4,
    //       maxWidth: 250,
    //       maxHeight: 250,
    //       objectFit: "contain",
    //     }}
    //     src={base64}
    //     alt="Post logo"
    //   />
    // </>
  );
};

export const useUploadImage = (props: IUseUploadImageProps) => {
  const image_ = props.watchFn(props.field);
  const oringialImage = image_?.id ? image_?.id : null;
  const apiUrl = useApiUrl();

  const [previewImage, setPreviewImage] = useState();
  const [image, setImage] = useState(image_);
  const [imageToDelete, setImageToDelete] = useState();

  const refImageBlob = useRef();

  const onPreview = (base64: any, file: any) => {
    setPreviewImage(base64);
    if (oringialImage) {
      setImageToDelete(oringialImage);
    }
    setImage(file);
  };

  const handleUpload = async (name_?: string) => {
    if (!image && !imageToDelete) {
      if (!oringialImage) {
        props.setValueFn(props.field, null, { shouldValidate: true });
      }
      return;
    }

    try {
      const formData = new FormData();
      let file: File = image;

      if (image) {
        const blob = refImageBlob.current!;
        file = new File([blob], name_ || image.name, { type: image.type });
        formData.append("files", file);
        formData.append("context", props.context);
      }

      if (imageToDelete) {
        formData.append("imageToDelete", imageToDelete);
        formData.append("context", props.context);
      }

      const res = await fetch(`${apiUrl}/upload-image`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("strapi-jwt-token")}`,
        },
      });

      const data = await res.json();

      const { name, size, type, lastModified } = file;

      const imagePaylod = {
        id: data[0].id,
        name: name,
        size,
        type,
        lastModified,
        url: data[0].url,
      };
      props.setValueFn(props.field, imagePaylod, { shouldValidate: true });
    } catch (error) {
      props.setErrorFn(props.field, {
        message: "Upload failed. Please try again.",
      });
    }
  };

  const removeImage = () => {
    setImageToDelete(image_.id);
    props.setValueFn(props.field, null, { shouldValidate: true });
  };

  const onPreviewImageChange = (base64: any, blob: any) => {
    refImageBlob.current = blob;

    if (props.onChangeEventName) {
      const event = new CustomEvent(props.onChangeEventName, {
        detail: base64,
      });
      window.dispatchEvent(event);
    }
  };

  return {
    Component: ({ disabled }: any) => (
      <Component {...props} disabled={disabled} onPreview={onPreview} />
    ),
    Viewer: ({ image, disabled }: any) => {
      if (previewImage) {
        return (
          <Preview
            size={props.size}
            base64={previewImage}
            onImageChange={onPreviewImageChange}
          />
        );
      }
      return (
        <Viewer
          image={image || image_}
          removable
          disabled={disabled}
          onRemove={removeImage}
        />
      );
    },
    handleUpload,
  };
};

export const useViewImage = ({ image }: any) => {
  return {
    Viewer: ({ noLabel = false }: any) => {
      if (!image) return null;

      return (
        <>
          {noLabel ? null : (
            <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 4 }}>
              Imagem
            </Typography>
          )}

          <Typography variant="body2">
            <Viewer image={image} />
          </Typography>
        </>
      );
    },
  };
};

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}
