import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Box, Stack, Typography } from "@pankod/refine-mui";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { getRandomColor } from "../../utils";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { ChartProps, ChartRefProps } from "../../pages/dashboard";
import { CircularProgress } from "@pankod/refine-mui";
import { axiosInstance } from "../../authProvider";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PaymentMethods = forwardRef<ChartRefProps, ChartProps>(
  ({ dates }, ref) => {
    const API_URL = useApiUrl();
    const url = `${API_URL}/dashboard/paymentMethodsByPeriod/${dates[0]}/${dates[1]}`;

    const { data, isFetching, refetch } = useCustom<{
      ordersByPaymentMethod: Record<any, any>[];
    }>({
      queryOptions: {
        retry: false,
        cacheTime: 0,
        enabled: false,
        networkMode: "always",
      },
      errorNotification: false,
      url,
      method: "get",
    });

    useImperativeHandle(ref, () => ({
      load: refetch,
    }));

    useEffect(() => {
      refetch();

      const handleRefresh = (event: any) => {
        if (
          event.detail.url.indexOf("paymentMethodsByPeriod") > -1 &&
          url.indexOf("paymentMethodsByPeriod") > -1
        ) {
          axiosInstance.defaults.headers.common["Authorization"] =
            event.detail.authorization;
          refetch();
        }
      };
      window.addEventListener("token-refreshed", handleRefresh);
      return () => {
        window.removeEventListener("token-refreshed", handleRefresh);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const labels = data?.data?.ordersByPaymentMethod?.map(
      (order) => `${order.name} ${order.pct}%`
    );
    const values = data?.data?.ordersByPaymentMethod?.map(
      (order) => order.count
    );
    const backgroundColor = labels?.map(() => getRandomColor());

    return (
      <Stack
        justifyContent="space-between"
        sx={{
          minHeight: "400px",
          p: 1,
          backgroundColor: "#5f4f7b",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#fff", fontWeight: 700, mb: 0 }}
          >
            Formas de pagamento
          </Typography>
        </Stack>
        <Box sx={{ minHeight: "300px" }}>
          {isFetching ? (
            <Box display={"flex"} justifyContent="center">
              <CircularProgress sx={{ color: "#fff" }} />
            </Box>
          ) : (
            <Pie
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: "bottom",
                    labels: {
                      color: "#fff",
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context: any) {
                        const value = values?.[context.dataIndex];
                        return ` ${value} pedidos`;
                      },
                    },
                  },
                },
              }}
              data={{
                labels,
                datasets: [
                  {
                    data: values,
                    backgroundColor: backgroundColor,
                  },
                ],
              }}
            />
          )}
        </Box>
      </Stack>
    );
  }
);
