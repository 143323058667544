import React from "react";
import { Stack, Typography, Box, CreateButton } from "@pankod/refine-mui";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { dataGridColumnFactory } from "../../components/DataGrid/components/Column";
import RefreshButton from "components/DataGrid/components/RefreshButton";
import DataGridFilterText from "components/DataGrid/components/filter/DataGridFilterText";
import DataGridFilterSort from "components/DataGrid/components/filter/DataGridFilterSort";
import DataGridFilterEnabled from "components/DataGrid/components/filter/DataGridFilterEnabled";

const columns = [
  dataGridColumnFactory({
    accessorKey: "name",
    header: "Nome",
    Cell: ({ attributes }) => (
      <Box display={"flex"} flexDirection="column">
        <Typography variant="body2">{attributes.name}</Typography>
        <Typography variant="caption" color={"GrayText"}>
          {attributes.slug}
        </Typography>
      </Box>
    ),
  }),
  dataGridColumnFactory({
    accessorKey: "startDate",
    header: "Periodo",
    Cell: ({ attributes }) => {
      let _startDate = new Date(attributes.startDate);
      let startDate = _startDate.toLocaleDateString();
      let startTime = _startDate.toLocaleTimeString();
      startTime = startTime.slice(0, -3);

      let _endDate = new Date(attributes.endDate);
      let endDate = _endDate.toLocaleDateString();
      let endTime = _endDate.toLocaleTimeString();
      endTime = endTime.slice(0, -3);

      //calculate if the sale is active
      let now = new Date();
      let isActive = now >= _startDate && now <= _endDate;

      return (
        <Stack direction="column" spacing={0}>
          <Typography variant="caption">
            {isActive ? "Ativa" : "Inativa"}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption">{startDate}</Typography>
            <Typography variant="caption">{startTime}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="caption">{endDate}</Typography>
            <Typography variant="caption">{endTime}</Typography>
          </Stack>
        </Stack>
      );
    },
  }),
  dataGridColumnFactory({
    accessorKey: "enabled",
    header: "Habilitado",
    isBoolean: true,
  }),
];

export const SalesList: React.FC = () => {
  return (
    <DataGridTable<{
      name: string;
      enabled: string;
    }>
      id="sales-list"
      initialSort={{
        field: "createdAt",
        order: "desc",
      }}
      endpoint="sales"
      header="Promoções"
      columns={columns}
      initialState={{
        name: "",
        enabled: "",
      }}
      headerActions={
        <>
          <RefreshButton />
          <CreateButton />
        </>
      }
      filterComponent={
        <>
          <DataGridFilterText field="name" />
          <DataGridFilterEnabled />
          <DataGridFilterSort
            fields={[
              {
                name: "createdAt",
                label: "Criado em",
              },
              {
                name: "name",
                label: "Nome",
              },
              {
                name: "enabled",
                label: "Habilitado",
              },
            ]}
          />
        </>
      }
      queryPipeline={(f) => {
        const query: Record<string, string> = {};
        if (f.name) {
          query["filters[name][$containsi]"] = f.name;
        }

        if (f.enabled) {
          query["filters[enabled][$eq]"] = f.enabled;
        }
        return query;
      }}
    />
  );
};
