import ReactECharts from "echarts-for-react";

import { FC } from "react";
import { Typography } from "@pankod/refine-mui";

type HeatMapOrdersProps = {
  orders: {
    hour: {
      [hour: string]: {
        weekday: {
          [weekday: string]: number;
        };
        quantity: number;
      };
    };
    weekday: {
      [weekday: string]: {
        quantity: number;
      };
    };
  };
};

const orderWeekdays = ["mo", "tu", "we", "th", "fr", "sa", "su"];
const orderWeekdaysReverse = [...orderWeekdays].reverse();
const hoursOrder = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const HeatMapOrders: FC<HeatMapOrdersProps> = ({ orders }) => {
  if (!orders || !orders.hour) {
    return null;
  }

  const hours = Object.keys(orders.hour).sort((a, b) => +a - +b);

  const hoursOrderNoEmpty = hoursOrder.filter((hour) => hours.includes(hour));

  let days = Object.keys(orders.weekday);
  //order days of the week in the order
  days = days.sort((a, b) => {
    return orderWeekdays.indexOf(b) - orderWeekdays.indexOf(a);
  });

  days = days.map((day) => {
    switch (day) {
      case "su":
        return "Domingo";
      case "mo":
        return "Segunda";
      case "tu":
        return "Terça";
      case "we":
        return "Quarta";
      case "th":
        return "Quinta";
      case "fr":
        return "Sexta";
      case "sa":
        return "Sábado";
      default:
        return "-";
    }
  });

  const totalOrders = Object.keys(orders.hour).reduce((acc, hour) => {
    return acc + orders.hour[hour].quantity;
  }, 0);

  let heighstValue = 0;

  const data = Object.keys(orders.hour).reduce(
    (acc: (string | number)[][], hour) => {
      if (orders.hour[hour].weekday) {
        Object.keys(orders.hour[hour].weekday).forEach((weekday) => {
          let pct = Number(
            ((orders.hour[hour].weekday[weekday] * 100) / totalOrders).toFixed(
              2
            )
          );

          if (pct > heighstValue) {
            heighstValue = pct;
          }

          acc.push([
            hoursOrderNoEmpty.indexOf(hour),
            orderWeekdaysReverse.indexOf(weekday),
            pct,
          ]);
        });
      }

      return acc;
    },
    []
  );

  const option = {
    tooltip: {
      position: "top",
    },
    grid: {
      left: "2%",
      right: "2%",
      top: "0%",
      bottom: "30%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: hours,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      data: days,
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: heighstValue,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15%",
    },
    series: [
      {
        name: "Punch Card",
        type: "heatmap",
        data: data,
        label: {
          show: true,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 2,
            shadowColor: "rgba(0, 0, 0, 0.3)",
          },
        },
      },
    ],
  };

  return (
    <>
      <Typography variant="h6" sx={{ px: 2, py: 2 }}>
        Distribuição de Pedidos (Hora x Dia da Semana)
      </Typography>
      <ReactECharts style={{ height: "100%" }} option={option} />
    </>
  );
};
