import { Authenticated, useApiUrl, useCustom } from "@pankod/refine-core";

import React, { useState } from "react";
import { Box, Button, Card, Grid, Typography } from "@pankod/refine-mui";
import { ProductsOverall } from "pages/analytics/products/ProductsOverall";
import { ProductsOrderPeriod } from "pages/analytics/products/ProductsOrderPeriod";
import { localDateTimeStr } from "localDateTime";
import { CircularProgress } from "@pankod/refine-mui";
import { HeatMapOrders } from "pages/analytics/products/HeatMapOrders";
import { SoldPerPeriod } from "pages/analytics/products/SoldPerPeriod";

export const Analytics: React.FC = () => {
  const API_URL = useApiUrl();
  const url = `${API_URL}/analytics/update-data`;

  //retirive a method to call the api
  const { refetch: refreshData, isFetching: isUpdating } = useCustom({
    url,
    method: "post",
    queryOptions: {
      enabled: false,
      retry: false,
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });

  const {
    data,
    refetch: loadData,
    isFetching: isLoading,
  } = useCustom<any>({
    url: `${API_URL}/analytics`,
    method: "get",
    queryOptions: {
      retry: false,
    },
  });

  const products = data?.data.data[0].attributes.products;
  const orders = data?.data.data[0].attributes.orders;
  const lastUpdate = data?.data.data[0].attributes.updatedAt;

  const productState = useState<string[]>([]);

  return (
    <Authenticated>
      <Grid container columns={24} spacing={2} p={2}>
        <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <Box
              display="flex"
              justifyContent={{ xs: "space-between" }}
              alignItems={{ xs: "center" }}
              sx={{
                paddingX: { xs: 2 },
                paddingY: { xs: 2 },
              }}
            >
              <Typography
                variant="caption"
                sx={{ fontWeight: 400, mb: 0, ml: 2 }}
              >
                Última atualização:{" "}
                {lastUpdate ? localDateTimeStr(lastUpdate) : "-"}
              </Typography>

              <Button
                variant="contained"
                onClick={() =>
                  refreshData().then(() => {
                    loadData();
                  })
                }
                color="primary"
                disabled={isLoading || isUpdating}
              >
                {(isLoading || isUpdating) && (
                  <CircularProgress
                    size={20}
                    sx={{ marginRight: 1 }}
                    color="inherit"
                  />
                )}
                Atualizar
              </Button>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={24}>
          <Card sx={{ height: 500 }}>
            {isLoading ? <></> : <HeatMapOrders orders={orders || {}} />}
          </Card>
        </Grid>

        <Grid item xs={24}>
          <Card sx={{ height: "auto" }}>
            {isLoading ? <></> : <SoldPerPeriod products={products} />}
          </Card>
        </Grid>

        <Grid item xs={24} sm={24} md={24} lg={8} xl={8}>
          <Card>
            {isLoading ? (
              <></>
            ) : (
              <ProductsOverall
                stateHandler={productState}
                products={products?.overall || {}}
              />
            )}
          </Card>
        </Grid>

        <Grid item xs={24} sm={24} md={24} lg={16} xl={16}>
          <Card sx={{ height: 570 }}>
            {isLoading ? (
              <></>
            ) : (
              <ProductsOrderPeriod
                stateHandler={productState}
                products={products}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </Authenticated>
  );
};
