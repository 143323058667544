import { Box, IconButton, TextField, useTheme } from "@pankod/refine-mui";
import React, { useEffect } from "react";

import { MaterialReactTable as DataGrid } from "material-react-table";
import { useApiUrl, useCustom } from "@pankod/refine-core";
import { filterReducer } from "./reducer";
import { columns } from "./columns";
import { CloseRounded } from "@mui/icons-material";

const DialogSelectProductTable: React.FC<{
  onSelectItem: any;
  isItemSelected: any;
  disabledItems?: any[];
}> = ({ onSelectItem, isItemSelected, disabledItems }) => {
  const theme = useTheme();
  const API_URL = useApiUrl();
  const url = `${API_URL}/products`;

  const [filters, dispatch] = React.useReducer(filterReducer, {
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    filters: {
      name: "",
    },
    sort: {
      field: "name",
      order: "asc",
    },
  });

  const { data, isRefetching, isLoading } = useCustom<any>({
    url,
    config: {
      query: {
        "pagination[page]": parseInt(filters.pagination.pageIndex) + 1,
        "pagination[pageSize]": parseInt(filters.pagination.pageSize),
        sort: `${filters.sort.field}:${filters.sort.order}`,
        "filters[name][$containsi]": filters.filters.name,
        "filters[enabled][$eq]": "true",
      },
    },
    queryOptions: {
      retry: false,
      cacheTime: 0,
      enabled: true,
      networkMode: "always",
      keepPreviousData: true,
    },
    errorNotification: false,
    method: "get",
  });

  useEffect(() => {
    dispatch({
      type: "reset",
    });
  }, []);

  const [name, setName] = React.useState(filters.filters.name);

  const sendFilters = () => {
    dispatch({
      type: "update_filters",
      payload: {
        name,
      },
    });
  };

  if (isLoading) {
    return <>Carregando</>;
  }

  return (
    <Box>
      <TextField
        fullWidth
        type={"text"}
        placeholder="Buscar"
        name="q"
        size="small"
        autoComplete="off"
        sx={{
          padding: "0px 10px",
        }}
        InputProps={{
          endAdornment: name.length ? (
            <IconButton
              size="small"
              onClick={(e) => {
                setName(() => "");
                dispatch({
                  type: "update_filters",
                  payload: {
                    name: "",
                  },
                });
              }}
            >
              <CloseRounded />
            </IconButton>
          ) : null,
        }}
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sendFilters();
          }
        }}
      />

      <DataGrid
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableContainerProps={{
          sx: {
            mt: "10px",
            height: "calc(100vh - 171px)",
            background: theme.palette.background.default,
          },
        }}
        enableTopToolbar={false}
        enableStickyHeader
        enableStickyFooter
        data={data?.data?.data || []}
        columns={columns}
        initialState={{
          pagination: filters.pagination,
        }}
        rowCount={data?.data?.meta?.pagination?.total || 0}
        state={{
          pagination: filters.pagination,
          isLoading: isLoading || isRefetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10],
          showFirstButton: false,
          showLastButton: false,
        }}
        manualPagination
        onPaginationChange={(updater) => {
          dispatch({
            type: "update_pagination",
            payload: {
              //@ts-ignore
              ...updater(filters.pagination),
            },
          });
        }}
        //@ts-ignore
        muiTableBodyRowProps={({ row: { original } }) => {
          const isSelected = isItemSelected(original);
          //@ts-ignore
          const { id, attributes } = original;

          let rowStyle: React.CSSProperties = {
            background: undefined,
            color: undefined,
            cursor: "pointer",
          };

          if (isSelected) {
            rowStyle = {
              background: theme.palette.action.selected,
              color: theme.palette.action.active,
            };
          }

          if (disabledItems?.includes(id)) {
            rowStyle = {
              cursor: "not-allowed",
              opacity: 0.5,
            };
          }

          return {
            style: {
              ...rowStyle,
            },
            key: id,
            onClick: () => {
              if (disabledItems?.includes(id)) return;

              const item = {
                id: id,
                ...attributes,
              };

              onSelectItem(item);
            },
          };
        }}
      />
    </Box>
  );
};

export default DialogSelectProductTable;
