import { AuthProvider } from "@pankod/refine-core";
import { AuthHelper } from "@pankod/refine-strapi-v4";

import { TOKEN_KEY, API_URL } from "./constants";

import axios from "axios";

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    const { data, status } = await strapiAuthHelper.login(email, password);
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      //@ts-ignore
      localStorage.setItem("refreshToken", data.refreshToken);

      // set header axios instance
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${data.jwt}`,
      };

      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        username,
        email,
      });
    }

    return Promise.reject();
  },
};

let requestQueue: any = [];
let isRefreshing = false;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Check if the request received a 401 Unauthorized response
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;

        // Make a request to refresh the token
        const refreshToken = localStorage.getItem("refreshToken");
        try {
          const response = await axios.post(`${API_URL}/api/token/refresh`, {
            refreshToken,
          });

          // Update the JWT token and refresh token in local storage
          localStorage.setItem(TOKEN_KEY, response.data.jwt);
          localStorage.setItem("refreshToken", response.data.refreshToken);

          // Set the new token in the request's authorization header
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.data.jwt}`;

          originalRequest.headers.Authorization = `Bearer ${response.data.jwt}`;
          originalRequest._retry = true;

          // Retry the original request

          requestQueue.forEach((req: any) => {
            if (req.url.indexOf("dashboard") > -1) {
              window.dispatchEvent(
                new CustomEvent("token-refreshed", {
                  detail: {
                    url: req.url,
                    authorization: `Bearer ${response.data.jwt}`,
                  },
                })
              );
            } else {
              req.headers.Authorization = `Bearer ${response.data.jwt}`;
              axiosInstance(req);
            }
          });

          //if originalRequest is dashboard dispatch event else axios
          if (originalRequest.url.indexOf("dashboard") > -1) {
            window.dispatchEvent(
              new CustomEvent("token-refreshed", {
                detail: {
                  url: originalRequest.url,
                  authorization: `Bearer ${response.data.jwt}`,
                },
              })
            );
            return Promise.resolve();
          } else {
            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          //@ts-ignore
          await authProvider.logout();
          window.location.href = "/login";
          return Promise.reject(refreshError);
        } finally {
          requestQueue = [];
          isRefreshing = false;
        }
      } else {
        requestQueue.push(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);
