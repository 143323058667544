import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@pankod/refine-mui";
import React from "react";

import { OpenSelectProductProps } from "./useSelectProductDialog";
import DialogSelectProductTable from "./DialogSelectProductTable";
import DialogSelectProductSelected from "./DialogSelectProductSelected";

const DialogSelectProduct: React.FC<OpenSelectProductProps> = ({
  multiple,
  open = true,
  onClose,
  onSelected,
  closeSelectProduct,
  disabledItems,
}) => {
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  const isItemSelected = (item: any) => {
    return selectedItems.find((i) => i.id === item.id);
  };

  const removeItem = (item: any) => {
    setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));
  };

  const selectItem = (item: any) => {
    setSelectedItems((prev) => {
      if (multiple) {
        if (prev.find((i) => i.id === item.id)) {
          return prev.filter((i) => i.id !== item.id);
        } else {
          return [...prev, item];
        }
      } else {
        return [item];
      }
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          maxHeight: "100vh",
          height: "100vh",
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle>Produtos</DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        <Box display={"flex"} height={"100%"}>
          <Box flex={1}>
            {open && (
              <DialogSelectProductTable
                disabledItems={disabledItems}
                isItemSelected={isItemSelected}
                onSelectItem={selectItem}
              />
            )}
          </Box>
          <Box flexBasis={"40%"}>
            <DialogSelectProductSelected
              removeItem={removeItem}
              items={selectedItems}
            >
              <Button
                variant={selectedItems.length > 0 ? "contained" : "text"}
                onClick={() => {
                  if (selectedItems.length > 0) {
                    setSelectedItems([]);
                    onSelected?.(selectedItems);
                  }

                  closeSelectProduct?.();
                  onClose?.();
                }}
              >
                {selectedItems.length > 0 ? "Confirmar" : "Fechar"}
              </Button>
            </DialogSelectProductSelected>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSelectProduct;
