import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Button, CircularProgress } from "@pankod/refine-mui";

export const AnalyticsSection: React.FC = () => {
  const API_URL = useApiUrl();
  const url = `${API_URL}/analytics/clear-cache`;

  //retirive a method to call the api
  const { refetch: clearCache, isFetching: isLoading } = useCustom({
    url,
    method: "post",
    queryOptions: {
      enabled: false,
      retry: false,
    },
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });

  return (
    <Button
      variant="contained"
      onClick={() => clearCache()}
      color="primary"
      disabled={isLoading}
    >
      {isLoading && (
        <CircularProgress size={20} sx={{ marginRight: 1 }} color="inherit" />
      )}
      Limpar cache
    </Button>
  );
};
