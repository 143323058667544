import { useFieldArray, useForm } from "@pankod/refine-react-hook-form";
import { SaveButton } from "@pankod/refine-mui";
import { errorNotificationHandler } from "../../utils";
import { useEffect } from "react";

const schemas = [
  [
    [1, 2],
    [3, 4, 5, 6],
  ],
  [
    [1, 2, 3],
    [4, 5, 6, 7],
  ],
  [
    [1, 2],
    [3, 4, 5],
  ],
  [
    [1, 2, 3, 4],
    [5, 6, 7, 8],
  ],
];

export function useMosaicForm() {
  const {
    refineCore: {
      onFinish,
      formLoading,
      // @ts-ignore
      queryResult: { data },
    },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      errorNotification(error) {
        return errorNotificationHandler(error, "Erro ao salvar mosaico");
      },
    },
  });

  const { fields } = useFieldArray({
    name: "slots",
    control,
  });

  useEffect(() => {
    if (data) {
      const index = schemas.findIndex((schema) => {
        return (
          JSON.stringify(schema) === JSON.stringify(data.data.mosaic.schema)
        );
      });

      changeSchema(index);
    }
  }, [data]);

  useEffect(() => {
    setValue("slots", []);
  }, []);

  const changeSchema = (index: number) => {
    setValue("schema", index);
    setValue("mosaic", schemas[index]);

    setTimeout(() => {
      const memoValues = JSON.parse(JSON.stringify(getValues("slots") || []));
      setValue("slots", []);
      schemas[index].flat().map((_, index) => {
        const currentValue = memoValues[index]?.label;

        setValue(
          `slots[${index}].label`,
          currentValue
            ? currentValue
            : data.data?.mosaic?.slots[index]?.label || ""
        );
        setValue(`slots[${index}].image`, "");
        setValue(`slots[${index}].link`, "");
        setValue(`slots[${index}].target`, "");
        setValue(`slots[${index}].bgcolor`, "");
        setValue(`slots[${index}].color`, "");
      });

      trigger();
    }, 100);
  };

  const SaveButtonMosaic = () => (
    <SaveButton
      onClick={() => {
        trigger().then((isValid) => {
          if (isValid) {
            setValue("mosaic", {
              schema: getValues("mosaic"),
              slots: getValues("slots"),
            });
          }
          handleSubmit(onFinish)();
        });
      }}
    />
  );

  return {
    fields,
    schemas,
    formLoading,
    saveButtonProps,
    register,
    getValues,
    changeSchema,
    errors,
    SaveButtonMosaic,
    control,
  };
}
