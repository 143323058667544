import { SxProps, TextField } from "@pankod/refine-mui";

export interface DateTimeInputdProps {
  register: any;
  errors: any;
  name: string;
  label: string;
  registerProps?: any;
  sx?: SxProps;
  required?: boolean;
  disabled?: boolean;
}

export const DateTimeInput: React.FC<DateTimeInputdProps> = ({
  register,
  registerProps,
  errors,
  name,
  label,
  sx,
  required,
  disabled,
}) => {
  const _sx = {
    maxWidth: 250,
    marginTop: 0,
    ...sx,
  };

  const _registerProps = {
    ...(required ? { required: `${label} é obrigatório` } : {}),
    ...registerProps,
  };

  return (
    <TextField
      {...register(name, _registerProps)}
      error={!!errors?.[name]}
      helperText={errors?.[name]?.message}
      margin="normal"
      required={required}
      fullWidth
      type="datetime-local"
      id={name}
      defaultValue={""}
      label={label}
      name={name}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      sx={_sx}
    />
  );
};
