import { Box, Button } from "@pankod/refine-mui";
import { InputTextField, SelectInputField } from "components/form";
import { SectionProps } from "pages/settings/sections";

const sounds = {
  "announcement-sound.mp3": {
    audio: () => new Audio(`/sounds/announcement-sound.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "bonus.mp3": {
    audio: () => new Audio(`/sounds/bonus.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "call-to-attention.mp3": {
    audio: () => new Audio(`/sounds/call-to-attention.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "cash-register-purchase.mp3": {
    audio: () => new Audio(`/sounds/cash-register-purchase.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "decide.mp3": {
    audio: () => new Audio(`/sounds/decide.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "fail.mp3": {
    audio: () => new Audio(`/sounds/fail.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "game-bonus.mp3": {
    audio: () => new Audio(`/sounds/game-bonus.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "game-level-complete.mp3": {
    audio: () => new Audio(`/sounds/game-level-complete.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "new-level.mp3": {
    audio: () => new Audio(`/sounds/new-level.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
  "soft-bells.mp3": {
    audio: () => new Audio(`/sounds/soft-bells.mp3`),
    context: null,
    gainNode: null,
    source: null,
  },
};

function playSound(value: string, volume: number) {
  //@ts-ignore
  const soundValue = sounds[value];
  if (!soundValue) {
    return;
  }

  const audio = soundValue.audio();

  const context = new AudioContext();
  const gainNode = context.createGain();
  const source = context.createMediaElementSource(audio);

  source.connect(gainNode);
  gainNode.connect(context.destination);

  soundValue.context = context;
  soundValue.gainNode = gainNode;
  soundValue.source = source;

  gainNode.gain.value = volume > 9 ? 9 : volume;
  audio.play();

  audio.onended = function () {
    source.disconnect();
    gainNode.disconnect();
    context.close();
  };
}

export const OrderSection: React.FC<SectionProps> = ({
  register,
  errors,
  control,
  getValues,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <InputTextField
        name="whatsappOrderMessage"
        label="Mensagem de pedido no whatsapp"
        required
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
        type="textarea"
      />

      <SelectInputField
        control={control!}
        errors={errors}
        name="orderSound"
        label="Local"
        onChange={(e) => {
          playSound(e.target.value, getValues?.("orderSoundVolume") || 1);
        }}
        options={[
          { label: "Som de pedido", value: "" },
          ...Object.keys(sounds).map((sound) => ({
            label: sound.slice(0, -4),
            value: sound,
          })),
        ]}
        required
        sx={{ maxWidth: 300 }}
      />

      <Box
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <InputTextField
          name="orderSoundVolume"
          type="number"
          label="Volume do som de pedido 0 a 9"
          register={register}
          errors={errors}
          registerProps={{
            min: 1,
          }}
          sx={{
            maxWidth: 230,
          }}
        />
        <Button
          variant="outlined"
          style={{
            marginBottom: 8,
          }}
          component="span"
          onClick={() => {
            playSound(
              getValues?.("orderSound"),
              getValues?.("orderSoundVolume") || 1
            );
          }}
        >
          Play
        </Button>
      </Box>
    </Box>
  );
};
