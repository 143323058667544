import { EnableInputField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const DiscountSection: React.FC<SectionProps> = ({ control }) => {
  return (
    <EnableInputField
      control={control!}
      name="isDiscountActive"
      label="Visível no site"
      sx={{
        marginTop: -2,
        width: "fit-content",
      }}
    />
  );
};
