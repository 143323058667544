import { Button, Card, CardContent, Typography } from "@pankod/refine-mui";
import { DataGridContext } from "components/DataGrid/context";
import React from "react";

export function DataGridFilter({ children }: { children: JSX.Element }) {
  const { applyFilters, state } = React.useContext(DataGridContext);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries()) as Record<any, string>;

    const {
      sortField = state.sort.field,
      sortOrder = state.sort.field,
      ...filters
    } = data;

    applyFilters(filters, {
      field: sortField,
      order: sortOrder,
    });
  };

  return (
    <Card sx={{ maxWidth: "300px", minWidth: "300px", alignSelf: "start" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        component={"form"}
        onSubmit={onSubmit}
      >
        <Typography color="text.secondary" gutterBottom>
          Filtros
        </Typography>

        {children}

        <Button
          color="primary"
          type="submit"
          variant="contained"
          sx={{ width: "100%", mt: 2 }}
        >
          Filtrar
        </Button>
      </CardContent>
    </Card>
  );
}
