export type FilterReducerActions<T> = {
  type: "update_pagination" | "update_filters";
  payload: Partial<FilterReducerState<T>>;
};

export type FilterReducerState<T> = {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  filters: T;
  sort: {
    field: string;
    order: string;
  };
  updateQueryParams: (newState: FilterReducerState<T>) => void;
  setSearchParams: (nextInit: URLSearchParams) => void;
};

export function filterReducer<T>(
  state: FilterReducerState<T>,
  action: FilterReducerActions<T>
) {
  let newState: typeof state;

  const updateQueryParams = (newState_: any) => {
    state.setSearchParams({
      ...newState_.filters,
      ...newState_.sort,
      ...newState_.pagination,
    });
  };

  switch (action.type) {
    case "update_pagination":
      newState = {
        ...state,
        pagination: { ...state.pagination, ...action.payload },
      };
      updateQueryParams(newState);

      return newState;
    case "update_filters":
      newState = {
        ...state,
        pagination: { ...state.pagination, pageIndex: 0 },
        filters: action.payload.filters!,
        sort: action.payload.sort || state.sort,
      };
      updateQueryParams(newState);

      return newState;
    default:
      return state;
  }
}

export function fillFromSearchParams(initialState: any, searchParams: any) {
  const filters = initialState;

  Object.keys(initialState).forEach((key) => {
    //@ts-ignore
    filters[key] = searchParams.get(key) || initialState[key];
  });

  return filters;
}
