import React from "react";
import {
  GridSearchIcon,
  IconButton,
  InputBase,
  Paper,
} from "@pankod/refine-mui";
import { CrudFilter } from "@pankod/refine-core";

export type CrudFilterExtra = Partial<
  Omit<CrudFilter, "operator" | "field">
> & {
  field: string;
  operator: CrudFilter["operator"] | "containsi";
};

export interface TableSearchProps {
  getDefaultFilter?: any;
  filters: any;
  setFilters: (filters: CrudFilter[]) => void;
  defaultValue?: any;
  crudFilter: CrudFilterExtra;
}

export const TableSearch: React.FC<TableSearchProps> = ({
  getDefaultFilter,
  setFilters,
  crudFilter,
  filters,
}: TableSearchProps) => {
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const onClickSearch = () => {
    const value = searchInputRef?.current?.value;
    crudFilter.value = value;

    const newFilters = filters.filter(
      // @ts-ignore
      (filter: CrudFilter) => filter.field !== crudFilter.field
    );

    if (value) {
      newFilters.push(crudFilter);
    }
    setFilters(newFilters);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid",
        marginTop: "10px",
        marginBottom: "10px",
        width: "100%",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Buscar"
        inputRef={searchInputRef}
        inputProps={{
          "aria-label": "search items",
        }}
        defaultValue={
          // @ts-ignore
          getDefaultFilter(crudFilter.field, filters, crudFilter.operator)
        }
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            onClickSearch();
          }
        }}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={onClickSearch}
      >
        <GridSearchIcon />
      </IconButton>
    </Paper>
  );
};
