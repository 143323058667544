import React from "react";
import {
  Box,
  TextField,
  Create,
  FormControlLabel,
  Switch,
  Typography,
} from "@pankod/refine-mui";

import { Controller, useForm } from "@pankod/refine-react-hook-form";

export const PaymentMethodCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Criar Método de pagamento
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          error={!!errors?.name}
          //@ts-ignore
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nome"
          name="name"
          autoFocus
          sx={{ maxWidth: "300px" }}
        />

        <TextField
          {...register("order", { required: "Ordem é obrigatória" })}
          error={!!errors?.order}
          //@ts-ignore
          helperText={errors.order?.message}
          margin="normal"
          type="number"
          required
          fullWidth
          id="order"
          label="Ordem"
          name="order"
          sx={{ maxWidth: "100px" }}
          defaultValue={"0"}
        />

        <Controller
          control={control}
          name="enabled"
          defaultValue={true as any}
          render={({ field }) => (
            <FormControlLabel
              sx={{ marginTop: 2, width: "120px" }}
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                />
              }
              label="Habilitado"
            />
          )}
        />
      </Box>
    </Create>
  );
};
