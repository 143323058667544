import { Box, Divider, IconButton } from "@pankod/refine-mui";

import CloseIcon from "@mui/icons-material/Close";

import {
  ListItem,
  ListItemText,
  MuiList,
  Typography,
  useTheme,
} from "@pankod/refine-mui";
import React from "react";

const DialogSelectProductSelected: React.FC<{
  items: any[];
  removeItem: any;
  children?: React.ReactNode;
}> = ({ items, removeItem, children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      <MuiList
        sx={{
          position: "absolute",
          overflow: "auto",
          inset: 0,
          padding: 0,
          bottom: "57px",
        }}
      >
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeItem(item)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {item.name}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      id: {item.id}
                    </Typography>
                    {`, Preço atual: ${item.formattedPrice}`}
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider color={theme.palette.background.default} />
          </React.Fragment>
        ))}
      </MuiList>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "57px",
          paddingX: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: theme.palette.background.default,
        }}
      >
        <Typography variant="caption" color="text.primary">
          {items.length} selecionado(s)
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export default DialogSelectProductSelected;
