import {
  Box,
  TextField,
  Create,
  FormControlLabel,
  Switch,
  Typography,
  useAutocomplete,
  Autocomplete,
} from "@pankod/refine-mui";

import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { useUploadImage } from "../../components/useUploadImage/useUploadImage";
import { useState } from "react";

export const CategoryGroupCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
    setError,
    getValues,
  } = useForm();

  const {
    Component: UploadImage,
    Viewer: ImageViewer,
    handleUpload,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "image",
    context: "categoryGroups",
    size: [231, 133],
    errors,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { autocompleteProps } = useAutocomplete({
    resource: "categories",
    onSearch: (value) => [
      {
        field: "name",
        operator: "containss",
        value,
      },
    ],
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        disabled: saveButtonProps.disabled || isLoading,
        onClick: (e: any) => {
          if (!isValid) {
            saveButtonProps.onClick(e);
            return;
          }

          setIsLoading(true);
          handleUpload(getValues("name")).then(() => {
            saveButtonProps.onClick(e);
            setIsLoading(false);
          });
        },
      }}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Cadastrar Grupo
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          error={!!errors?.name}
          //@ts-ignore
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nome"
          name="name"
          autoFocus
          disabled={isLoading || formLoading}
          sx={{ maxWidth: "300px" }}
        />

        <Controller
          control={control}
          name="category"
          rules={{ required: "Categoria é obrigatória" }}
          defaultValue={[] as any}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              disabled={isLoading || formLoading}
              getOptionLabel={(item) => {
                return item.name ? item.name : "";
              }}
              isOptionEqualToValue={(option, value) => {
                return value === undefined || option.id === value.id;
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ maxWidth: "600px", marginTop: 3 }}
                  {...params}
                  label="Categoria"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.category}
                  //@ts-ignore
                  helperText={errors.category?.message}
                />
              )}
            />
          )}
        />

        <TextField
          {...register("order", { required: "Ordem é obrigatória" })}
          error={!!errors?.order}
          //@ts-ignore
          helperText={errors.order?.message}
          margin="normal"
          type="number"
          required
          fullWidth
          id="order"
          label="Ordem"
          name="order"
          sx={{ maxWidth: "100px" }}
          defaultValue={"0"}
          disabled={isLoading || formLoading}
        />

        <Controller
          control={control}
          name="enabled"
          defaultValue={true as any}
          render={({ field }) => (
            <FormControlLabel
              sx={{ marginTop: 2, marginBottom: 4, width: "120px" }}
              disabled={isLoading || formLoading}
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                />
              }
              label="Habilitada"
            />
          )}
        />

        <UploadImage disabled={isLoading || formLoading} />
        <ImageViewer disabled={isLoading || formLoading} />
      </Box>
    </Create>
  );
};
