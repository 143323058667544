import React from "react";
import { Typography, Box, CreateButton } from "@pankod/refine-mui";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { dataGridColumnFactory } from "../../components/DataGrid/components/Column";
import RefreshButton from "components/DataGrid/components/RefreshButton";
import DataGridFilterText from "components/DataGrid/components/filter/DataGridFilterText";
import DataGridFilterSort from "components/DataGrid/components/filter/DataGridFilterSort";
import DataGridFilterEnabled from "components/DataGrid/components/filter/DataGridFilterEnabled";

const columns = [
  dataGridColumnFactory({
    accessorKey: "name",
    header: "Nome",
    Cell: ({ attributes }) => (
      <Box display={"flex"} flexDirection="column">
        <Typography variant="body2">{attributes.name}</Typography>
      </Box>
    ),
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.order",
    header: "Ordem",
  }),
  dataGridColumnFactory({
    accessorKey: "enabled",
    header: "Habilitado",
    isBoolean: true,
  }),
];

export const CarouselList: React.FC = () => {
  return (
    <DataGridTable<{
      name: string;
      enabled: string;
    }>
      id="carousel-list"
      initialSort={{
        field: "order",
        order: "asc",
      }}
      endpoint="carousels"
      header="Carrossel"
      columns={columns}
      initialState={{
        name: "",
        enabled: "",
      }}
      headerActions={
        <>
          <RefreshButton />
          <CreateButton />
        </>
      }
      filterComponent={
        <>
          <DataGridFilterText field="name" />
          <DataGridFilterEnabled />
          <DataGridFilterSort
            fields={[
              {
                name: "name",
                label: "Nome",
              },
              {
                name: "order",
                label: "Ordem",
              },
              {
                name: "enabled",
                label: "Habilitado",
              },
            ]}
          />
        </>
      }
      queryPipeline={(f) => {
        const query: Record<string, string> = {};
        if (f.name) {
          query["filters[name][$containsi]"] = f.name;
        }

        if (f.enabled) {
          query["filters[enabled][$eq]"] = f.enabled;
        }
        return query;
      }}
    />
  );
};
