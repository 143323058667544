import {
  FilterReducerActions,
  FilterReducerState,
} from "components/DataGrid/reducer";
import React from "react";

export const DataGridContext = React.createContext<{
  state: FilterReducerState<any>;
  refetch: () => void;
  isLoading: boolean;
  dispatch: React.Dispatch<FilterReducerActions<any>>;

  applyFilters: (
    filters: Record<any, string>,
    sort: FilterReducerState<any>["sort"]
  ) => void;
}>({} as any);
