import { CloseRounded } from "@mui/icons-material";
import { IconButton, MenuItem, Select } from "@pankod/refine-mui";
import { DataGridContext } from "components/DataGrid/context";
import React from "react";

const DataGridFilterEnabled = () => {
  const { state } = React.useContext(DataGridContext);

  const [enabled, setEnabled] = React.useState(state.filters.enabled);

  return (
    <Select
      name="enabled"
      size="small"
      sx={{ flex: 1 }}
      onChange={(e) => setEnabled(e.target.value)}
      value={enabled}
      displayEmpty={true}
      endAdornment={
        enabled ? (
          <IconButton
            size="small"
            sx={{ mr: 1 }}
            onClick={() => {
              setEnabled("");
            }}
          >
            <CloseRounded />
          </IconButton>
        ) : null
      }
    >
      <MenuItem value="">Habilitados e Desabilitados</MenuItem>
      <MenuItem value="true">Somente habilidatos</MenuItem>
      <MenuItem value="false">Somente desabilitados</MenuItem>
    </Select>
  );
};

export default DataGridFilterEnabled;
