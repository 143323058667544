import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  typography,
} from "@pankod/refine-mui";
import { DarkTheme, LightTheme } from "@pankod/refine-mui";

type ColorModeContextType = {
  mode: string;
  setMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const colorModeFromLocalStorage = localStorage.getItem("colorMode");
  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const systemPreference = isSystemPreferenceDark ? "dark" : "light";
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || systemPreference
  );

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);
  }, [mode]);

  const setColorMode = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ThemeProvider theme={mode === "light" ? LightTheme2 : DarkTheme2}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const commonThemeProperties: ThemeOptions = {
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
};

const LightTheme2 = createTheme({
  ...commonThemeProperties,
  palette: {
    mode: "light",
    primary: {
      main: "#673ab7",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2A132E",
      contrastText: "#fff",
    },
    background: {
      default: "#f0f0f0",
      paper: "#ffffff",
    },
    success: {
      main: "#673ab7",
      contrastText: "#fff",
    },
    error: {
      main: "#fa541c",
      contrastText: "#fff",
    },
    warning: {
      main: "#fa8c16",
      contrastText: "#fff",
    },
    info: {
      main: "#0b82f0",
      contrastText: "#fff",
    },
    divider: "rgba(0,0,0,0)",
    text: {
      primary: "#626262",
      secondary: "#9f9f9f",
      disabled: "#c1c1c1",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#212121 !important",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01))",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 800,
          lineHeight: "2rem",
        },
      },
    },
  },
});

const DarkTheme2 = createTheme({
  ...commonThemeProperties,
  palette: {
    mode: "dark",
    primary: {
      main: "#9575cd",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2A132E",
      contrastText: "#fff",
    },
    background: {
      default: "#424242",
      paper: "#242424",
    },
    success: {
      main: "#9575cd",
      contrastText: "#fff",
    },
    error: {
      main: "#ee2a1e",
      contrastText: "#fff",
    },
    warning: {
      main: "#fa8c16",
      contrastText: "#fff",
    },
    info: {
      main: "#1890ff",
      contrastText: "#fff",
    },
    divider: "rgba(0,0,0,0)",
    text: {
      primary: "#fff",
      secondary: "rgba(255,255,255,0.7)",
      disabled: "#d1d1d1",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#212121 !important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025))",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: "transparent",
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 800,
          lineHeight: "2rem",
        },
      },
    },
  },
});
