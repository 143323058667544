import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  TextField,
  Switch,
  Typography,
  FormControlLabel,
  Autocomplete,
  useAutocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@pankod/refine-mui";
import { useUploadImage } from "../../components/useUploadImage/useUploadImage";

import { useEffect, useState } from "react";

export const BannerEdit: React.FC = () => {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    refineCoreProps: {
      metaData: {
        populate: ["image", "sale"],
      },
    },
  });

  const {
    Component: UploadImage,
    Viewer: ImageViewer,
    handleUpload,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "image",
    context: "banners",
    size: [640, 300],
    errors,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { autocompleteProps } = useAutocomplete({
    resource: "sales",
    filters: [
      {
        field: "enabled",
        operator: "eq",
        value: true,
      },
    ],
    defaultValue: queryResult?.data?.data.sale?.id,
    onSearch: (value) => [
      {
        field: "name",
        operator: "containss",
        value,
      },
    ],
  });

  const typeWatch = watch("type");

  useEffect(() => {
    if (typeWatch === "sale") {
      setValue("sale", queryResult?.data?.data.sale);
      setValue("externalLink", "");
      setValue("term", "");
    } else if (typeWatch === "externalLink") {
      setValue("externalLink", queryResult?.data?.data.externalLink);
      setValue("sale", null);
      setValue("term", "");
    } else if (typeWatch === "term") {
      setValue("term", queryResult?.data?.data.term);
      setValue("sale", null);
      setValue("externalLink", "");
    } else {
      setValue("sale", null);
      setValue("externalLink", "");
      setValue("term", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeWatch]);

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        disabled: saveButtonProps.disabled || isLoading,
        onClick: (e: any) => {
          if (!isValid) {
            saveButtonProps.onClick(e);
            return;
          }

          setIsLoading(true);
          handleUpload(getValues("name")).then(() => {
            saveButtonProps.onClick(e);
            setIsLoading(false);
          });
        },
      }}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Editar Banner
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          error={!!errors?.name}
          //@ts-ignore
          helperText={errors?.name?.message}
          required
          margin="normal"
          fullWidth
          id="name"
          label="Nome"
          name="name"
          defaultValue={" "}
          autoFocus
          disabled={isLoading || formLoading}
          sx={{ maxWidth: "300px" }}
        />

        <Controller
          control={control}
          name="type"
          defaultValue={" "}
          rules={{ required: "Tipo é obrigatório" }}
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginTop: 3 }}>
              <InputLabel>Tipo de banner</InputLabel>

              <Select
                {...field}
                error={!!errors?.type}
                disabled={isLoading || formLoading}
                id="type"
                required
                label="Tipo de banner"
              >
                <MenuItem value="nothing">Nenhum</MenuItem>
                <MenuItem value="externalLink">Link Externo</MenuItem>
                <MenuItem value="sale">Promoção</MenuItem>
                <MenuItem value="term">Busca</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        {typeWatch === "externalLink" && (
          <TextField
            defaultValue={" "}
            {...register("externalLink", { required: "Link é obrigatório" })}
            error={!!errors?.externalLink}
            //@ts-ignore
            helperText={errors.externalLink?.message}
            margin="normal"
            fullWidth
            id="externalLink"
            label="Link (URL completa http://...)"
            name="externalLink"
            disabled={isLoading || formLoading}
            sx={{ maxWidth: "600px", marginTop: 3 }}
          />
        )}

        {typeWatch === "term" && (
          <TextField
            {...register("term", { required: "Termo da busca é obrigatório" })}
            error={!!errors?.term}
            //@ts-ignore
            helperText={errors.term?.message}
            margin="normal"
            fullWidth
            id="term"
            label="Termo da busca"
            name="term"
            disabled={isLoading || formLoading}
            sx={{ maxWidth: "300px", marginTop: 3 }}
            defaultValue={" "}
          />
        )}

        {typeWatch === "sale" && (
          <Controller
            control={control}
            name="sale"
            rules={{ required: "Promoção é obrigatório" }}
            defaultValue={0 as any}
            render={({ field }) => (
              <Autocomplete
                {...autocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                disabled={isLoading || formLoading}
                getOptionLabel={(item) => {
                  return item.name ? item.name : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return value === undefined || option.id === value.id;
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ maxWidth: "600px", marginTop: 3 }}
                    {...params}
                    label="Promoção"
                    margin="normal"
                    variant="outlined"
                    error={!!errors.sale}
                    //@ts-ignore
                    helperText={errors.sale?.message}
                  />
                )}
              />
            )}
          />
        )}

        <TextField
          {...register("order", { required: "Ordem é obrigatória" })}
          error={!!errors?.order}
          //@ts-ignore
          helperText={errors.order?.message}
          margin="normal"
          type="number"
          required
          fullWidth
          id="order"
          label="Ordem"
          name="order"
          sx={{ maxWidth: "100px", marginTop: 3 }}
          defaultValue={"0"}
          disabled={isLoading || formLoading}
        />

        <Controller
          control={control}
          name="enabled"
          defaultValue={true as any}
          render={({ field }) => (
            <FormControlLabel
              sx={{ marginTop: 2, marginBottom: 4, width: "120px" }}
              disabled={isLoading || formLoading}
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                />
              }
              label="Habilitado"
            />
          )}
        />

        <UploadImage disabled={isLoading || formLoading} />
        <ImageViewer disabled={isLoading || formLoading} />
      </Box>
    </Edit>
  );
};
