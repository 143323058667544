import React, { useState } from "react";

import { Stack, Box } from "@pankod/refine-mui";

import { useApiUrl } from "@pankod/refine-core";

const Component = (
  props: Pick<IUseBatchCsvProps, "uploadUrl"> & {
    children: any;
    updateUploadState: any;
    onSelectedFile: any;
    onResponse: any;
    onError: any;
  }
) => {
  const apiUrl = useApiUrl();

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //@ts-ignore
    const file_ = event.target.files[0];

    if (!file_) return;
    props.onSelectedFile(file_);
    props.updateUploadState(true);

    try {
      const formData = new FormData();
      const file: File = file_;

      formData.append("files", file);

      const data = await fetch(`${apiUrl}${props.uploadUrl}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("strapi-jwt-token")}`,
        },
      });

      const response = await data.json();

      if (!response.error) {
        props.onResponse(response);
      } else {
        props.onError?.(response);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      props.updateUploadState(false);
    }

    // uploadUrl
  };

  return (
    <Stack direction="row" gap={4} flexWrap="wrap">
      <label
        htmlFor="file-input"
        style={{
          width: "100%",
        }}
      >
        <input
          id="file-input"
          type="file"
          style={{ display: "none" }}
          onChange={onChangeHandler}
        />

        <Box
          component="span"
          sx={{
            width: "100%",
          }}
        >
          {props.children}
        </Box>
      </label>
    </Stack>
  );
};

interface IUseBatchCsvProps {
  uploadUrl: string;
}

export const useBatchCsv = (props: IUseBatchCsvProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [dataToConfirm, setDataToConfirm] = useState<any>(null);
  const [file, setFile] = useState<any>(null);

  const apiUrl = useApiUrl();

  const updateUploadState = (state: boolean) => {
    setIsUploading(state);
  };

  const onResponse = (response: any) => {
    setDataToConfirm(response);
  };

  const cancelUpload = () => {
    setDataToConfirm(null);
  };

  const confirmUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("confirmed", "true");

      await fetch(`${apiUrl}${props.uploadUrl}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("strapi-jwt-token")}`,
        },
      });
    } catch (error) {
      // console.log(error);
    } finally {
      setDataToConfirm(null);
    }
  };

  return {
    Component: ({ children, onSelectedFile, onError }: any) => (
      <Component
        {...props}
        updateUploadState={updateUploadState}
        onSelectedFile={(file: any) => {
          onSelectedFile();
          setFile(file);
          setDataToConfirm(null);
        }}
        onResponse={onResponse}
        onError={onError}
      >
        {children}
      </Component>
    ),
    isUploading,
    dataToConfirm,
    cancelUpload,
    confirmUpload,
  };
};
