import { Box } from "@pankod/refine-mui";
import { InputTextField } from "components/form";
import { SectionProps } from "pages/settings/sections";

export const SocialSection: React.FC<SectionProps> = ({ register, errors }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <InputTextField
        name="instagramUrl"
        label="https://www.instagram.com/....."
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="facebookUrl"
        label="https://www.facebook.com/....."
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />

      <InputTextField
        name="youtubeUrl"
        label="https://www.youtube.com/....."
        register={register}
        errors={errors}
        sx={{
          maxWidth: "100%",
        }}
      />
    </Box>
  );
};
