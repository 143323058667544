import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from "@pankod/refine-mui";
import {
  Control,
  Controller,
  FieldValues,
} from "@pankod/refine-react-hook-form";

interface TypeInputFieldProps {
  control: Control<FieldValues>;
  errors: any;
  name: string;
  label: string;
  options: { label: string; value: string }[];
  required?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  onChange?: (event: SelectChangeEvent<any>) => void;
}

export const SelectInputField: React.FC<TypeInputFieldProps> = ({
  control,
  errors,
  name,
  label,
  required,
  disabled,
  options,
  sx,
  onChange,
}) => {
  let _rules = {};

  if (required) {
    _rules = {
      required: `${label} é obrigatório`,
    };
  }

  const _sx = { maxWidth: 150, marginBottom: 1, ...sx };

  return (
    <Controller
      control={control}
      name={name}
      rules={_rules}
      defaultValue={options[0].value}
      render={({ field }) => (
        <FormControl fullWidth sx={_sx}>
          <InputLabel>{label}</InputLabel>

          <Select
            {...field}
            error={!!errors?.[name]}
            disabled={disabled}
            name={name}
            required={required}
            label={label}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
