import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Stack,
  EditButton,
} from "@pankod/refine-mui";

export const SettingsList: React.FC = () => {
  const { dataGridProps } = useDataGrid({
    //@ts-ignore
    initialCurrent: 1,
    initialPageSize: 10,
    initialSorter: [],
    initialFilter: [],
    syncWithLocation: true,
  });

  const columns = React.useMemo<GridColumns>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 50,
        sortable: false,
        filterable: false,
        hideable: false,
      },
      {
        field: "footerText",
        headerName: "",
        minWidth: 400,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: function render() {
          return (
            <Stack direction="row" spacing={1}>
              Configurações gerais
            </Stack>
          );
        },
      },

      {
        headerName: "",
        headerAlign: "center",
        field: "actions",
        width: 140,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
      />
    </List>
  );
};
