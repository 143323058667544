import { Box, Typography } from "@pankod/refine-mui";

import { type MRT_ColumnDef as GridColDef } from "material-react-table";

export const columns: GridColDef[] = [
  {
    accessorKey: "id",
    header: "ID",
    enableColumnFilter: false,
    enableColumnActions: false,
    enableSorting: false,

    size: 10,
    Cell: ({
      row: {
        //@ts-ignore
        original: { id },
      },
    }) => (
      <Typography
        variant="body2"
        style={{ maxWidth: "20px", width: "20px", userSelect: "none" }}
      >
        {id}
      </Typography>
    ),
  },
  {
    accessorKey: "name",
    header: "Nome",
    enableColumnFilter: false,
    enableColumnActions: false,
    enableSorting: false,
    Cell: ({
      row: {
        //@ts-ignore
        original: { attributes },
      },
    }) => (
      <Box
        display="flex"
        alignContent="center"
        alignItems={"center"}
        style={{ width: "400px", userSelect: "none" }}
      >
        <Typography variant="body2" pt={1}>
          {attributes.name}
        </Typography>
      </Box>
    ),
  },
];
