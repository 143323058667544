import { SxProps, TextField } from "@pankod/refine-mui";
import {
  Control,
  Controller,
  FieldValues,
} from "@pankod/refine-react-hook-form";

import { NumericFormat } from "react-number-format";

interface PriceInputFieldProps {
  control: Control<FieldValues>;
  errors: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  prefix?: string;
  sufix?: string;
}

export const PriceInputField: React.FC<PriceInputFieldProps> = ({
  control,
  errors,
  name,
  label,
  required,
  disabled,
  sx,
  prefix,
  sufix,
}) => {
  const _sx = { maxWidth: 150, marginBottom: 1, ...sx };
  const _prefix = prefix !== undefined ? prefix : "R$ ";
  const _sufix = sufix !== undefined ? sufix : "";

  let _rules = {};

  if (required) {
    _rules = {
      required: `${label} é obrigatório`,
    };
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={_rules}
      defaultValue={null as any}
      render={({ field }) => (
        <>
          <NumericFormat
            {...field}
            customInput={TextField}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
            label={label}
            prefix={_prefix}
            suffix={_sufix}
            sx={_sx}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            type="text"
            required={required}
            fixedDecimalScale
            error={!!errors?.[name]}
            helperText={errors?.[name]?.message}
            onValueChange={({ value }) => field.onChange(value)}
          />
        </>
      )}
    />
  );
};
