import { Image } from "@mui/icons-material";

export function ProductImage({ image }: { image: any }) {
  const url = image?.data?.attributes?.url;

  if (!url) {
    return <Image sx={{ marginRight: "10px", fontSize: 75, opacity: 0.5 }} />;
  }

  return (
    <img
      src={url}
      style={{
        marginRight: "10px",
        width: 75,
        height: 75,
        objectFit: "scale-down",
      }}
      alt=""
    />
  );
}
