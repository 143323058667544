import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Box, TextField, Create, Typography } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

export const UserCreate: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const API_URL = useApiUrl();
  const url = `${API_URL}/company-settings`;

  const { data: settings } = useCustom({
    url,
    method: "get",
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Cadastrar Usuário
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          error={!!errors?.name}
          //@ts-ignore
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nome"
          name="name"
          autoFocus
          sx={{ maxWidth: "300px" }}
        />

        <Box display="flex" gap={2}>
          <TextField
            {...register("email", { required: "E-mail é obrigatório" })}
            error={!!errors?.email}
            //@ts-ignore
            helperText={errors.email?.message}
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            sx={{ maxWidth: "300px" }}
          />
          {/* {settings?.data?.data?.[0]?.attributes.userDomain} */}
        </Box>

        <TextField
          {...register("password", { required: "Senha é obrigatório" })}
          error={!!errors?.password}
          //@ts-ignore
          helperText={errors.password?.message}
          margin="normal"
          required
          fullWidth
          id="password"
          label="Senha"
          name="password"
          sx={{ maxWidth: "300px" }}
        />
      </Box>
    </Create>
  );
};
