import React, { useContext, useEffect } from "react";
import {
  useApiUrl,
  useGetIdentity,
  useNotification,
  // useGetLocale,
  // useSetLocale,
} from "@pankod/refine-core";
import {
  AppBar,
  IconButton,
  Avatar,
  Stack,
  Toolbar,
  Typography,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Box,
} from "@pankod/refine-mui";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";

import { ColorModeContext } from "contexts";
// import i18n from "i18n";

import packageJson from "../../../../package.json";
import { axiosInstance } from "../../../authProvider";

export const Header: React.FC = () => {
  const { mode, setMode } = useContext(ColorModeContext);

  const notification = useNotification();

  // const changeLanguage = useSetLocale();
  // const locale = useGetLocale();
  // const currentLocale = locale();

  const apiUrl = useApiUrl();

  const { data: user } = useGetIdentity();
  const showUserInfo = user && (user.name || user.avatar);

  const [isStoreOpen, setIsStoreOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [isValidating, setIsValidating] = React.useState(false);

  const formRef = React.useRef<HTMLFormElement>(null);

  useEffect(() => {
    axiosInstance
      .get(`${apiUrl}/is-open`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("strapi-jwt-token")}`,
        },
      })
      .then((res) => {
        setIsStoreOpen(res.data.isOpen);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openStore = (e: any) => {
    e.preventDefault();
    if (formRef.current && formRef.current.reportValidity()) {
      setIsValidating(true);

      axiosInstance
        .post(
          `${apiUrl}/open`,
          JSON.stringify({ password, action: !isStoreOpen }),
          {
            headers: {
              "Content-Type": "text/plain",
              Authorization: `Bearer ${localStorage.getItem(
                "strapi-jwt-token"
              )}`,
            },
          }
        )
        .then((res) => {
          setIsStoreOpen(!isStoreOpen);
          setIsOpen(false);
        })
        .catch((err) => {
          notification?.open?.({
            message: err.response.data.error.message,
            type: "error",
          });
        })
        .finally(() => {
          setIsValidating(false);
        });
    }
  };

  const triggerSubmit = (e: any) => {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Switch
                checked={isStoreOpen}
                onChange={(_, value) => {
                  setIsOpen(true);
                  setPassword("");
                  _.preventDefault();
                }}
              />
            }
            label={isStoreOpen ? "Aberto para pedidos" : "Fechado para pedidos"}
          />

          <Box>
            <Typography
              variant="body2"
              component="label"
              sx={{ marginRight: 2 }}
            >
              v{packageJson.version}
            </Typography>
            <IconButton
              onClick={() => {
                setMode();
              }}
            >
              {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>
          </Box>
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              disableUnderline
              defaultValue={currentLocale}
              inputProps={{ "aria-label": "Without label" }}
              variant="standard"
            >
              {[...(i18n.languages ?? [])].sort().map((lang: string) => (
                <MenuItem
                  selected={currentLocale === lang}
                  key={lang}
                  defaultValue={lang}
                  onClick={() => {
                    changeLanguage(lang);
                  }}
                  value={lang}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      sx={{
                        width: "16px",
                        height: "16px",
                        marginRight: "5px",
                      }}
                      src={`/images/flags/${lang}.svg`}
                    />
                    {lang === "en" ? "English" : "German"}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {showUserInfo && (
            <Stack direction="row" gap="16px" alignItems="center">
              {user.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
              {user.name && (
                <Typography variant="subtitle2">{user?.name}</Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Toolbar>

      <Dialog open={isOpen}>
        {!isStoreOpen ? (
          <DialogTitle>Tem certeza que deseja abrir para pedidos?</DialogTitle>
        ) : (
          <DialogTitle>Tem certeza que deseja fechar para pedidos?</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>
            <form ref={formRef} onSubmit={openStore}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="description"
                label="Insira a senha para confirmar"
                type="password"
                name="openStorePassword"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} disabled={isValidating}>
            Cancelar
          </Button>
          <Button
            onClick={triggerSubmit}
            autoFocus
            color="error"
            variant="contained"
            disabled={isValidating}
          >
            <CircularProgress
              size={16}
              sx={{
                color: "white",
                marginRight: "5px",
                display: isValidating ? "block" : "none",
              }}
            />
            {!isStoreOpen ? "Abrir loja" : "Fechar loja"}
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};
