import React from "react";
import { AuthPageProps, RegisterFormTypes } from "@pankod/refine-core";
import { UseFormProps } from "@pankod/refine-react-hook-form";

import { LoginPageProps, LoginFormTypes } from "@pankod/refine-core";
import { FormProvider, useForm } from "@pankod/refine-react-hook-form";
import {
  Button,
  BoxProps,
  CardProps,
  Box,
  Checkbox,
  Container,
  Card,
  CardContent as MuiCardContent,
  CardContentProps,
  FormControlLabel,
  TextField,
  Typography,
  Divider,
  Link as MuiLink,
} from "@mui/material";

import {
  BaseRecord,
  HttpError,
  useLogin,
  useTranslate,
  useRouterContext,
} from "@pankod/refine-core";

// import { titleStyles } from "@pankod/refine-mui/src/components/pages/auth/components/styles";

export interface FormPropsType extends UseFormProps {
  onSubmit?: (values: RegisterFormTypes) => void;
}

export type AuthProps = AuthPageProps<BoxProps, CardProps, FormPropsType>;

/**
 * **refine** has a default auth page form served on the `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/mui-auth-page/} for more details.
 */
export const AuthPage: React.FC<AuthProps> = (props) => {
  const { type } = props;
  const renderView = () => {
    switch (type) {
      default:
        return <LoginPage {...props} />;
    }
  };

  return <>{renderView()}</>;
};
type LoginProps = LoginPageProps<BoxProps, CardContentProps, FormPropsType>;

/**
 * login will be used as the default type of the <AuthPage> component. The login page will be used to log in to the system.
 * @see {@link https://refine.dev/docs/api-reference/mui/components/mui-auth-page/#login} for more details.
 */
export const LoginPage: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
}) => {
  const { onSubmit, ...useFormProps } = formProps || {};
  const methods = useForm<BaseRecord, HttpError, LoginFormTypes>({
    ...useFormProps,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const { mutate: login, isLoading } = useLogin<LoginFormTypes>();
  const translate = useTranslate();
  const { Link } = useRouterContext();

  const renderProviders = () => {
    if (providers && providers.length > 0) {
      return (
        <>
          {providers.map((provider: any) => {
            return (
              <Button
                key={provider.name}
                fullWidth
                variant="outlined"
                sx={{
                  my: "8px",
                  textTransform: "none",
                }}
                onClick={() => login({ providerName: provider.name })}
                startIcon={provider.icon}
              >
                {provider.label}
              </Button>
            );
          })}
        </>
      );
    }
    return null;
  };

  const CardContent = (
    <Card {...(contentProps ?? {})}>
      <MuiCardContent sx={{ paddingX: "32px" }}>
        <Typography component="h1" variant="h5" align="center" color="primary">
          Backoffice
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            if (onSubmit) {
              return onSubmit(data);
            }

            return login(data);
          })}
          gap="16px"
        >
          {renderProviders()}
          <TextField
            {...register("email", {
              required: true,
            })}
            id="email"
            margin="normal"
            fullWidth
            label={translate("pages.login.fields.email", "Email")}
            error={!!errors.email}
            name="email"
            type="email"
            autoComplete="email"
          />
          <TextField
            {...register("password", {
              required: true,
            })}
            id="password"
            margin="normal"
            fullWidth
            name="password"
            label={translate("pages.login.fields.password", "Password")}
            helperText={errors?.password?.message}
            error={!!errors.password}
            type="password"
            placeholder="●●●●●●●●"
            autoComplete="current-password"
          />

          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          ></Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: "8px",
            }}
            disabled={isLoading}
          >
            Entrar
          </Button>
          <Box style={{ marginTop: 8 }}></Box>
        </Box>
      </MuiCardContent>
    </Card>
  );

  return (
    <FormProvider {...methods}>
      <Box component="div" {...(wrapperProps ?? {})}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {renderContent ? renderContent(CardContent) : CardContent}
          </Box>
        </Container>
      </Box>
    </FormProvider>
  );
};
