import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from "@pankod/refine-mui";

export const UserEdit: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = useForm();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Editar Usuário
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          error={!!errors?.name}
          //@ts-ignore
          helperText={errors?.name?.message}
          required
          margin="normal"
          fullWidth
          id="name"
          label="Nome"
          name="name"
          defaultValue={" "}
          autoFocus
          sx={{ maxWidth: "300px" }}
        />

        <TextField
          {...register("email", { required: "Email is required" })}
          margin="normal"
          required
          fullWidth
          id="email"
          name="email"
          label="E-mail"
          disabled
          defaultValue={" "}
          sx={{ maxWidth: "300px" }}
        />

        <TextField
          {...register("password")}
          margin="normal"
          fullWidth
          id="password"
          label="Senha"
          name="password"
          sx={{ maxWidth: "300px" }}
        />

        <Controller
          control={control}
          name="blocked"
          defaultValue={false as any}
          render={({ field }) => (
            <FormControlLabel
              sx={{ marginTop: 2, width: "120px" }}
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                />
              }
              label="Bloqueado"
            />
          )}
        />
      </Box>
    </Edit>
  );
};
