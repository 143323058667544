import {
  Box,
  InputBase,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@pankod/refine-mui";
import { FC, useState } from "react";

type ProductsOverallProps = {
  products: {
    [id: string]: {
      name: string;
      quantity: number;
    };
  };
  stateHandler: any;
};

export const ProductsOverall: FC<ProductsOverallProps> = ({
  products,
  stateHandler,
}) => {
  const theme = useTheme();

  const [selected, setSelected] = stateHandler;
  const [searchTerm, setSearchTerm] = useState("");

  const toggleSelected = (key: string) => {
    const currenctSelected = selected ? selected : [];
    const index = currenctSelected.indexOf(key);
    if (index > -1) {
      currenctSelected.splice(index, 1);
    } else {
      currenctSelected.push(key);
    }
    setSelected(() => [...currenctSelected]);
  };

  const filteredProducts = Object.keys(products).filter((item) => {
    const includ = products[item].name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return searchTerm === "" || includ;
  });

  const productsSorted = filteredProducts.sort((a, b) => {
    return products[b].quantity - products[a].quantity;
  });

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        py: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, px: 2 }}>
        Top produtos
      </Typography>

      <Box
        sx={{
          px: 2,
          py: 1,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid",
            marginTop: "10px",
            marginBottom: "10px",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Buscar"
            inputProps={{
              "aria-label": "search items",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {selected.length > 0 && (
            <button
              onClick={() => setSelected([])}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "12px",
                marginRight: "5px",
                color: theme.palette.text.primary,
              }}
            >
              Limpar seleção
            </button>
          )}
        </Paper>
      </Box>

      <Box
        sx={{
          maxHeight: 420,
          overflowY: "auto",
        }}
      >
        {productsSorted.map((key) => (
          <Box
            display="flex"
            justifyContent="space-between"
            key={key}
            sx={{
              px: 2,
              py: 1,
              width: "100%",
              ":hover": {
                backgroundColor: theme.palette.action.hover,
              },
              backgroundColor: selected.includes(key)
                ? theme.palette.action.hover
                : "",
              cursor: "pointer",
            }}
            onClick={() => toggleSelected(key)}
          >
            <Typography variant="caption">{products[key].name}</Typography>
            <Typography variant="caption">{products[key].quantity}</Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
