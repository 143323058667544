import { Box, Button, Stack, Typography } from "@pankod/refine-mui";
import {
  EnableInputField,
  Form,
  InputNumberField,
  InputTextField,
} from "../../components/form";
import { FieldValues, UseFormReturn } from "@pankod/refine-react-hook-form";
import React from "react";
import { DateTimeInput } from "components/form/DateTimeInput";

export default function CategoryForm({
  register,
  control,
  errors,
  UploadImage,
  ImageViewer,
  isSubmitting,
  formLoading,
  clearDates,
  nextDay,
  hasDates,
}: Partial<UseFormReturn> & {
  UploadImage: ({ disabled }: any) => JSX.Element;
  ImageViewer: ({ disabled }: any) => JSX.Element;
  isSubmitting: boolean;
  formLoading: boolean;
  clearDates: () => void;
  nextDay: (amount: number) => void;
  hasDates: boolean;
  errors: FieldValues["errors"];
}) {
  return (
    <Form>
      <InputTextField
        name="name"
        label="Nome"
        required
        register={register}
        errors={errors}
      />

      <InputNumberField
        name="order"
        label="Ordem"
        required
        register={register}
        errors={errors}
        sx={{ maxWidth: 150 }}
      />

      <Stack
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        flexWrap="wrap"
        direction="column"
        gap={2}
      >
        <Typography variant="h5" sx={{ fontSize: 16 }}>
          Restrição de Disponibilidade
        </Typography>

        <DateTimeInput
          name="startDateAvailability"
          label="Data de início"
          register={register}
          errors={errors}
        />

        <DateTimeInput
          name="endDateAvailability"
          label="Data de encerramento"
          register={register}
          errors={errors}
        />

        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={!hasDates}
            onClick={() => nextDay(1)}
            variant="text"
          >
            +1 dia
          </Button>
          <Button onClick={() => clearDates()} variant="text">
            Limpar datas
          </Button>
        </Box>
      </Stack>

      <EnableInputField name="isPublic" control={control!} label="Publica" />
      <EnableInputField
        name="enabled"
        control={control!}
        sx={{ marginTop: 0 }}
        label="Habilitado"
      />

      <UploadImage disabled={formLoading || isSubmitting} />
      <ImageViewer disabled={formLoading || isSubmitting} />
    </Form>
  );
}
