import { EditButton, Stack } from "@pankod/refine-mui";

import { type MRT_ColumnDef as GridColDef } from "material-react-table";

import { boolToString } from "../../../boolToString";
import { DeleteButton } from "@pankod/refine-mui";
import { DataGridTable } from "components/DataGrid/DataGridTable";

type DataGridColumnDef = {
  accessorKey: string;
  header: string;
  isBoolean?: boolean;
  size?: number;
  Cell?: (row: Record<string, any>) => JSX.Element;
};

export function dataGridColumnFactory(params: DataGridColumnDef): GridColDef {
  let CelElement: ((...args: any) => React.ReactNode) | undefined;

  if (params.isBoolean) {
    CelElement = ({ attributes }) => (
      //@ts-ignore
      <span>{boolToString(attributes[params.accessorKey])}</span>
    );
  } else if (params.Cell) {
    CelElement = params.Cell;
  }

  return {
    ...params,
    Cell:
      params.Cell || params.isBoolean
        ? ({ row: { original } }: any) => CelElement?.(original)
        : undefined,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableSorting: false,
  };
}

export const rowActions = (id: string) => ({
  enableRowActions: true,
  positionActionsColumn: "last" as "last",
  renderRowActions: ({ row: { original } }: any) => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={"center"}
      width={"100%"}
      spacing={2}
    >
      <EditButton size="small" hideText recordItemId={original.id} />
      <DeleteButton
        size="small"
        hideText
        //@ts-ignore
        recordItemId={original.id}
        onSuccess={() => {
          DataGridTable.refresh(id);
        }}
        errorNotification={(error: any) => {
          return {
            message:
              error.response.data.error.details.message || "Erro ao deletar",
            type: "error",
          };
        }}
      />
    </Stack>
  ),
});
