import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Stack,
  EditButton,
  DeleteButton,
  Typography,
  Box,
} from "@pankod/refine-mui";
import { boolToString } from "../../boolToString";

import { getDefaultFilter } from "@pankod/refine-core";
import { TableSearch } from "../../components/search/TableSearch";
import { EnabledFilter } from "../../components/search/EnabledFilter";

export const DiscountList: React.FC = () => {
  const { dataGridProps, setFilters, filters } = useDataGrid({
    //@ts-ignore
    initialCurrent: 1,
    initialPageSize: 10,
    initialSorter: [
      {
        field: "availableQuantity",
        order: "asc",
      },
    ],
    initialFilter: [],
    syncWithLocation: true,
  });

  const columns = React.useMemo<GridColumns>(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 50,
        sortable: false,
        filterable: false,
        hideable: false,
      },
      {
        field: "code",
        headerName: "Código",
        minWidth: 400,
        sortable: false,
        filterable: false,
        hideable: false,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Typography
              sx={{
                color:
                  !params.row.enabled || params.row.availableQuantity <= 0
                    ? "error.main"
                    : "",
              }}
              variant="body2"
            >
              {params.row.code}
            </Typography>
          );
        },
      },
      {
        field: "type",
        headerName: "Tipo",
        flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,
        renderCell: function render(params) {
          return (
            <span>
              {params.row.type === "percent" ? (
                <>
                  {params.row.formattedAmount}% (max:{" "}
                  {params.row.formattedMaxDiscountAllowed})
                </>
              ) : (
                <>
                  {params.row.formattedAmount} (min:
                  {params.row.formattedMinOrderAmount})
                </>
              )}
            </span>
          );
        },
      },
      {
        field: "availableQuantity",
        headerName: "Quantidade",
        flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,
      },
      {
        field: "referenceDate",
        headerName: "Data de referência",
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: function render(params) {
          //format date
          let _referenceDate = new Date(params.row.referenceDate);
          let referenceDate = _referenceDate.toLocaleDateString();
          let startReference = _referenceDate.toLocaleTimeString();
          startReference = startReference.slice(0, -3);

          return (
            <Stack direction="row" spacing={2}>
              <Stack direction="column" spacing={0}>
                <Typography variant="caption">{referenceDate}</Typography>
                <Typography variant="caption">{startReference}</Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        field: "enabled",
        headerName: "Habilitado",
        flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,
        renderCell: function render(params) {
          return <span>{boolToString(params.row.enabled)}</span>;
        },
      },
      {
        headerName: "",
        headerAlign: "center",
        field: "actions",
        width: 140,
        sortable: false,
        filterable: false,
        hideable: false,
        align: "center",
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={params.row.id} />
              {/* <ShowButton size="small" hideText recordItemId={params.row.id} /> */}
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
                errorNotification={(error: any) => {
                  return {
                    message:
                      error.response.data.error.details.message ||
                      "Erro ao deletar",
                    type: "error",
                  };
                }}
              />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
        }}
      >
        <TableSearch
          getDefaultFilter={getDefaultFilter}
          filters={filters}
          setFilters={setFilters}
          crudFilter={{
            field: "code",
            operator: "containsi",
          }}
        />

        <EnabledFilter
          getDefaultFilter={getDefaultFilter}
          filters={filters}
          setFilters={setFilters}
          crudFilter={{
            field: "enabled",
            operator: "eq",
          }}
        />
      </Box>

      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
      />
    </List>
  );
};
