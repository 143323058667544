import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@pankod/refine-mui";
import { DataGridFilter } from "./components/filter/DataGridFilter";
import { useSearchParams } from "react-router-dom";

import { MaterialReactTable as DataGrid } from "material-react-table";

import { Reducer, useReducer, useEffect } from "react";
import { useApiUrl, useCustom } from "@pankod/refine-core";
import { dataGridColumnFactory, rowActions } from "./components/Column";

import { type MRT_ColumnDef as GridColDef } from "material-react-table";
import {
  FilterReducerActions,
  FilterReducerState,
  fillFromSearchParams,
  filterReducer,
} from "components/DataGrid/reducer";
import { DataGridContext } from "components/DataGrid/context";
import { CircularProgress } from "@pankod/refine-mui";

type DataGridTableProps<T> = {
  id: string;
  header: String | JSX.Element;
  headerActions?: JSX.Element;
  initialState: T;
  endpoint: string;
  columns: GridColDef[];
  initialSort: FilterReducerState<T>["sort"];
  onClickRow?: (id: string) => void;
  queryPipeline?: (filters: T) => Record<string, any>;
  filterComponent?: JSX.Element;
  noActions?: boolean;
};

export function DataGridTable<T>({
  id,
  header,
  headerActions,
  initialState,
  endpoint,
  initialSort,
  onClickRow,
  columns,
  queryPipeline,
  filterComponent,
  noActions,
}: DataGridTableProps<T>) {
  const theme = useTheme();

  const API_URL = useApiUrl();
  const url = `${API_URL}/${endpoint}`;

  const [searchParams, setSearchParams] = useSearchParams();

  const [reducer, dispatch] = useReducer<
    Reducer<FilterReducerState<T>, FilterReducerActions<T>>
  >(filterReducer, {
    pagination: {
      pageIndex: Number(searchParams.get("pageIndex")) || 0,
      pageSize: Number(searchParams.get("pageSize")) || 10,
    },
    filters: fillFromSearchParams(initialState, searchParams),
    sort: {
      field: searchParams.get("sort") || initialSort.field,
      order: searchParams.get("order") || initialSort.order,
    },
    updateQueryParams: (newState_: FilterReducerState<T>) => {
      setSearchParams({
        ...newState_.filters,
        pageIndex: newState_.pagination.pageIndex.toString(),
        pageSize: newState_.pagination.pageSize.toString(),
        field: newState_.sort.field,
        order: newState_.sort.order,
      });
    },
    setSearchParams,
  });

  const { data, isRefetching, isLoading, refetch } = useCustom<any>({
    url,
    config: {
      query: {
        "pagination[page]": reducer.pagination.pageIndex + 1,
        "pagination[pageSize]": reducer.pagination.pageSize,
        sort: `${reducer.sort.field}:${reducer.sort.order}`,
        ...queryPipeline?.(reducer.filters),
      },
    },
    queryOptions: {
      retry: false,
      cacheTime: 0,
      enabled: true,
      networkMode: "always",
      keepPreviousData: true,
    },
    errorNotification: false,
    method: "get",
  });

  useEffect(() => {
    const refresh = () => {
      refetch();
    };

    window.addEventListener(`${id}:refresh`, refresh);

    return () => {
      window.removeEventListener(`${id}:refresh`, refresh);
    };
  }, [id, refetch]);

  const applyFilters = (
    filters: Record<keyof T, string>,
    sort: FilterReducerState<T>["sort"]
  ) => {
    dispatch({
      type: "update_filters",
      payload: {
        filters: filters as any,
        sort,
      },
    });
  };

  if (isLoading) {
    return (
      <Stack
        flex={1}
        sx={{
          p: 2,
          placeContent: "center",
          height: "100%",
          placeItems: "center",
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <DataGridContext.Provider
      value={{
        state: reducer,
        refetch,
        isLoading: isRefetching || isLoading,
        dispatch,
        applyFilters,
      }}
    >
      <Box display="flex" gap={2}>
        {filterComponent && <DataGridFilter>{filterComponent}</DataGridFilter>}

        <Box display="flex" flex={1}>
          <Card style={{ width: "100%" }}>
            <Stack
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              direction="row"
              gap={2}
              sx={{
                px: 2,
                pt: 2,
              }}
            >
              {typeof header === "string" ? (
                <Typography variant="h5">{header}</Typography>
              ) : (
                header
              )}

              {headerActions && (
                <Box>
                  <Stack direction="row" spacing={2}>
                    {headerActions}
                  </Stack>
                </Box>
              )}
            </Stack>

            <CardContent
              sx={{
                pt: 2,
                pb: 0,
                px: 0,

                "&:last-child": {
                  pb: 0,
                },
              }}
            >
              <DataGrid
                muiTablePaperProps={{
                  elevation: 0,
                }}
                muiTableContainerProps={{
                  sx: {
                    height: "calc(100vh - 260px)",

                    "& .MuiTableCell-body": {
                      background: theme.palette.background.paper,
                    },
                  },
                }}
                enableTopToolbar={false}
                enableStickyHeader
                enableStickyFooter
                data={data?.data?.data || []}
                columns={[
                  dataGridColumnFactory({
                    accessorKey: "id",
                    header: "ID",
                    size: 50,
                    Cell: ({ id }) => (
                      <Typography
                        variant="body2"
                        style={{ maxWidth: "20px", width: "20px" }}
                      >
                        {id}
                      </Typography>
                    ),
                  }),
                  ...columns,
                ]}
                initialState={{
                  pagination: reducer.pagination,
                }}
                rowCount={data?.data?.meta?.pagination?.total || 0}
                state={{
                  pagination: reducer.pagination,
                  isLoading: isLoading || isRefetching,
                }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [10, 20, 50],
                  showFirstButton: false,
                  showLastButton: false,
                }}
                manualPagination
                onPaginationChange={(updater) => {
                  dispatch({
                    type: "update_pagination",
                    payload: {
                      //@ts-ignore
                      ...updater(reducer.pagination),
                    },
                  });
                }}
                muiTableBodyRowProps={({ row: { original } }) => ({
                  //@ts-ignore
                  key: original.id,
                  onClick: (event) => {
                    //@ts-ignore
                    if (event.target.closest("button")) return;
                    //@ts-ignore
                    onClickRow?.(original.id);
                  },
                })}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    muiTableHeadCellProps: {
                      align: "center",
                    },
                    size: 100,
                  },
                }}
                {...(noActions ? {} : { ...rowActions(id) })}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </DataGridContext.Provider>
  );
}

DataGridTable.refresh = (id: string) => {
  window.dispatchEvent(new CustomEvent(`${id}:refresh`));
};
