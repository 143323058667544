import { CloseRounded } from "@mui/icons-material";
import { IconButton, TextField } from "@pankod/refine-mui";
import { DataGridContext } from "components/DataGrid/context";
import React from "react";

type DataGridFilterTextProps = {
  field: string;
  placeholder?: string;
};

const DataGridFilterText = ({
  field,
  placeholder = "Buscar",
}: DataGridFilterTextProps) => {
  const { state } = React.useContext(DataGridContext);

  const [value, setValue] = React.useState(state.filters[field]);

  return (
    <TextField
      fullWidth
      type={"text"}
      placeholder={placeholder}
      name={field}
      size="small"
      autoComplete="off"
      InputProps={{
        endAdornment: value ? (
          <IconButton
            size="small"
            onClick={(e) => {
              setValue("");
            }}
          >
            <CloseRounded />
          </IconButton>
        ) : null,
      }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      //   onKeyDown={(e) => {
      //     if (e.key === "Enter") {
      //       sendFilters();
      //     }
      //   }}
    />
  );
};

export default DataGridFilterText;
