import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { useApiUrl, useCustom } from "@pankod/refine-core";
import { formatPrice } from "../../print";
import { Typography } from "@pankod/refine-mui";
import { EVENTS } from "./ChangeTypes";

export default function OrderHistoryTimeline({ id }: any) {
  const API_URL = useApiUrl();

  const { data } = useCustom({
    url: `${API_URL}/order/${id}/history`,
    method: "get",
  });

  const { data: settings } = useCustom({
    url: `${API_URL}/company-settings`,
    method: "get",
  });

  let _settings = settings?.data?.data?.[0]?.attributes || {};
  _settings = {
    currency: _settings?.currency || "BRL",
    language: _settings?.language || "pt-BR",
  };

  const format = formatPrice;

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {data?.data?.body.map((item: any, key: any, arr: any) => {
        const modified = operation(item, format, _settings) as any;

        return (
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography
                color="textSecondary"
                variant="body2"
                component="label"
              >
                {new Date(item.date).toLocaleString()}
              </Typography>
              <Typography color="textSecondary" fontSize={12}>
                {createdBy(item)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />

              {key !== arr.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant="body2"
                component="label"
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {modified.title}
              </Typography>
              {modified.items && (
                <ul>
                  {modified.items.map((itemDescription: any) => (
                    <Typography
                      variant="body2"
                      component="label"
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {itemDescription}
                    </Typography>
                  ))}
                </ul>
              )}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}

const createdBy = (item: any) => {
  switch (item.v) {
    case 1:
      return item?.createdBy?.name || "";
    default:
      return item?.createdBy?.name || item?.updatedByCourier?.name || "";
  }
};

const operation = (item: any, format: any, _settings: any) => {
  switch (item.v) {
    case 1:
      return v1Operation(item, format, _settings);
    default:
      return oldOperation(item, format, _settings);
  }
};

const v1Operation = (item: any, format: any, _settings: any) => {
  const { data } = item;
  const modified = {} as any;

  if (!("changeType" in item)) {
    modified.title = "Nao identificado";
    return modified;
  }

  switch (item.changeType) {
    case EVENTS.ASSIGN_TO_COURIER:
      modified.title = `Entregador definido\n`;
      modified.title += data?.status
        ? ` e Movido para "${data?.status?.name}"`
        : "";
      break;
    case EVENTS.CHANGE_STATUS_COURIER:
      modified.title = `Entregador Alterou o pedido \n de "${data?.status?.from?.name}" para "${data?.status?.to?.name}"`;
      break;
    case EVENTS.CHANGE_DELIVERY_TIME_COURIER:
      const [fMin, fMax] = data?.deliveryTime?.from;
      const [tMin, tMax] = data?.deliveryTime?.to;
      if (!fMin) {
        modified.title = `Entregador Definiu o tempo de entrega para ${tMin} a ${tMax}`;
        break;
      }

      modified.title = `Entregador Alterou o tempo de entrega \n de ${fMin} a ${fMax} para ${tMin} a ${tMax}`;
      break;
    default:
      modified.title = "Nao identificado";
      break;
  }

  return modified;
};

const oldOperation = (item: any, format: any, _settings: any) => {
  const { data } = item;
  const modified = {} as any;

  if (data.created) {
    modified.title = "Criado";
  } else if (data.open) {
    modified.title = "Aberto";
  } else if (data.items) {
    modified.title = "Pedido alterado";
    modified.items = [];
    data.items.forEach((item: any) => {
      modified.items.push(
        `[${item.id}] ${item.product.name} - ${item.quantity}x ${format(
          item.totalPrice,
          _settings
        )}`
      );
    });
  } else if (data.status) {
    modified.title = `Movido para ${data.status.name}`;
    if (data?.to) {
      modified.title = `Movido de ${data.status.name} para ${data?.to?.name}`;
    }
  } else if (data.courier) {
    modified.title = "";

    const courier = data.courier;
    const from = courier?.from;
    const to = courier?.to;
    const { from: deliveryFrom, to: deliveryTo } = data?.deliveryTime || {
      from: [],
      to: [],
    };
    const [fromMin, fromMax] = deliveryFrom;
    const [toMin, toMax] = deliveryTo;

    if (!from && !to) {
      modified.title = ``;
    } else if (!from) {
      modified.title = `Entregador definido para ${to?.name}`;
    } else if (!to) {
      modified.title = `Entregador removido`;
    } else if (from && to && from?.id !== to?.id) {
      modified.title = `Entregador alterado de ${from?.name} para ${to?.name}`;
    }

    if ((fromMin && fromMax) || (toMin && toMax)) {
      if (fromMin === toMin && fromMax === toMax) {
        return modified;
      }

      if (modified.title !== "") modified.title += " - ";
      modified.title += `Tempo de entrega`;

      modified.items = [];

      if (fromMin && fromMax) {
        modified.items.push(
          `alterado de ${fromMin} a ${fromMax}, para ${toMin} a ${toMax} minutos`
        );
      } else if (toMin && toMax) {
        modified.items.push(`definido de ${toMin} a ${toMax} minutos`);
      }
    }
  } else {
    modified.title = "Nao identificado";
  }
  return modified;
};
