import React from "react";
import {
  Box,
  Edit,
  FormControlLabel,
  TextField,
  Typography,
} from "@pankod/refine-mui";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import {
  Controller,
  UseModalFormReturnType,
} from "@pankod/refine-react-hook-form";
import { NumericFormat } from "react-number-format";
import { Switch } from "@pankod/refine-mui";

export const QuickEditProduct: React.FC<UseModalFormReturnType> = ({
  formState: { errors },
  control,
  refineCore: { onFinish, formLoading, queryResult },
  handleSubmit,
  modal: { visible, close },
  saveButtonProps,
}) => {
  const record = queryResult?.data?.data;

  return (
    <Drawer
      sx={{ zIndex: "1300" }}
      PaperProps={{
        sx: {
          width: { sm: "100%", md: 500 },
          padding: "0",
        },
      }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Edit
        headerProps={{
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: "30px",
                height: "30px",
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
          action: null,
        }}
        wrapperProps={{
          sx: {
            overflowY: "scroll",
            height: "100vh",
            boxShadow: "none",
            borderRadius: "0",
            padding: "0 20px",
          },
        }}
        saveButtonProps={saveButtonProps}
        isLoading={formLoading}
        title={
          <Typography variant="body2" color="inherit" component="div">
            Edição rápida
          </Typography>
        }
      >
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
          onSubmit={handleSubmit(onFinish)}
        >
          <Typography variant="h6" color="inherit" component="div">
            {record?.name}
          </Typography>

          {record?.image?.url && (
            <img src={record?.image?.url} width={200} alt="product" />
          )}
          <Controller
            control={control}
            name="price"
            rules={{ required: "Preço é obrigatório" }}
            defaultValue={null as any}
            render={({ field }) => (
              <NumericFormat
                {...field}
                customInput={TextField}
                disabled={formLoading}
                label="Preço"
                prefix="R$ "
                sx={{ marginTop: 4, maxWidth: "150px" }}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                type="text"
                required
                fixedDecimalScale
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                error={!!errors.price}
                //@ts-ignore
                helperText={errors.price?.message}
                onValueChange={({ value }) => field.onChange(value)}
              />
            )}
          />

          <Controller
            control={control}
            name="enabled"
            defaultValue={true as any}
            render={({ field }) => (
              <FormControlLabel
                sx={{ marginTop: 2, marginBottom: 4, width: "120px" }}
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                  />
                }
                label="Habilitado"
                disabled={formLoading}
              />
            )}
          />
        </Box>
      </Edit>
    </Drawer>
  );
};
