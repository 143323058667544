import {
  EnableInputField,
  Form,
  InputNumberField,
  InputTextField,
} from "../../components/form";
import { FieldValues, UseFormReturn } from "@pankod/refine-react-hook-form";

export default function OrderStatusForm({
  register,
  control,
  errors,
}: Partial<UseFormReturn> & { errors: FieldValues["errors"] }) {
  return (
    <Form>
      <InputTextField
        name="name"
        label="Status"
        required
        register={register}
        errors={errors}
      />

      <InputTextField
        name="displayName"
        label="Titulo exibido para o cliente"
        required
        register={register}
        errors={errors}
      />

      <InputTextField
        name="description"
        label="Descrição do status exibido para o cliente"
        required
        register={register}
        errors={errors}
        sx={{ maxWidth: 600 }}
      />

      <InputNumberField
        name="order"
        label="Ordem"
        required
        register={register}
        errors={errors}
        sx={{ maxWidth: 150 }}
      />

      <EnableInputField name="enabled" control={control!} label="Habilitado" />
    </Form>
  );
}
