import React from "react";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@pankod/refine-mui";
import { NumericFormat } from "react-number-format";

export const DeliverySettingsEdit: React.FC = () => {
  const {
    refineCore: {
      formLoading,
      // @ts-ignore
      queryResult: { data },
    },
    saveButtonProps,
    handleSubmit,
    control,
  } = useForm({
    mode: "onChange",
    refineCoreProps: {
      metaData: {
        populate: ["city"],
      },
      queryOptions: {
        cacheTime: 1,
      },
    },
  });

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Configurações de entrega
        </Typography>
      }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleSubmit(saveButtonProps.onClick)}
      >
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ marginTop: 4, marginBottom: 4, fontSize: 16 }}
        >
          Bairros (se não preencher a taxa de entrega o bairro não fica
          disponível)
        </Typography>

        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Bairro</TableCell>
                <TableCell>Taxa de entrega</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.city.neighbourhoods
                .sort((a: any, b: any) => {
                  const aFee = data?.data?.neighbourhoods[a.id]?.fee;
                  const bFee = data?.data?.neighbourhoods[b.id]?.fee;

                  // equal items sort equally
                  if (aFee === bFee) {
                    return 0;
                  }

                  // nulls sort after anything else
                  if (aFee === null) {
                    return 1;
                  }
                  if (bFee === null) {
                    return -1;
                  }

                  // otherwise, if we're ascending, lowest sorts first
                  if (bFee !== null && aFee !== null) {
                    return a.name < b.name ? -1 : 1;
                  }
                })
                ?.map((neighbourhood: any) => (
                  <TableRow
                    key={neighbourhood.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell width={200}>{neighbourhood.name}</TableCell>
                    <TableCell>
                      <Controller
                        control={control}
                        name={`neighbourhoods.${neighbourhood.id}.fee`}
                        defaultValue={null as any}
                        render={({ field }) => (
                          <NumericFormat
                            {...field}
                            size="small"
                            prefix="R$ "
                            customInput={TextField}
                            disabled={formLoading}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            style={{ marginTop: 5, marginBottom: 5 }}
                            decimalScale={2}
                            fixedDecimalScale
                            type="text"
                            onValueChange={({ value }) => field.onChange(value)}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Edit>
  );
};
