import React from "react";
import { Typography, Box, CreateButton, Stack } from "@pankod/refine-mui";
import { DataGridTable } from "../../components/DataGrid/DataGridTable";
import { dataGridColumnFactory } from "../../components/DataGrid/components/Column";
import RefreshButton from "components/DataGrid/components/RefreshButton";
import DataGridFilterText from "components/DataGrid/components/filter/DataGridFilterText";
import DataGridFilterSort from "components/DataGrid/components/filter/DataGridFilterSort";
import DataGridFilterEnabled from "components/DataGrid/components/filter/DataGridFilterEnabled";

const columns = [
  dataGridColumnFactory({
    accessorKey: "name",
    header: "Nome",
    Cell: ({ attributes }) => {
      let hasDates = false;
      let startDate;
      let startTime;
      let endDate;
      let endTime;
      let isActive;

      if (attributes.startDateAvailability && attributes.endDateAvailability) {
        hasDates = true;

        let _startDate = new Date(attributes.startDateAvailability);
        startDate = _startDate.toLocaleDateString();
        startTime = _startDate.toLocaleTimeString();
        startTime = startTime.slice(0, -3);

        let _endDate = new Date(attributes.endDateAvailability);
        endDate = _endDate.toLocaleDateString();
        endTime = _endDate.toLocaleTimeString();
        endTime = endTime.slice(0, -3);

        if (startDate === endDate) {
          startTime = `${startTime} - ${endTime}`;
          endDate = "";
          endTime = "";
        }

        //calculate if the sale is active
        let now = new Date();
        isActive = now >= _startDate && now <= _endDate;
      }

      return (
        <Box display={"flex"} flexDirection="column">
          <Typography variant="body2">
            {attributes.name}
            {hasDates && (
              <Typography variant="caption">
                {isActive ? " (Disponivel)" : " (Indisponivel)"}
              </Typography>
            )}
          </Typography>
          {hasDates && (
            <>
              <Stack direction="row" spacing={1}>
                <Typography variant="caption">{startDate}</Typography>
                <Typography variant="caption">{startTime}</Typography>
              </Stack>

              <Stack direction="row" spacing={1}>
                <Typography variant="caption">{endDate}</Typography>
                <Typography variant="caption">{endTime}</Typography>
              </Stack>
            </>
          )}
        </Box>
      );
    },
  }),

  dataGridColumnFactory({
    accessorKey: "enabled",
    header: "Habilitado",
    isBoolean: true,
  }),
  dataGridColumnFactory({
    accessorKey: "isPublic",
    header: "Publica",
    isBoolean: true,
  }),
  dataGridColumnFactory({
    accessorKey: "attributes.order",
    header: "Ordem",
  }),
];

export const CategoriesList: React.FC = () => {
  return (
    <DataGridTable<{
      name: string;
      enabled: string;
    }>
      id="categorie-list"
      initialSort={{
        field: "order",
        order: "asc",
      }}
      endpoint="categories"
      header="Categorias"
      columns={columns}
      initialState={{
        name: "",
        enabled: "",
      }}
      headerActions={
        <>
          <RefreshButton />
          <CreateButton />
        </>
      }
      filterComponent={
        <>
          <DataGridFilterText field="name" />
          <DataGridFilterEnabled />
          <DataGridFilterSort
            fields={[
              {
                name: "name",
                label: "Nome",
              },
              {
                name: "order",
                label: "Ordem",
              },
              {
                name: "enabled",
                label: "Habilitado",
              },
            ]}
          />
        </>
      }
      queryPipeline={(f) => {
        const query: Record<string, string> = {};
        if (f.name) {
          query["filters[name][$containsi]"] = f.name;
        }

        if (f.enabled) {
          query["filters[enabled][$eq]"] = f.enabled;
        }

        return query;
      }}
    />
  );
};
