import { useForm } from "@pankod/refine-react-hook-form";
import { SaveButton } from "@pankod/refine-mui";
import { dateToInput } from "../../localDateTime";
import { errorNotificationHandler } from "../../utils";
import { useEffect, useRef } from "react";
import { useUploadImage } from "components/useUploadImage/useUploadImage";

export function useCategoriesForm() {
  const refDates = useRef<any>(null);

  const {
    refineCore: {
      onFinish,
      formLoading,
      // @ts-ignore
      queryResult: { data },
    },
    saveButtonProps,
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    getValues,
    setError,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    refineCoreProps: {
      metaData: {
        populate: ["image"],
      },
      // @ts-ignore
      errorNotification(error) {
        // roolback dates to original format

        if (
          refDates.current.startDateAvailability &&
          refDates.current.endDateAvailability
        ) {
          const startDateAvailability_ = dateToInput(
            refDates.current.startDateAvailability
          );
          const endDateAvailability_ = dateToInput(
            refDates.current.endDateAvailability
          );
          setValue("startDateAvailability", startDateAvailability_);
          setValue("endDateAvailability", endDateAvailability_);
        }
        return errorNotificationHandler(error, "Erro ao salvar categoria");
      },
    },
  });

  useEffect(() => {
    if (data?.data?.startDateAvailability) {
      setValue(
        "startDateAvailability",
        dateToInput(new Date(data?.data?.startDateAvailability))
      );
    }
    if (data?.data?.endDateAvailability) {
      setValue(
        "endDateAvailability",
        dateToInput(new Date(data?.data?.endDateAvailability))
      );
    }
  }, [data]);

  const {
    Component: UploadImage,
    Viewer: ImageViewer,
    handleUpload,
  } = useUploadImage({
    watchFn: watch,
    setValueFn: setValue,
    setErrorFn: setError,
    registerFn: register,
    field: "image",
    context: "categories",
    size: [209, 247],
    errors,
  });

  const startDateAvailabilityWatch = watch("startDateAvailability");
  const endDateAvailabilityWatch = watch("endDateAvailability");

  const SaveButtonSale = () => (
    <SaveButton
      onClick={() => {
        trigger().then((isValid) => {
          if (isValid) {
            if (startDateAvailabilityWatch && endDateAvailabilityWatch) {
              const startDateAvailability_ = new Date(
                getValues("startDateAvailability")
              );
              const endDateAvailability_ = new Date(
                getValues("endDateAvailability")
              );
              refDates.current = {
                startDateAvailability: startDateAvailability_,
                endDateAvailability: endDateAvailability_,
              };
              setValue(
                "startDateAvailability",
                startDateAvailability_.toISOString().slice(0, -8)
              );
              setValue(
                "endDateAvailability",
                endDateAvailability_.toISOString().slice(0, -8)
              );
            }
          }
          handleUpload(getValues("name")).then(() => {
            handleSubmit(onFinish)();
          });
        });
      }}
    />
  );

  const clearDates = () => {
    setValue("startDateAvailability", "");
    setValue("endDateAvailability", "");
  };

  const nextDay = (amount: number) => {
    const startDateAvailability = new Date(startDateAvailabilityWatch);
    const endDateAvailability = new Date(endDateAvailabilityWatch);
    startDateAvailability.setDate(startDateAvailability.getDate() + amount);
    endDateAvailability.setDate(endDateAvailability.getDate() + amount);
    setValue("startDateAvailability", dateToInput(startDateAvailability));
    setValue("endDateAvailability", dateToInput(endDateAvailability));
  };

  const hasDates = [
    startDateAvailabilityWatch || "",
    endDateAvailabilityWatch || "",
  ].every((date) => date.length > 0);

  return {
    formLoading,
    isSubmitting,
    saveButtonProps,
    register,
    errors,
    SaveButtonSale,
    control,
    UploadImage,
    ImageViewer,
    clearDates,
    nextDay,
    hasDates,
  };
}
