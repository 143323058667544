import { Box } from "@pankod/refine-mui";

import { stylesMosaic } from "./edit";

export const MosaicPreview: React.FC<{ getValues: (param: string) => any }> = ({
  getValues,
}) => {
  return (
    <Box
      sx={{
        width: 320,
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem",
      }}
    >
      {getValues("mosaic").map((cell: any, index: any) => (
        <Box sx={stylesMosaic.cell} key={index}>
          {cell.map((item: any, index: any) => (
            <Box sx={{ ...stylesMosaic.item, height: 80 }} key={index}>
              {getValues("slots")[item - 1]?.label || "Slot vazio"}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
