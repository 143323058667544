import { useShow } from "@pankod/refine-core";
import { Show, Stack, Typography } from "@pankod/refine-mui";
import { boolToString } from "../../boolToString";

export const UserShow: React.FC = () => {
  const { queryResult } = useShow();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={
        <Typography variant="h5" color="inherit" component="div">
          Usuário
        </Typography>
      }
    >
      <Stack gap={1} sx={{ marginLeft: 2 }}>
        <Typography variant="body1" fontWeight="bold">
          Nome
        </Typography>
        <Typography variant="body2">{record?.name}</Typography>
        <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 2 }}>
          E-mail
        </Typography>
        <Typography variant="body2">{record?.email}</Typography>
        <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 2 }}>
          Bloqueado
        </Typography>
        <Typography variant="body2">{boolToString(record?.blocked)}</Typography>
      </Stack>
    </Show>
  );
};
